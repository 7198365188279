import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewStackService } from '../../../../../../shared/services/view-stack/view-stack.service';
import { formsActions } from '../../../../../../store/features/forms/forms-actions';
import { Store } from '@ngrx/store';
import { CollectionListDataInstance } from '../../../../../../shared/interfaces/collection-list-data-instance';
import { Dialog } from '@angular/cdk/dialog';
import { CollectionInstanceCompareDto } from '../../../../../../../models/ts/collection-instance-compare-dto.model';
import { InstanceFolderTreeDto } from '../../../../../../../models/ts/instance-folder-tree-dto.model';
import { map, Observable, switchMap, take, tap } from 'rxjs';
import { DeleteInstanceModalComponent } from '../../../../../../shared/components/modals/data-collection/delete-instance-modal/delete-instance-modal.component';
import { DeleteInstanceModel } from '../../../../../../shared/components/modals/data-collection/delete-instance-modal/delete-instance-model';
import { RestartWorkflowModalComponent } from '../../../../../../shared/components/modals/data-collection/restart-workflow-modal/restart-workflow-modal.component';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { DialogModalButtons } from '../../../../../../shared/enums/dialog-modal-buttons';
import { DialogModalComponent } from '../../../../../../shared/components/modals/dialog-modal/dialog-modal.component';
import { RestoreInstanceModalComponent } from '../../../../../../shared/components/modals/data-collection/restore-instance-modal/restore-instance-modal.component';
import { MonitorModalComponent } from '../../../../../../shared/components/modals/monitor-modal/monitor-modal.component';
import { ArchiveModalComponent } from '../../../../../../shared/components/modals/archive-modal/archive-modal.component';
import { MonitorDto } from '../../../../../../../models/ts/monitor-dto.model';
import { flowStatusActions } from '../../../../../../store/features/flow-status/flow-status-actions';
import { CollectionListApiService } from '../../../../../../api/bizzmine/collection-list/collection-list-api.service';
import { MonitorApiService } from '../../../../../../api/bizzmine/monitor/monitor-api.service';
import { CollectionHistoryApiService } from 'src/app/api/bizzmine/collection-history/collection-history-api.service';
import { ViewStackItem } from 'src/app/shared/classes/view-stack-item';
import { SchedulerDto } from 'src/models/ts/scheduler-dto.model';
import { TaskService } from '../../../../../../shared/services/tasks/task.service';
import { ArchiveApiService } from '../../../../../../api/bizzmine/archive/archive-api.service';
import { GridContextPermissions } from '../../classes/grid-context-permissions';
import { ViewStackTrackChangesComponent } from 'src/app/features/bizzmine/track-changes/components/view-stack-track-changes/view-stack-track-changes.component';
import { TrackChangesParams } from 'src/app/features/bizzmine/track-changes/interfaces/track-changes-params.interface';
import { ViewStackHistoryComponent } from 'src/app/features/bizzmine/history/components/view-stack-history/view-stack-history.component';
import { HistoryParams } from 'src/app/features/bizzmine/history/interfaces/history-params.interface';
import { FileHistoryDto } from 'src/models/ts/file-history-dto.model';
import { FileHistoryModalComponent } from 'src/app/shared/components/modals/file-history-modal/file-history-modal.component';
import { GridOptionsDto } from 'src/models/ts/grid-options-dto.model';
import { TrackChangesApiService } from 'src/app/api/bizzmine/track-changes/track-changes-api.service';
import { CollectionMethodType } from 'src/models/ts/collection-method-type.model';
import { EditDraftOrPublishedModalComponent } from 'src/app/shared/components/modals/data-collection/compare-base-modal/variants/edit-draft-or-published-modal/edit-draft-or-published-modal.component';
import { InstanceComparisonModalModel } from 'src/app/shared/components/modals/data-collection/compare-base-modal/compare-modal-model';
import { ReadDraftOrPublishedModalComponent } from 'src/app/shared/components/modals/data-collection/compare-base-modal/variants/read-draft-or-published-modal/read-draft-or-published-modal.component';
import { DeleteDraftOrPublishedModalComponent } from 'src/app/shared/components/modals/data-collection/compare-base-modal/variants/delete-draft-or-published-modal/delete-draft-or-published-modal.component';
import { DownloadDraftPublishedOrPdfModal } from 'src/app/shared/components/modals/data-collection/compare-base-modal/variants/download-draft-published-or-pdf-modal/download-draft-published-or-pdf-modal.component';
import { CollectionInstancePropertiesDto } from 'src/models/ts/collection-instance-properties-dto.model';
import { DocumentSettingsComponent } from 'src/app/shared/components/modals/document-settings/document-settings.component';
import { DocumentSettingsParams } from 'src/app/shared/components/modals/document-settings/interfaces/document-settings-params';
import { ViewStackPermissionsComponent } from 'src/app/features/bizzmine/permissions/components/view-stack-permissions/view-stack-permissions.component';
import { PermissionsParams } from 'src/app/features/bizzmine/permissions/interfaces/permissions-params.interface';
import { InstancePermissionsDto } from 'src/models/ts/instance-permissions-dto.model';
import { PermissionsModalComponent } from 'src/app/features/bizzmine/permissions/components/permissions-modal/permissions-modal.component';
import { UnlinkFromFolderModalComponent } from 'src/app/shared/components/modals/unlink-from-folder-modal/unlink-from-folder-modal.component';
import { UnlinkFromFolderParams } from 'src/app/shared/components/modals/unlink-from-folder-modal/interfaces/unlink-from-folder-params';

import { DuplicateModalComponent } from 'src/app/shared/components/modals/duplicate-modal/duplicate-modal.component';
import { ReportModalComponent } from 'src/app/shared/components/modals/report-modal/report-modal.component';
import { ReportTileListDto } from 'src/models/ts/report-tile-list-dto.model';
import { DownloadService } from '../../../../../../core/services/download/download.service';
import { MediaApiService } from '../../../../../../api/bizzmine/media/media-api.service';
import { StateType } from 'src/models/ts/state-type.model';
import { workspaceSidebarFeature } from 'src/app/store/features/workspace-sidebar/workspace-sidebar-feature';
import { refreshActions } from 'src/app/store/features/refresh/refresh-actions';
import { TranslationService } from '../../../../../../core/services/translation/translation.service';
import { CollectionInstanceMetadata } from '../../../../../../../models/ts/media-dto.model';

@Injectable({
  providedIn: 'root'
})
export class GridService {
  private workspaceIdSignal = this.store$.selectSignal(workspaceSidebarFeature.selectID);

  public constructor(
    private store$: Store,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: Dialog,
    private viewStackService: ViewStackService,
    private collectionListService: CollectionListApiService,
    private monitorService: MonitorApiService,
    private collectionHistoryService: CollectionHistoryApiService,
    private taskService: TaskService,
    private archiveService: ArchiveApiService,
    private trackChangesService: TrackChangesApiService,
    private mediaApiService: MediaApiService,
    private downloadService: DownloadService,
    private translationService: TranslationService
  ) {

  }

  /**
   * Open (the form) of an instance in readonly mode. Opens the form in a new tab if ctrl/meta(win) key is pressed.
   * @param collectionId
   * @param instanceId
   * @param versionId
   * @param draftId
   * @param originalFolderId
   * @param addToViewStack (optional)
   * @param isDeletedForm (optional)
   * @param openNewTab (optional)
   */
  public readInstance(
    collectionId: number | undefined,
    instanceId: number | undefined,
    versionId: number | undefined,
    draftId: number | undefined,
    originalFolderId: number | undefined,
    addToViewStack = true,
    isDeletedForm = false,
    openNewTab = false
  ): void {
    // Obligated params
    if (typeof collectionId !== 'number' || typeof instanceId !== 'number' || !versionId) {
      throw new Error('Invalid dataItem');
    }
    if (draftId && typeof originalFolderId !== 'undefined') {
      this.getInstanceComparisonData(collectionId, originalFolderId, instanceId, versionId, draftId, false).subscribe({
        next: (comparisonData: CollectionInstanceCompareDto) => {
          this.dialog.open<InstanceComparisonModalModel>(
            ReadDraftOrPublishedModalComponent,
            {
              data: {
                collectionId,
                instanceId,
                versionId,
                draftId,
                originalFolderId,
                comparison: comparisonData,
                onSelectVersionType: (version: number, newTab: boolean) =>
                  this.navigateToInstance(collectionId, instanceId, version, true, addToViewStack, undefined, isDeletedForm, newTab)
              }
            }
          );
        }
      });
    } else {
      this.navigateToInstance(collectionId, instanceId, versionId, true, addToViewStack, undefined, isDeletedForm, openNewTab);
    }
  }

  /**
   * Edit (the form) of an instance. Opens the form in a new tab if ctrl/meta(win) key is pressed.
   * @param event
   * @param collectionId
   * @param instanceId
   * @param versionId
   * @param draftId
   * @param originalFolderId
   * @param addToViewStack
   * @param schedulerData
   */
  public editInstance(
    collectionId: number | undefined,
    instanceId: number | undefined,
    versionId: number | undefined,
    draftId: number | undefined,
    originalFolderId: number | undefined,
    addToViewStack = true,
    schedulerData?: SchedulerDto | undefined,
    openNewTab = false
  ): void {
    if (typeof collectionId !== 'number' || typeof instanceId !== 'number' || !versionId) {
      throw new Error('Invalid dataItem');
    }
    if (draftId && typeof originalFolderId !== 'undefined') {
      this.getInstanceComparisonData(collectionId, originalFolderId, instanceId, versionId, draftId, false).subscribe({
        next: (comparisonData: CollectionInstanceCompareDto) => {
          this.dialog.open<InstanceComparisonModalModel>(
            EditDraftOrPublishedModalComponent,
            {
              data: {
                collectionId,
                instanceId,
                versionId,
                draftId,
                originalFolderId,
                comparison: comparisonData,
                onSelectVersionType: (version: number, newTab: boolean) =>
                  this.navigateToInstance(collectionId, instanceId, version, false, addToViewStack, schedulerData, undefined, newTab)
              }
            }
          );
        }
      });
    } else {
      this.navigateToInstance(collectionId, instanceId, versionId, false, addToViewStack, schedulerData, undefined, openNewTab);
    }
  }

  /**
   * Retrieve data of draft and public version of an instance
   * @private
   * @param dataItem The instance of which the versions need to be compared
   * @param isDeleteAction If the action is a delete action (requires additional data)
   */
  public getInstanceComparisonData(collectionId: number, originalFolderId: number, instanceId: number,
                                   versionId: number, draftId: number, isDeleteAction: boolean): Observable<CollectionInstanceCompareDto> {
    return this.collectionListService.getInstanceComparisonData(
      collectionId,
      originalFolderId,
      instanceId,
      versionId,
      draftId,
      isDeleteAction
    );
  }

  /** Opens the duplicate modal.
   * @param dataItem The instance data
   */
  public duplicateInstance(dataItem: CollectionListDataInstance): void {
    if (dataItem != undefined && dataItem.CollectionsID) {
      this.dialog.open(DuplicateModalComponent, {
        data: { dataItem }
      });
    }
  }

  public executeTask(taskId: number): void {
    this.store$.dispatch(formsActions.getTaskForm({ taskId }));
  }

  /**
   * Execute task (next step) of an instance.
   * @param event
   * @param collectionId
   * @param instanceId
   * @param versionId
   * @param addToViewStack
   */
  public openNextStep(
    event: MouseEvent | undefined,
    collectionId: number,
    instanceId: number,
    versionId: number,
    taskId: number,
    draftId: number | undefined,
    addToViewStack = true
  ): void {
    //TODO: RV open in new window if ctrl is pressed
    this.taskService.executeTask({
        instanceId: instanceId,
        collectionId: collectionId,
        versionId: versionId,
        taskId: taskId,
        draftId: draftId
      }
    );
  }

  public createMajorRevision(collectionId: number, instanceId: number, versionId: number): void {
    this.store$.dispatch(formsActions.getFormByCollectionMethodType({
      collectionId: collectionId,
      versionId: versionId,
      instanceId: instanceId,
      collectionMethodType: CollectionMethodType.RevisionWithMajorVersionChange
    }));
  }

  public createMinorRevision(collectionId: number, instanceId: number, versionId: number): void {
    this.store$.dispatch(formsActions.getFormByCollectionMethodType({
      collectionId: collectionId,
      versionId: versionId,
      instanceId: instanceId,
      collectionMethodType: CollectionMethodType.RevisionWithMinorVersionChange
    }));
  }

  /**
   * Delete the instance, if the instance has multiple versions (draft or published) a comparison
   * modal is shown.
   * @param dataItem
   */
  public deleteInstance(dataItem: CollectionListDataInstance): void {
    const {
      CollectionsID: collectionId,
      OriginalFoldersID: originalFolderId,
      ID: instanceId,
      VersionsID: versionId,
      DraftsID: draftId
    } = dataItem;

    if (typeof collectionId !== 'number') {
      throw new Error('Invalid dataItem');
    }

    // Check if there is a draft (if there is, show the option to delete which version)
    if (draftId && typeof originalFolderId !== 'undefined') {
      this.getInstanceComparisonDataForDeletion(collectionId, originalFolderId, instanceId, versionId, draftId).subscribe({
        next: (comparisonData: CollectionInstanceCompareDto) => {
          this.dialog.open<DeleteDraftOrPublishedModalComponent>(
            DeleteDraftOrPublishedModalComponent,
            {
              data: { ...dataItem, comparison: comparisonData }
            }
          ).closed.pipe(take(1)).subscribe(() => {
            this.store$.dispatch(refreshActions.refreshData({ collectionId: collectionId }));
          });
        }
      });
    } else if (
      dataItem?.HasTrainingSessions ||
      dataItem?.HasTrainees ||
      dataItem?.ExamHasReferences
    ) {
      let title = '';
      let message = '';
      if (dataItem.HasTrainingSessions) {
        title = this.translationService.translate('ImpossibleToDeleteTraining');
        message = this.translationService.translate('YouCantDeleteThisTrainingBecauseThereAreStillUnderlyingTrainingSessions');
      } else if (dataItem.HasTrainees) {
        title = this.translationService.translate('ImpossibleToDeleteTrainingSession');
        message = this.translationService.translate('YouCantDeleteThisTrainingSessionBecauseThereAreStillUnderlyingTrainees');
      } else if (dataItem.ExamHasReferences) {
        title = this.translationService.translate('CannotDeleteExam');
        message = this.translationService.translate('CannotDeleteExam_Body');
      }
      this.dialog.open(DialogModalComponent, {
        data: {
          title: title,
          message: message,
          showCancel: true
        }
      });
    } else if (dataItem?.CollectionsID) {
      this.collectionListService
        .getFoldersFromInstance(dataItem.CollectionsID, dataItem.ID)
        .subscribe({
          next: (value: InstanceFolderTreeDto[]) => {
            this.dialog.open<DeleteInstanceModel>(
              DeleteInstanceModalComponent,
              {
                data: { dataItem, folders: value }
              }
            ).closed.pipe(take(1)).subscribe(() => {
              this.store$.dispatch(refreshActions.refreshData({ collectionId: collectionId }));
            });
          }
        });
    }
  }

  /**
   * Restarts the workflow, shows a generic error modal if the workflow can't be restarted.
   * @param dataItem
   */
  public restartWorkflow(dataItem: CollectionListDataInstance): void {
    //RestartWorkflowModalComponent
    const dialogRef = this.dialog.open<HttpErrorResponse>(
      RestartWorkflowModalComponent,
      {
        data: { ...dataItem }
      }
    );
    dialogRef.closed.subscribe({
      next: (response: HttpErrorResponse | undefined) => {
        if (
          response != undefined &&
          response.status == HttpStatusCode.NotFound
        ) {
          this.dialog.open<DialogModalButtons>(DialogModalComponent, {
            data: {
              title: this.translationService.translate('DocumentIsPublishedAndCantBeRestarted'),
              message: this.translationService.translate('DocumentAlreadyPublished'),
              buttons: DialogModalButtons.Ok,
              showCancel: true
            }
          });
        }
      }
    });
  }

  /**
   * Opens the restore instance (undelete) dialog modal.
   * @param dataItem
   */
  public restoreInstance(dataItem: CollectionListDataInstance): void {
    this.dialog.open<HttpErrorResponse>(RestoreInstanceModalComponent, {
      data: { ...dataItem }
    });
  }

  public downloadArchiveFile(dataItem: CollectionListDataInstance): void {
    this.archiveService.downloadFile(dataItem);
  }

  /**
   * Download (the form) of an instance.
   * @param dataItem: CollectionListDataInstance
   */
  public downloadInstance(
    dataItem: CollectionListDataInstance
  ): void {
    const {
      CollectionsID: collectionId,
      OriginalFoldersID: originalFolderId,
      ID: instanceId,
      VersionsID: versionId,
      DraftsID: draftId
    } = dataItem;
    if (typeof collectionId !== 'number') {
      throw new Error('Invalid dataItem');
    }
    this.getInstanceComparisonData(collectionId, originalFolderId ?? 0, instanceId, versionId, draftId ?? 0, false).subscribe({
      next: (comparisonData: CollectionInstanceCompareDto) => {
        this.dialog.open<InstanceComparisonModalModel>(
          DownloadDraftPublishedOrPdfModal,
          {
            data: {
              ...dataItem,
              comparison: comparisonData
            }
          }
        );
      }
    });
  }

  /**
   * Opens the monitor modal.
   * @param dataItem The instance data.
   */
  public openMonitorModal(collectionId: number, instanceId: number, versionId: number): void {
    if (collectionId && instanceId && versionId) {
      this.monitorService
        .getMonitorData(
          collectionId,
          instanceId,
          versionId
        )
        .pipe(take(1))
        .subscribe({
          next: (value: MonitorDto) => {
            this.dialog.open(MonitorModalComponent, {
              data: { monitorDto: value }
            });
          }
        });
    }
  }

  public openUnlinkFromFolderModal(unlinkFromFolderParams: UnlinkFromFolderParams): void {
    this.collectionListService.getFoldersFromInstance(unlinkFromFolderParams.collectionId, unlinkFromFolderParams.instanceId)
      .pipe(take(1))
      .subscribe({
        next: (folders: InstanceFolderTreeDto[]) => {
          this.dialog.open(
            UnlinkFromFolderModalComponent,
            {
              data: {
                ...unlinkFromFolderParams,
                folders: folders
              }
            }
          );
        }
      });
  }

  /**
   * Opens the archive modal.
   * @param dataItem The instance data.
   * @param permissions The permissions on the instance.
   */
  public openArchiveModal(dataItem: CollectionListDataInstance, permissions: GridContextPermissions): void {
    if (dataItem != undefined && dataItem.CollectionsID) {
      this.dialog.open(ArchiveModalComponent, {
        data: { dataItem, permissions }, width: '100%', height: '100%'
      });
    }
  }

  /**
   * Opens the history page.
   * @param dataItem The instance data.
   */
  public openHistoryPage(collectionId: number, instanceId: number, versionId: number, workspaceId = 0, addToViewStack = true): void {
    if (collectionId && instanceId && versionId) {
      if (addToViewStack) {
        const viewstackItem = new ViewStackItem<HistoryParams>(ViewStackHistoryComponent, collectionId.toString());
        this.collectionHistoryService.getGridOptions(collectionId)
          .pipe(take(1))
          .subscribe({
            next: (value) => {
              viewstackItem.componentData = {
                collectionId: collectionId,
                instanceId: instanceId,
                versionId: versionId,
                gridOptions: value
              };
              this.viewStackService.addItemToStack(viewstackItem);
            }
          });
      } else {
        this.router
          .navigate(
            [
              '/workspace',
              workspaceId,
              'history',
              'collectionId',
              collectionId,
              'instanceId', instanceId,
              'versionId', versionId
            ]
          )
          .then();
      }
    }
  }

  /**
   * Opens the history page.
   * @param dataItem The instance data.
   */
  public openPermissionsPage(collectionId: number, instanceId: number, versionId: number, folderId = 0, addToViewStack = true): void {
    if (collectionId && instanceId && versionId) {
      this.collectionListService.permissionsFromInstance(collectionId, instanceId, versionId, folderId)
        .pipe(take(1))
        .subscribe({
          next: (value: InstancePermissionsDto) => {
            const data = {
              collectionId: collectionId,
              instanceId: instanceId,
              versionId: versionId,
              folderId: folderId,
              permissions: value
            };
            if (addToViewStack) {
              const viewstackItem = new ViewStackItem<PermissionsParams>(ViewStackPermissionsComponent, collectionId.toString());

              viewstackItem.componentData = data;
              this.viewStackService.addItemToStack(viewstackItem);
            } else {

              this.dialog.open(
                PermissionsModalComponent,
                { data });
            }
          }
        });

    }
  }

  /**
   * Opens the file history modal.
   * @param dataItem The instance data.
   */
  public openFileHistoryModal(dataItem: CollectionListDataInstance): void {
    if (dataItem != undefined && dataItem.CollectionsID) {
      this.archiveService
        .getFileVersions(dataItem)
        .pipe(take(1))
        .subscribe({
          next: (value: FileHistoryDto) => {
            this.dialog.open(FileHistoryModalComponent, {
              data: { dataItem, history: value }
            });
          }
        });
    }
  }

  public openReportModal(collectionId: number, instanceId: number, versionId: number): void {
    if (collectionId) {
      this.collectionListService.exportPdf(collectionId)
        .pipe(take(1))
        .subscribe({
          next: (data: ReportTileListDto) => {
            this.dialog.open(ReportModalComponent, {
              data: {
                collectionId: collectionId,
                instanceId: instanceId,
                versionId: versionId,
                reportList: data
              }
            });
          }
        });
    }
  }

  public openTrackchangesPage(collectionId: number, instanceId: number, versionId: number): void {
    if (collectionId && instanceId && versionId) {
      const viewstackItem = new ViewStackItem<TrackChangesParams>(ViewStackTrackChangesComponent, collectionId.toString());
      this.trackChangesService.getGridOptions()
        .pipe(take(1))
        .subscribe({
          next: (value: GridOptionsDto) => {
            viewstackItem.componentData = {
              collectionId: collectionId,
              instanceId: instanceId,
              versionId: versionId,
              auditlogId: 0,
              gridOptions: value
            };
            this.viewStackService.addItemToStack(viewstackItem);
          }
        });
    }
  }

  /**
   * Opens the flow status page.
   * @param dataItem The instance data.
   * @param workspaceId The workspace id.
   * @param addToViewStack Add the flow status to the view stack or open it by url
   */
  public openFlowStatusPage(collectionId: number, instanceId: number, versionId: number, methodType: CollectionMethodType, workspaceId = 0,
                            addToViewStack = false): void {
    if (!collectionId ||
      !instanceId || !versionId || !methodType) {
      throw new Error('dataItem is invalid or undefined');
    }

    if (addToViewStack) {
      this.store$.dispatch(
        flowStatusActions.getFlowStatusForViewStack({
          collectionsID: collectionId,
          instancesID: instanceId,
          versionsID: versionId,
          methodType: methodType
        })
      );
    } else {
      this.router
        .navigate(
          [
            '/workspace',
            workspaceId,
            'flow-status',
            'collectionId',
            collectionId,
            'instanceId', instanceId,
            'versionId', versionId,
            'methodType', methodType
          ]
        )
        .then();
    }
  }

  public openDocumentSettings(collectionId: number, instanceId: number, versionId: number): void {
    if (!collectionId || !instanceId || !versionId) {
      throw new Error('paramaters is invalid or undefined');
    }
    this.collectionListService.getPropertiesFromInstance(collectionId, instanceId, versionId)
      .pipe(take(1))
      .subscribe({
        next: (properties: CollectionInstancePropertiesDto) => {
          this.dialog.open<DocumentSettingsParams>(DocumentSettingsComponent, {
            data: {
              collectionId: collectionId,
              instanceId: instanceId,
              versionId: versionId,
              properties: properties
            }
          });
        }
      });
  }

  public fetchPermissionAndDownloadFromInstance(request: { CollectionsID: number, VersionsID: number }): void {
    this.mediaApiService.getFileMeta({
      collectionId: request.CollectionsID,
      versionId: request.VersionsID
    }).pipe(take(1)).subscribe({
      next: (value: CollectionInstanceMetadata) => {
        this.downloadFromInstance({
          CollectionsID: value.CollectionsID,
          ID: value.InstancesID,
          VersionsID: value.VersionsID,
          DraftsID: value.DraftVersionsID,
          CanReadDrafts: value.CanReadDrafts,
          PDFMediaID : value.PDFMediaID,
          Permissions : {
            CanReadDocPDF: value.CanReadDocPDF,
            CanReadProperties: value.CanReadProperties
          },
          UsePDFConverter: value.UsePDFConverter
        });
      }
    });
  }

  /**
   * Download the file of an instance with compare if needed for drafsId.
   * @param {{CollectionsID: number, ID: number, VersionsID: number, DraftsID: number, CanReadDrafts: boolean, DocumentStatus: VersionStateType}} dataItem
   */
  public downloadFromInstance(dataItem: {
    CollectionsID: number,
    ID: number,
    VersionsID: number,
    DraftsID: number,
    CanReadDrafts: boolean,
    PDFMediaID?: number,
    Permissions?: any,
    OriginalFoldersID?: number,
    UsePDFConverter?: boolean
  }): void {
    if (dataItem.CanReadDrafts && dataItem.DraftsID > 0) {
      //this.downloadInstance(dataItem); only seems to work when there is a draftsId
      this.downloadInstance(dataItem);
    } else if (dataItem.PDFMediaID != null && dataItem.PDFMediaID > 0 && dataItem.Permissions?.CanReadDocPDF && dataItem.UsePDFConverter) {
      if (dataItem.Permissions.CanReadProperties) {
        this.downloadInstance(dataItem);
      } else {
        this.directDownloadPdfFile(dataItem.CollectionsID, dataItem.ID, dataItem.VersionsID);
      }
    } else {
      this.directDownloadFile(dataItem.CollectionsID, dataItem.ID, dataItem.VersionsID);
    }
  }

  private navigateToInstance(
    collectionId: number,
    instanceId: number,
    versionId: number,
    read: boolean,
    addToViewStack: boolean | undefined,
    schedulerData?: SchedulerDto | undefined,
    isDeletedForm: boolean = false,
    openNewTab: boolean = false
  ): void {
    if (openNewTab) {

      const url = this.router.createUrlTree([
        `/workspace/${this.workspaceIdSignal()}/form/collectionsid/`,
        collectionId,
        'instancesid',
        instanceId,
        'versionsid',
        versionId +
        `${isDeletedForm ? 'state/0' : ''}`,
        `${read ? 'readonly' : ''}`
      ]);
      window.open(url.toString(), '_blank');
    } else {
      if (addToViewStack) {
        if (isDeletedForm) {
          this.store$.dispatch(
            formsActions.getFormReadOnly({
              collectionId,
              instanceId,
              versionId,
              state: StateType.Inactive
            })
          );
        } else {
          this.store$.dispatch(
            formsActions.getFormInstanceById({
              collectionId,
              instanceId,
              versionId,
              read,
              schedulerData
            })
          );
        }
        this.dialog.closeAll();
      } else {
        this.router
          .navigate(
            [
              './form/collectionsid/',
              collectionId,
              'instancesid',
              instanceId,
              'versionsid',
              versionId +
              `${isDeletedForm ? '/state/0/readonly' : ''}`
            ],
            { relativeTo: this.route }
          )
          .then();
      }
    }
  }

  /**
   * Retrieve data of draft and public version of an instance
   * TODO: RV handle errors
   * @private
   */
  private getInstanceComparisonDataForDeletion(collectionId: number, originalFolderId: number, instanceId: number, versionId: number, draftId: number): Observable<CollectionInstanceCompareDto> {
    return this.collectionListService
      .getInstanceComparisonData(
        collectionId, originalFolderId, instanceId, versionId, draftId
      )
      .pipe(
        switchMap((comparisonModel: CollectionInstanceCompareDto) =>
          this.collectionListService
            .getFoldersFromInstance(collectionId, instanceId)
            .pipe(
              map((folders: InstanceFolderTreeDto[]) => {
                comparisonModel.folders = folders;
                return comparisonModel;
              })
            )
        )
      );
  }

  private directDownloadFile(collectionsId: number, instanceId: number, versionId: number): void {
    this.mediaApiService.downloadFileWithResponse(collectionsId, instanceId, versionId).pipe(tap(response => {
      if (response.body) {
        const fileName = response.headers.get('x-filename')?.replace(/\+/g, '%20') ?? '';
        this.downloadService.startDownload(response.body, fileName);
      }
    })).subscribe();
  }

  private directDownloadPdfFile(collectionsId: number, instanceId: number, versionId: number): void {
    this.mediaApiService.downloadPdfFileWithResponse(collectionsId, instanceId, versionId).pipe(tap(response => {
      if (response.body) {
        const fileName = response.headers.get('x-filename')?.replace(/\+/g, '%20') ?? '';
        this.downloadService.startDownload(response.body, fileName);
      }
    })).subscribe();
  }
}
