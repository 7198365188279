@if (storeForm && form) {
    <bizz-form-header (canceled)="cancel()" (submitted)="submit($event)"
                      (editClicked)="editForm()"
                      [readMode]="storeForm.data.ReadMode"
                      [buttonStates]="formButtonStates" class="shrink-0"
                      [dataItem]="storeForm"></bizz-form-header>
    <form [formGroup]="form"
          class="flex h-[calc(100%_-_5rem)] w-full max-w-full flex-col overflow-y-auto sm:gap-5 sm:p-5">
        <bizz-loader [showLoader]="!!storeForm.loading" [backgroundClasses]="'!bg-[#000]/20'"
                     [ngClass]="{'hidden': !storeForm.loading }">
        </bizz-loader>

        @if (environment.debug) {
            <div class="flex flex-col gap-4">
                <div class="button-group">
                    <button class="default" type="button" (click)="debugForm('console')">DEBUG: JSON Console</button>
                    <button class="default" type="button" (click)="debugForm('clipboard')">DEBUG: JSON Clipboard</button>
                    <button class="default" type="button" (click)="debugVDS('console')">DEBUG: VDS Console</button>
                    <button class="default" type="button" (click)="debugVDS('clipboard')">DEBUG: VDS Clipboard</button>
                </div>
                <span class="text-default">DEBUG: ViewDataSources</span>
                <pre class="h-8 resize-y overflow-x-hidden overflow-y-scroll text-default">{{ storeForm.data.ViewDataSources | json }}</pre>
            </div>
        }

        <!-- Alerts & Validation Messages -->
        @if (storeForm.validationResult) {
            <bizz-validation-summary id="summary" [validation]="storeForm.validationResult"
                                     (validationLinkClick)="goToFormField($event)"></bizz-validation-summary>
        }
        @if (storeForm.data.TaskInfo != undefined) {
            <div class="mx-4 my-4 flex flex-wrap gap-4 text-default sm:mx-0 sm:my-0">
                <span class="text-lg font-semibold">{{ "Task" | translate }}
                    : {{ storeForm.data.TaskInfo.Subject | notificationFilterPipe }} - {{ "Step" | translate }}
                    : {{ storeForm.data.TaskInfo.StepName }}</span>
                <bizz-icon (click)="taskInfo()" iconName="circle-info" class="size-4 cursor-pointer" kendoTooltip
                           [title]="'MoreInfo' | translate"></bizz-icon>
            </div>

        }

        <!-- TODO: move to components with base class? -->
        <!-- Checkout Warnings -->
        @if (storeForm.data.DocumentProperties.CheckedOutByID > 0 && (storeForm.data.TasksID > 0 || !storeForm.data.ReadMode) &&
        !storeForm.data.IsLocked) {
            <div class="rounded bg-form text-default shadow-dark-grey-md">
                <bizz-form-accordion-header tabIndex="-1" [canCollapse]="false" accentClass="bg-yellow-700">
                    <bizz-icon class="h-4 w-4 text-yellow-500" iconName="triangle-exclamation"></bizz-icon>
                    <span class="font-title text-lg font-bold text-yellow-200 sm:text-base">
                            {{ "TaskNotCompleted" | translate }}
                        </span>
                </bizz-form-accordion-header>
                <div class="flex flex-col items-center gap-2 rounded-b bg-form p-4 sm:px-5 sm:pb-3 sm:pt-1.5">
                    @if (isSelfCheckout) {
                        {{ "FileCheckOut_Intro" | translate }} {{ storeForm.data.DocumentProperties.CheckedOutOn | date }} {{
                                "By" | translate
                        }} {{ storeForm.data.DocumentProperties.checkedOutBy }}
                        {{ "FileCheckOut_Outro" | translate }}
                    } @else {
                        {{ "FileCheckout_Self" | translate }}
                    }
                </div>
            </div>
        }

        <!-- Lock Warnings  Old code added this: && (storeForm.data.ReadMode || storeForm.data.TaskType == taskType.Task)-->
        @if (storeForm.data.IsLocked && (!storeForm.data.ReadMode || (storeForm.data.ReadMode && storeForm.data.TasksID > 0))) {
            <bizz-form-lock [formData]="storeForm.data" (formUnlocked)="formUnlocked($event)"></bizz-form-lock>
        }

        <!-- Light User Warnings -->
        <!--  BizzMineSettings.UserType === Enums.UserType.LightUser && !showSaveCloseForLightUser && ngModel.TaskType === Enums.TaskType.Task      -->
        <!--  showSaveCloseForLightUser = $scope.BizzMineSettings.UserType === Enums.UserType.LightUser && (($scope.ngModel.StepType === Enums.StepType.StartStep && (($scope.ngModel.CollectionType == Enums.CollectionType.ControlledDocument && !$scope.ngModel.HasWorkflow) || $scope.ngModel.CollectionType == Enums.CollectionType.Workflow)) || $scope.ngModel.TaskType == Enums.TaskType.NotificationTask)-->
        @if (storeForm.data.TaskType == taskType.Task && userType == UserType.LightUser) {
            <div class="rounded bg-yellow-300 text-default shadow-dark-grey-md">
                <div class="m-3 flex items-center gap-2 rounded-t">
                    <bizz-icon class="size-4 text-yellow-500"
                               iconName="triangle-exclamation"></bizz-icon>
                    <p>{{ "LightUsers" | translate }}</p>
                </div>
                <div class="flex flex-col items-center gap-2 rounded-b bg-form p-4">
                    <p>{{ "TaskNotExecutableForLightUser" | translate }}</p>
                </div>
            </div>
        }

        <!-- Fallback Warnings -->
        @if (storeForm.data.TaskInfo && storeForm.data.TaskInfo.IsFallback) {
            <div class="relative rounded bg-form text-default shadow-dark-grey-md">
                <div class="z-1 relative h-full w-full rounded bg-yellow-200/30">
                    <div class="h-1 w-full bg-yellow-600 sm:h-2.5 sm:rounded-t">
                    </div>
                    <div class="mx-3 my-2 flex items-center gap-4 sm:rounded-b">
                        <bizz-icon class="size-6 text-yellow-500"
                                   iconName="triangle-exclamation"></bizz-icon>
                        <div class="flex flex-col text-sm">
                            <!-- TODO: optimize, variable? computed from signal? -->
                            @if (storeForm.data.TaskInfo.NumberOfOriginalAssignedTo == 0) {
                                <span>{{ "TaskOriginalAssignedToNobody" | translate }}</span>
                            } @else if (storeForm.data.TaskInfo.NumberOfOriginalAssignedTo >= 1) {
                                <span>{{ "TaskOriginalAssignedTo" | translate }} {{ storeForm.data.TaskInfo.OriginalAssignedTo }}
                                    .</span>
                            }
                            @if (storeForm.data.TaskInfo.NumberOfOriginalAssignedTo > 1) {
                                <span>{{ "TaskMultipleFallbackAssignedTo" | translate }}</span>
                            } @else if (storeForm.data.TaskInfo.NumberOfOriginalAssignedTo == 1) {
                                <span>{{ "TaskSingleFallbackAssignedTo" | translate }}</span>
                            } @else {
                                <span>{{ "TaskNobodyFallbackAssignedTo" | translate }}</span>
                            }
                        </div>
                    </div>
                </div>

            </div>
        }

        <!-- Assessment -->
        @if (storeForm.data.StepType == StepType.AssessmentStep) {
            <bizz-form-assessment [formData]="storeForm.data" [formId]="formId"
                                  (assessmentPropertiesChange)="assessmentUpdated($event)">
            </bizz-form-assessment>
        }

        @if (storeForm.data.StepType == StepType.TrainingAppAssessmentStep) {
            <bizz-form-trainingapp-assessment [formData]="storeForm.data"
                                              (trainingAssessmentDtoPropertiesChange)="trainingAssessmentDtoPropertiesChange($event)">
            </bizz-form-trainingapp-assessment>
        }

        <!-- Scheduler Panel -->
        @if (storeForm.schedulerData) {
            <bizz-scheduler-panel [formData]="storeForm.data" [schedulerData]="storeForm.schedulerData"
                                  (schedulerDataChanged)="updateScheduler($event)"></bizz-scheduler-panel>
        }

        @if (storeForm.data.DocumentProperties.ReadAndUnderstood?.Active) {
            <bizz-form-read-and-understood [formData]="storeForm.data" [formId]="formId"
                                           (readAndUnderstoodChange)="readAndUnderstoodChange($event)">
            </bizz-form-read-and-understood>
        }

        <!-- Accordions -->
        @for (accordion of storeForm.data.Accordions; track accordion.Id) {
            <div class="bg-form shadow-dark-grey-md sm:rounded-b" [ngClass]="{'hidden': accordion.IsHidden}">
                <bizz-form-accordion-header tabIndex="-1" [isOpen]="accordion.AccordionStatus == accordionStatusType.Open"
                                            [accentClass]="userType != UserType.ExternalUser ? 'bg-form-accent' : 'bg-form-accent-external'"
                                            (toggle)="toggleAccordion($event, accordion)">
                    <span class="font-title text-lg font-bold text-form-header sm:text-base">
                        {{ accordion.Caption }}
                    </span>
                </bizz-form-accordion-header>
                <div id="{{accordion.Id}}"
                     class="text-form sm:rounded-b p-4 sm:pt-1.5 sm:pb-3 sm:px-5 grid gap-y-2 gap-x-4 grid-cols-1 lg:grid-cols-{{accordion.ColumnCount}}"
                     [ngClass]="{'hidden' : accordion.AccordionStatus !== accordionStatusType.Open}">
                    @for (row of accordion.Rows; track $index) {
                        @if (!row.IsHidden) {
                            @for (container of row.Containers; track container.Id) {
                                <div class="flex flex-col gap-2 col-span-1 lg:col-span-{{container.ColSpan}}">
                                    @for (field of container.Fields; track field.Id) {
                                        @if (field.CollectionFieldsID != 0 || field.ListSourceCollectionsID !== 0) {
                                            <bizz-form-control class="focus:!border-y-red-500" [fieldId]="field.Id" [formId]="formId"
                                                               [control]="field | fieldFormControl: form"
                                                               [viewDataSources]="storeForm.data.ViewDataSources"
                                                               (fileUploaded)="fileUploaded($event, field)"
                                            >
                                            </bizz-form-control>
                                        } @else {
                                            <bizz-form-element [element]="field" [formId]="formId"></bizz-form-element>
                                        }
                                    }
                                </div>
                            }
                        }
                    }
                </div>
            </div>
        }
    </form>
    <!-- Form -->
}

<!-- Workaround to make sure tailwind includes these interfaces into the final css file -->
<div class="col-span-1 col-span-2 col-span-3 col-span-4 hidden grid-cols-1 grid-cols-2 grid-cols-3 grid-cols-4 lg:col-span-1 lg:col-span-2 lg:col-span-3 lg:col-span-4 lg:grid-cols-1 lg:grid-cols-2 lg:grid-cols-3 lg:grid-cols-4">
</div>