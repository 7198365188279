import { CollectionListDataInstance } from '../../../../../shared/interfaces/collection-list-data-instance';
import { VersionStateType } from '../../../../../../models/ts/version-state-type.model';
import { ProtectedCollectionType } from '../../../../../../models/ts/protected-collection-type.model';
import { InstanceStatusType } from '../../../../../../models/ts/instance-status-type.model';
import { UserType } from '../../../../../../models/ts/user-type.model';
import { FolderInstanceTargetType } from '../../../../../../models/ts/folder-instance-target-type.model';
import { DirectEditMode } from '../../../../../../models/ts/direct-edit-mode.model';
import { DocumentCheckinType } from '../../../../../../models/ts/document-checkin-type.model';
import { CollectionType } from '../../../../../../models/ts/collection-type.model';
import { GridContextPermissionsMetadata } from '../interfaces/grid-context-permissions-metadata';

export class GridContextPermissions {

  public showRead = false;
  public showReadTrashCan = false;
  public showEdit = false;
  public showExecuteSteps = false;
  public showRecover = false;
  public showDownload = false;
  public showMinor = false;
  public showMajor = false;
  public showCheckOut = false;
  public showCheckIn = false;
  public showPermissions = false;
  public showMonitor = false;
  public showReport = false;
  public showRestartWorkflow = false;
  public showDelete = false;
  public showUnlink = false;
  public showDeleteFromFolder = false;
  public showHistory = false;
  public showTrackChanges = false;
  public showArchive = false;
  public showFlowStatus = false;
  public showProperties = false;
  public showDirectLink = false;
  public showDropdownMenu = true;
  public showViewOnline = false;
  public showEditOnline = false;
  public showSubscribeToTraining = false;
  public showSubscribeToExam = false;
  public showDuplicateExams = false;

  public showFolderShortcut = false;
  public showForwardTask = false;
  public canConsultRecord = false;

  public copyLinkToRecord = true;
  public viewFileHistory = false;

  public canCreate = false;
  public canEdit = false;
  public canDelete = false;
  public canExecuteStep = false;
  
  private metaData: GridContextPermissionsMetadata;

  public get isTrashFilterActive(): boolean {
    return this.metaData.trashFilterActive;
  }

  /**
   * Class that is used to determine what actions are displayed in the list view and
   * context menu.
   * This is done based on multiple conditions like the usertype (admin, user, light user..),
   * list options, is the trash filter active and the dataItem.
   * Note: By default only the permissions for the grid actions are calculated, call loadMenuPermissions
   * to calculate the permissions for the context menu.
  */

  public loadActionPermissions(metaData: GridContextPermissionsMetadata, dataItem: CollectionListDataInstance): void {
    this.metaData = metaData;
    if (this.metaData.listOptions.HasValidLicenseToEditCollection) {
      this.canCreate = true;
      this.canDelete = true;
      this.canEdit = true;
      this.canExecuteStep = true;
    }

    this.showRecover = dataItem.Permissions?.CanRecoverFromTrashCan == true && this.metaData.trashFilterActive;
    this.showRead =
      ((dataItem.Permissions?.CanReadProperties == true ||
        dataItem.Permissions?.CanReadDocPDF == true) &&
        (dataItem.CanReadDrafts || dataItem.DocumentStatus == VersionStateType.Active
          || dataItem.DocumentStatus === undefined)) && !this.metaData.trashFilterActive;

    this.showReadTrashCan = dataItem.Permissions?.CanReadProperties == true
      && this.metaData.listOptions.Permissions.CanAccessTrashCan;
    this.showEdit = (dataItem.Permissions?.CanEditProperties == true && this.canEdit) && !this.metaData.trashFilterActive;
    this.showExecuteSteps = this.metaData.listOptions.HasWorkflow && this.enableDisableExecuteSteps(dataItem)
      && this.canExecuteStep && !this.metaData.trashFilterActive;
    this.showSubscribeToTraining = dataItem.ProtectedCollectionType == ProtectedCollectionType.Trainings
      && dataItem.IsTrainingOpen == true && !this.metaData.trashFilterActive;
    this.showSubscribeToExam = dataItem.HasValidExamLicense == true
      && dataItem.ProtectedCollectionType == ProtectedCollectionType.Exams
      && dataItem.ExamIsPublished == true && !this.metaData.trashFilterActive;

    this.showFolderShortcut = this.metaData.isDocumentTreeGrid && dataItem.TargetType == FolderInstanceTargetType.Link;
    this.showForwardTask = dataItem.CanForwardTasks == true;
  }

  /***
   * Loads the permissions for the context menu.
   * This was separated to calculate these permissions only when needed.
   */
  public loadMenuPermissions(dataItem: CollectionListDataInstance): void {
    this.showDownload = !this.hideDownload(dataItem);
    this.showMinor = (dataItem.InstanceStatusType === InstanceStatusType.Completed &&
      dataItem.Permissions?.CanRevisionWithMinorVersionChange == true && dataItem.DraftsID == 0) && !this.metaData.trashFilterActive;
    this.showMajor = dataItem.InstanceStatusType === InstanceStatusType.Completed &&
      dataItem.Permissions?.CanRevisionWithMajorVersionChange == true && dataItem.DraftsID == 0 && !this.metaData.trashFilterActive;
    this.showCheckOut = !this.hideCheckout(dataItem);
    this.showCheckIn = !this.hideCheckIn(dataItem,
      this.metaData.listOptions.Permissions?.Manager);
    this.showPermissions = dataItem.Permissions?.CanSetPermissions == true;
    this.showMonitor = !this.hideMonitor(dataItem, this.metaData.collectionType);
    this.showReport = dataItem.Permissions?.CanGenerateReport == true && !this.metaData.trashFilterActive;

    this.showRestartWorkflow = !this.hideRestartWorkflow(dataItem);
    this.showDelete = !this.metaData.isDocumentTreeGrid && dataItem.Permissions?.CanDeleteToTrashCan != undefined && this.canDelete
      && !this.metaData.trashFilterActive;
    this.showUnlink = this.metaData.isDocumentTreeGrid && this.metaData.selectedTreeNode?.CanUnlinkDocument && this.canDelete
      && this.metaData.userType != UserType.LightUser && !this.metaData.trashFilterActive;
    this.showDeleteFromFolder = this.metaData.isDocumentTreeGrid && dataItem.Permissions?.CanDeleteToTrashCan == true
      && dataItem.TargetType == FolderInstanceTargetType.Original && !this.metaData.trashFilterActive;
    this.showHistory = dataItem.Permissions?.CanAccessHistory == true;
    this.showTrackChanges = dataItem.Permissions?.CanAccessTrackChanges == true;
    this.showArchive = dataItem.Permissions?.CanAccessArchives == true;

    this.showFlowStatus = dataItem.Permissions?.CanViewFlowStatus == true;
    this.showProperties = dataItem.Permissions?.CanViewProperties == true && this.metaData.userType != UserType.LightUser;

    this.showDirectLink = typeof dataItem.RetroDocAccessType !== 'undefined' && dataItem.RetroDocAccessType !== 0
      && this.showDownload && this.metaData.userType != UserType.LightUser;

    this.showDropdownMenu = this.showDownload || this.showMinor || this.showMajor || this.showCheckOut
      || this.showCheckIn || this.showPermissions || this.showMonitor || this.showReport
      || this.showRestartWorkflow || this.showDelete || this.showUnlink || this.showDeleteFromFolder
      || this.showHistory || this.showTrackChanges || this.showArchive || this.showFlowStatus || this.showProperties;

    this.showViewOnline = this.metaData.listOptions.DirectEditMode !== DirectEditMode.Disabled
      && this.metaData.listOptions.DirectEditAdminConsented && this.showDownload && !this.metaData.trashFilterActive;
    this.showEditOnline = this.showViewOnline && (!this.metaData.listOptions.HasWorkflow || this.showCheckOut)
      && this.showEdit && !this.metaData.trashFilterActive;
    this.showDuplicateExams = dataItem.ProtectedCollectionType == ProtectedCollectionType.Exams && !this.metaData.trashFilterActive;
  }

  public setMetaData(metaData: GridContextPermissionsMetadata): void {
    this.metaData = metaData;
  }
  private enableDisableExecuteSteps(dataItem: CollectionListDataInstance): boolean {
    return dataItem.InstanceStatusType != undefined &&
      dataItem.InstanceStatusType != InstanceStatusType.Completed;
  }

  private hideDownload(dataItem: CollectionListDataInstance): boolean {
    if (dataItem.File == undefined)
      return true;

    if (dataItem.DocumentStatus === undefined)
      return true;

    // a) Public Version: Read Permissions
    if (dataItem.DocumentStatus === VersionStateType.Active &&
      (!dataItem.Permissions?.CanReadProperties && !dataItem.Permissions?.CanReadDocPDF))
      return true;

    // b) Draft Version: Edit Permissions + Document must not be checked out
    if (dataItem.DocumentStatus === VersionStateType.Draft &&
      (dataItem.DocumentCheckinType === DocumentCheckinType.CheckedOut || !dataItem.Permissions?.CanEditProperties))
      return true;

    // c) no pdf found, and you only have read as pdf permission then hide download
    const hasPdf = dataItem.PDFMediaID != undefined &&
      dataItem.PDFMediaID > 0 && dataItem.UsePDFConverter;

    return !!((!hasPdf && dataItem.Permissions?.CanReadDocPDF) && !dataItem.Permissions?.CanReadProperties);
  }

  private hideCheckout(dataItem: CollectionListDataInstance): boolean {
    if (dataItem.InstanceLockedByOtherUser) {
      return true;
    }

    if (this.metaData.trashFilterActive || !dataItem.Permissions?.CanEditProperties)
      return true;

    // first check on draft
    if (dataItem.DraftsID != undefined && dataItem.DraftsID > 0) {

      if (dataItem.DraftDocumentCheckinType === DocumentCheckinType.NoDocument || dataItem.DraftDocumentCheckinType !== DocumentCheckinType.CheckedIn)
        return true;
    } else {
      if (dataItem.DocumentCheckinType === DocumentCheckinType.NoDocument || dataItem.DocumentCheckinType !== DocumentCheckinType.CheckedIn)
        return true;
    }
    return false;
  }

  private hideCheckIn(dataItem: CollectionListDataInstance, isManager: boolean): boolean {
    if (dataItem.InstanceLockedByOtherUser) {
      return true;
    }

    if (this.metaData.trashFilterActive || !dataItem.Permissions?.CanEditProperties)
      return true;

    // check if the user has locked the instance or the user has collection manager permissions
    if (isManager || dataItem.CheckedOutByID == this.metaData.userId) {
      // first check on draft
      if (dataItem.DraftsID != undefined && dataItem.DraftsID > 0) {
        if (dataItem.DraftDocumentCheckinType !== DocumentCheckinType.CheckedOut)
          return true;
      } else {
        if (dataItem.DocumentCheckinType !== DocumentCheckinType.CheckedOut)
          return true;
      }
    } else {
      return true;
    }
    return false;
  }

  private hideMonitor(dataItem: CollectionListDataInstance, collectionType: CollectionType): boolean {
    return this.metaData.trashFilterActive || collectionType === CollectionType.Workflow || !dataItem.HasMonitor;

  }

  private hideRestartWorkflow(dataItem: CollectionListDataInstance): boolean {
    if (dataItem.OnStartStep) {
      return true;
  }
    // No restart workflow for exam collection and exam sessions collection
    if (dataItem.ProtectedCollectionType === ProtectedCollectionType.Exams ||
      dataItem.ProtectedCollectionType === ProtectedCollectionType.ExamSessions) {
      return true;
    }

    if (this.metaData.trashFilterActive || !dataItem.Permissions?.CanRestartWorkFlow || !this.metaData.listOptions.HasWorkflow)
      return true;
    // draft is published
    if (dataItem.DraftsID != undefined && dataItem.DraftsID > 0 &&
      dataItem.DraftDocumentCheckinType === DocumentCheckinType.Published)
      return true;
    // no draft but current document is published
    if (dataItem.DraftsID != undefined && dataItem.DraftsID == 0 &&
      dataItem.DocumentCheckinType === DocumentCheckinType.Published)
      return true;

    return dataItem.InstanceStatusType == undefined
  }

}
