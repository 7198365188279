import { createSelector } from '@ngrx/store';
import { formsFeature } from './forms-feature';
import { CollectionFormService } from '../../../features/bizzmine/form/services/collection-form.service';
import { ProtectedFieldType } from '../../../../models/ts/protected-field-type.model';
import { FormFieldType } from '../../../../models/ts/form-field-type.model';
import { CollectionFormField } from '../../../../models/ts/collection-form-field.model';
import { FormulaService } from '../../../features/bizzmine/form/services/formula/formula.service';

/**
 * Selects the forms state from the store. This selector is the base for almost all other selectors.
 * @param {string} id
 */
export const selectForm = (id: string) => createSelector(
  formsFeature.selectEntities,
  entities => entities[id]
);

/**
 * Selects a form's VDS array from the store by formId.
 * @param {string} id
 */
export const selectFormViewDataSources = (id: string) => createSelector(
  selectForm(id),
  storeForm => {
    return storeForm ? storeForm.data.ViewDataSources : false;
  }
);

/**
 * Selects the lock state of a form from the store by formId.
 * @param {string} id
 */
export const selectFormLockState = (id: string) => createSelector(
  selectForm(id),
  storeForm => {
    return storeForm ? storeForm.data.IsLocked : false;
  }
);

/**
 * Looks through the validationResult to check if the field is present in the validation error array.
 * @param {string} formId
 * @param {number} fieldId
 */
export const selectFormFieldValidationState = (formId: string, fieldId: number) => createSelector(
  selectForm(formId),
  storeForm => {
    if (storeForm != null && storeForm.validationResult != null && !storeForm.validationResult.IsValid && storeForm.validationResult.Errors.length > 0) {
      const field = CollectionFormService.getField(storeForm.data, field => field.Id === fieldId);
      const result = storeForm.validationResult.Errors.find(vr => vr.CollectionFormFieldID == fieldId || vr.Bookmark == field?.Bookmark);
      return result;
    }
    return undefined;
  }
);

/**
 * Selects a form field from the store by formId and formFieldId.
 * Makes use of SelectForm selector to get the form.
 * @param {string} formId
 * @param {number} formFieldId
 */
export const selectFormField = (formId: string, formFieldId: number) => createSelector(
  selectForm(formId),
  form => {
    if (form) {
      return CollectionFormService.getField(form.data, field => field.Id === formFieldId);
    } else {
      return undefined;
    }
  }
);



export const selectIntegrityForFormField = (formId: string, formFieldId: number | undefined) => createSelector(
  selectForm(formId),
  form => {
    if (form && formFieldId) {
      return form.integrity?.find(_ => _.fieldId == formFieldId)?.value;
    } else {
      return undefined;
    }
  }
);

export const selectExternalAccess = (formId: string, viewDataSourceId: number) => createSelector(
  selectForm(formId),
  form => {
    if (form?.data?.ExternalAccessList) {
      const found = form.data.ExternalAccessList.find(_ => _.ViewDataSourcesID == viewDataSourceId && _.FormID == form.data.CollectionFormId);
      return found;
    } else {
      return undefined;
    }
  }
);

export const selectFormFieldByPredicate = (formId: string, predicate: (formField: CollectionFormField) => boolean) => createSelector(
  selectForm(formId),
  form => {
    if (form) {
      return CollectionFormService.getField(form.data, predicate);
    } else {
      return undefined;
    }
  }
);

/**
 * Selects a form field from the store by formId and formFieldId.
 * @param {string} formId
 * @param {number} childCollectionId
 */
export const selectFormInstances = (formId: string, childCollectionId: number) => createSelector(
  selectFormViewDataSources(formId),
  viewDataSources => {
    return viewDataSources ? viewDataSources.filter(vds => vds.ChildCollectionsID == childCollectionId)?.flatMap(vds => vds.Instances) : null;
  }
);

/**
 * Selects a record instance from the store by formId, childCollectionId and recordId.
 * @param {string} formId
 * @param {number} childCollectionId
 * @param {number} recordId
 */
export const selectRecordInstance = (formId: string, childCollectionId: number, recordId: number) => createSelector(
  selectFormInstances(formId, childCollectionId),
  instances => {
    return instances ? instances.find(i => i.CrossLinkedInstancesID == recordId) : null;
  }
);

/**
 * Selects a record instance from the store by formId, fieldId and recordId.
 * @param {string} formId
 * @param {number} fieldId
 * @param {number} recordId
 */
export const selectRecordInstanceFromField = (formId: string, fieldId: number, recordId: number) => createSelector(
  selectForm(formId),
  form => {
    if (form?.data) {
      return CollectionFormService.getRecord(form.data, fieldId, recordId);
    }
    return null;
  }
);

/**
 * Selects a record field from the store by formId, gridFieldId, recordId and protectedFieldTypes.
 * @param {string} formId
 * @param {number} gridFieldId
 * @param {number} recordId
 * @param {Array<ProtectedFieldType>} protectedFieldTypes
 */
export const selectRecordFields = (formId: string, gridFieldId: number, recordId: number, protectedFieldTypes: Array<ProtectedFieldType>) => createSelector(
  selectRecordInstanceFromField(formId, gridFieldId, recordId),
  record => {
    if (record) {
      const result: Record<ProtectedFieldType, CollectionFormField | undefined> = {} as Record<ProtectedFieldType, CollectionFormField | undefined>;
      for (const fieldType of protectedFieldTypes) {
        result[fieldType] = record.Fields?.find(f => f.ProtectedFieldType == fieldType);
      }
      return result;

    } else {
      return null;
    }
  }
);

/**
 * Selects a record field from the store by formId, formFieldId, recordId and recordFieldId.
 * Makes use of SelectFormField selector to get the form field.
 * @param {string} formId
 * @param {number} formFieldId
 * @param {number} recordId
 * @param {(field: CollectionFormField) => boolean} predicate
 */
export const selectGridRecordField = (formId: string, formFieldId: number, recordId: number | undefined, predicate: (field: CollectionFormField) => boolean) => createSelector(
  selectFormField(formId, formFieldId),
  formField => {
    if (formField) {
      const record = CollectionFormService.getRecordFromField(formField, record => record.CrossLinkedInstancesID == recordId);
      if (record) {
        return CollectionFormService.getRecordField(record, predicate);
      } else return undefined;
    } else return undefined;
  }
);

export const selectFormFieldValues = (formId: string, filter: {
  viewDataSourceId?: number | undefined,
  collectionId?: number | undefined
}, protectedFieldTypes: Array<ProtectedFieldType>) => createSelector(
  selectForm(formId),
  (form) => {
    if (form && form.data) {
      const result: Record<ProtectedFieldType, unknown> = {} as Record<ProtectedFieldType, unknown>;
      for (const fieldType of protectedFieldTypes) {
        result[fieldType] = CollectionFormService.getField(form.data, (f) => {
          let predicate = f.ProtectedFieldType == fieldType;
          if (filter?.collectionId) predicate = predicate && f.CollectionsID == filter.collectionId;
          if (filter?.viewDataSourceId) predicate = predicate && f.ViewDataSourcesID == filter.viewDataSourceId;
          return predicate;
        })?.Value;
      }
      return result;

    } else {
      return null;
    }
  }
);

export const selectFormFieldByviewDataSourceId = (formId: string, viewDataSourceId: number) => createSelector(
  selectForm(formId),
  (form) => {
    if (form && form.data) {
      return CollectionFormService.getField(form.data, (f) => {
        return f.ViewDataSourcesID == viewDataSourceId;
      });

    } else {
      return null;
    }
  }
);

export const selectFormFieldsByProtectedFieldType = (formId: string, collectionId: number, protectedFieldTypes: Array<ProtectedFieldType>) => createSelector(
  selectForm(formId),
  (form) => {
    if (form && form.data) {
      const result: Record<ProtectedFieldType, CollectionFormField | undefined> = {} as Record<ProtectedFieldType, CollectionFormField | undefined>;
      for (const fieldType of protectedFieldTypes) {
        result[fieldType] = CollectionFormService.getField(form.data, (f) => {
          let predicate = f.ProtectedFieldType == fieldType;
          if (collectionId > 0) {
            predicate = predicate && f.CollectionsID == collectionId;
          }
          return predicate;
        });
      }
      return result;

    } else {
      return [];
    }
  }
);

export const selectFormFields = (formId: string, filter: {
  formFieldType: FormFieldType,
  protectedFieldType: ProtectedFieldType | null
}) => createSelector(
  selectForm(formId),
  (form) => {
    const query = ((f: CollectionFormField): boolean => {
      let current: boolean = f.FormFieldType == filter.formFieldType;
      if (filter.protectedFieldType) { // Only apply additional filter if it's defined
        current = current && f.FormFieldType == filter.formFieldType &&
          f.Records?.find(r => r.Fields.find(f => f.ProtectedFieldType == filter.protectedFieldType) != null) != null;
      }
      return current;
    });
    if (form && form.data) {
      return CollectionFormService.getFields(form.data, (f) => {
        return query(f);
      });
    } else {
      return null;
    }
  }
);

export const selectFormFieldValue = (formId: string, filter: {
  viewDataSourceId?: number | undefined,
  collectionId?: number | undefined
}, protectedFieldTypes: ProtectedFieldType) => createSelector(
  selectFormFieldValues(formId, filter, [protectedFieldTypes]),
  (fieldValues) => fieldValues ? fieldValues[protectedFieldTypes] : null
);

export const selectFormFieldValueById = (formId: string, formFieldId: number, fieldValueProperty: keyof CollectionFormField) => createSelector(
  selectForm(formId),
  (form) => {
    if (form && form.data) {
      const field = CollectionFormService.getField(form.data, (f) => {
        return f.Id === formFieldId;
      });
      return field ? field[fieldValueProperty] : null;
    } else {
      return null;
    }
  }
);

export const selectFormFieldFormulas = (formId: string, formFieldId: number) => createSelector(
  selectForm(formId),
  (form) => {
    if (form && form.data && form.data.Formulas.length > 0) {
      return FormulaService.fieldFormulas(form.data.Formulas, formFieldId);
    }
    return [];
  }
);
