import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerComponent, DatePickerModule, DateTimePickerModule } from '@progress/kendo-angular-dateinputs';
import { ReactiveFormsModule } from '@angular/forms';
import { BaseFormControlComponent } from '../../../classes/base-form-control.component';
import { userSettingsFeature } from '../../../../../../store/features/user-settings/user-settings-feature';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CollectionFormField } from '../../../../../../../models/ts/collection-form-field.model';
import { placeholderDateFormat } from '../../../../../../shared/constants/date-time-placeholders';
import { TooltipComponent } from '../../../../../../shared/components/ui/tooltip/tooltip.component';
import { DateFilterService } from 'src/app/shared/services/filter/date-filter-service';

/**
 * Represents a control that allows the user to select a date.
 */
@Component({
  selector: 'bizz-date-picker-control',
  standalone: true,
  imports: [CommonModule, DatePickerModule, ReactiveFormsModule, TooltipComponent, DateTimePickerModule],
  templateUrl: './date-picker-control.component.html',
  styleUrls: ['./date-picker-control.component.scss']
})
export class DatePickerControlComponent extends BaseFormControlComponent implements AfterViewInit {
  @ViewChild('input') inputElement: DatePickerComponent ;

  public dateFormat: string;
  protected placeholderFormat: string = placeholderDateFormat;
  public dateString: string = '';
  public constructor(public dateFilterService: DateFilterService,
  ) {
    super();
  }

  public override ngOnInit() {
    super.ngOnInit();
    // TODO: remove once BE passes valid format string
    this.dateFormat = this.placeholderFormat;
   
    this.store$.select(userSettingsFeature.selectDateFormatTypeString).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: value => {
        // TODO: uncomment when BE passes valid format strings
        this.dateFormat = this.dateFilterService.convertDateMomentFormat(value) ?? this.placeholderFormat;
      }
    });
   
  }

  public setCurrentDate(): void {
    if (this.formControl.value === null) this.formControl.setValue(new Date());
    this.focus();
  }

  public ngAfterViewInit(): void {
    // Prevent kendo popup from closing fix IOS mobile
     this.inputElement.close.subscribe((e: Event)=>{
      e.preventDefault();
    })
    const objFrame = document.getElementById('TabIDPicker' + this.formFieldSignal()?.Bookmark);
   
    // Set tabindex to 0 or -1 to rendered input component to override kendo defaults.
    if (objFrame) {
      if (objFrame.firstElementChild) {
        objFrame.firstElementChild.removeAttribute('tabindex')
        objFrame.firstElementChild.removeAttribute('ng-reflect-tabindex')
        objFrame.firstElementChild.setAttribute('tabindex', (this.readOnlyOverride || this.formFieldSignal()?.IsReadOnly ? '-1' : '0'))
        
        if (objFrame.firstElementChild.firstElementChild) {
          objFrame.firstElementChild.firstElementChild.setAttribute('tabindex', (this.readOnlyOverride || this.formFieldSignal()?.IsReadOnly ? '-1' : '0'))
        }
      }
    }
   
  }
 
  protected override valueSetter(field: CollectionFormField) {
    this.formControl.setValue(this.getDateValue(field.Value), { emitEvent: false });
  }

  public getDateValue(dateString: string | Date | null): Date | null {
    if (dateString) {
      if (typeof dateString == 'string') {
        try {
          return new Date(dateString.trim());
        } catch (error) {
          console.error(dateString + ' is not a valid Date string', error);
          return null;
        }
      } else
        return dateString;
    } else return null;
  }
 
  public hidePopup(): void {
   this.inputElement.toggle(false);
  }

  protected override focus(): void {
    this.inputElement.toggle();
    setTimeout(() => {
      this.inputElement.focus();
    }, 20);
  }
}
