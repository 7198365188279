import {
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { GridModule, PageChangeEvent, PagerModule, SharedFilterModule } from '@progress/kendo-angular-grid';
import { GroupDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { BehaviorSubject, combineLatest, debounceTime, filter, map, of, shareReplay, switchMap, tap } from 'rxjs';
import { CollectionHistoryApiService } from 'src/app/api/bizzmine/collection-history/collection-history-api.service';

import { GridOptions } from 'src/app/shared/classes/list/grid-options';
import { IconComponent } from 'src/app/shared/components/ui/icon/icon.component';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { userSettingsFeature } from 'src/app/store/features/user-settings/user-settings-feature';
import { AuditLogGridSearchDto } from 'src/models/ts/audit-log-grid-search-dto.model';
import { CollectionType } from 'src/models/ts/collection-type.model';
import { GridFilterDto } from 'src/models/ts/grid-filter-dto.model';
import { GridGroupSortDto } from 'src/models/ts/grid-group-sort-dto.model';
import { ListDesignGridOptionsDto } from 'src/models/ts/list-design-grid-options-dto.model';
import { UserType } from 'src/models/ts/user-type.model';
import { HistoryParams } from 'src/app/features/bizzmine/history/interfaces/history-params.interface';
import { ExtensionIconComponent } from 'src/app/shared/components/ui/icon/extension-icon/extension-icon.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DownloadService } from 'src/app/core/services/download/download.service';
import { selectByCollectionId } from 'src/app/store/features/collections/collections-selectors';
import { CollectionStoreState } from 'src/app/store/features/collections/collections-state';
import { FilterComponent } from 'src/app/shared/components/ui/filter-component/filter.component';
import {FilterItemType} from "../../../../models/ts/filter-item-type.model";
import { GridComponent } from '../../../shared/components/grid/grid.component';
import { SearchFilterOutputDto } from '../../../../models/ts/search-filter-output-dto.model';
import { PopupComponent } from '@progress/kendo-angular-popup';

@Component({
  selector: 'bizz-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  imports: [
    GridComponent,
    AsyncPipe,
    GridModule,
    PagerModule,
    SharedFilterModule,
    TranslatePipe,
    IconComponent,
    ExtensionIconComponent,
    FilterComponent,
    PopupComponent
  ],
  standalone: true
})
export class HistoryComponent implements OnInit {
  @ViewChild('mobileActionsAnchor')
  public mobileAnchor: ElementRef;
  @Output() public backClick: EventEmitter<void> = new EventEmitter();

  @Input() public params: HistoryParams;


  public data: CollectionStoreState;
  public listOptions: ListDesignGridOptionsDto;
  public gridOptions: GridOptions;
  public gridData: BehaviorSubject<AuditLogGridSearchDto[] | any[]> = new BehaviorSubject<AuditLogGridSearchDto[] | any[]>([]);
  public isTrashView = false;
  public collectionType: CollectionType;
  public loading = true;
  //Can't keep these properties in gridOptions because of binding issue with kendo.
  public currentPage = 1;

  public listSearchFilter$: BehaviorSubject<Partial<AuditLogGridSearchDto> | undefined> = new BehaviorSubject<Partial<AuditLogGridSearchDto> | undefined>(undefined);
  /**
   * Filter data
   */
  public filterData: GridFilterDto | undefined;
  public showMobileActionsPopup = false;

  public searchTerm: string;
  public searchTerm$ = new BehaviorSubject<string>('');
  public debouncedSearchTerm$ = this.searchTerm$.pipe(debounceTime(300));

  public filterWithSearchTerm$ = combineLatest([this.debouncedSearchTerm$, this.listSearchFilter$])
    .pipe(map(found => {
      const filter = found[1];
      if (filter) {
        filter.Search = found[0];
      }
      return filter;
    }));
  /**
   * When the list search filter updates and is not undefined,
   * we fetch the data from the server,
   * update the gridData
   * and update the totalItems and wheter we have an activeFilter
   */
  public gridResultData$ = this.filterWithSearchTerm$.pipe(
    filter((search) => search != undefined),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    map(search => search!),
    tap(() => this.loading = true),
    switchMap((search) => this.collectionHistoryApiService.getHistory(this.params.collectionId, this.params.instanceId, this.params.versionId, search!)),
    tap((value) => {
      this.loading = false;
      this.gridData.next(value.Data);
    }),
    switchMap((value) => {
      return of({
        totalItems: value.TotalRecords,
        activeFilter: value.ActiveSearchfilter
      });
    }),
    shareReplay(1));


  public get userType(): UserType {
    return this.store$.selectSignal(userSettingsFeature.selectUserType)();
  }

  public get userId(): UserType {
    return this.store$.selectSignal(userSettingsFeature.selectUserID)();
  }


  public constructor(private collectionHistoryApiService: CollectionHistoryApiService, private store$: Store,
                     private downloadService: DownloadService, private destroyRef: DestroyRef
  ) {
  }

  public ngOnInit(): void {
    this.store$.select(selectByCollectionId(this.params.collectionId)).subscribe((res) => {
      if (res) {
        this.data = res.data;
        this.gridOptions = new GridOptions(this.params.gridOptions);
        //        this.gridOptions.pageSize = this.data.ListOptions?.Records as number;
        //    this.listOptions = this.data.ListOptions as ListDesignGridOptionsDto;
        this.gridOptions.autoSize = true;
      }
    });

    this.fetchSearchData();


  }

  //TODO: bv check for duplicate code
  public refreshFilterGrid(filterData: SearchFilterOutputDto): void {
    if(filterData.Search?.length){
      this.searchTerm$.next(filterData.Search);
    }else{
      this.filterData = filterData.SearchFilters;
    }

    this.fetchSearchData();
  }

  public fetchSearchData(): void {
    this.listSearchFilter$.next({
      SearchFilters: this.filterData,
      Page: this.currentPage,
      Records: this.gridOptions.pageSize,
      Group: this.gridOptions.dataSource.group as GridGroupSortDto[],
      Sort: this.gridOptions.dataSource.sort as GridGroupSortDto[]
    });
  }

  public onPageChange(event: PageChangeEvent): void {
    this.currentPage = (event.skip / event.take) + 1; // Update the current page number
    this.gridOptions.pageSize = event.take;
    this.fetchSearchData();
  }

  public onGroupChange(groups: Array<GroupDescriptor>): void {
    //ColumnOrder
    this.gridOptions.dataSource.group = groups;
    this.fetchSearchData();
  }

  public onSortChange(sorting: Array<SortDescriptor>): void {
    this.gridOptions.dataSource.sort = sorting;
    this.fetchSearchData();
  }

  public export(): void {
    this.collectionHistoryApiService.getHistoryExport(this.params.collectionId, this.params.instanceId, this.params.versionId, this.listSearchFilter$.value!).pipe(
      takeUntilDestroyed(this.destroyRef),
      map(file =>
        this.downloadService.startDownload(file, this.data.Caption + '_' + this.params.instanceId + '_History.csv')
      )).subscribe();
  }

  public onBackClicked(): void {
    if (this.backClick != undefined)
      this.backClick.emit();
    //this.location.back();
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: KeyboardEvent): void {
    if (!this.showMobileActionsPopup &&
      this.mobileAnchor.nativeElement.contains(event.target))
      this.toggleMobileActionsPopup(undefined, false);
    else if (this.showMobileActionsPopup)
      this.toggleMobileActionsPopup(undefined, false);
  }

  public toggleMobileActionsPopup(event?: MouseEvent, value?: boolean): void {
    this.showMobileActionsPopup = value != undefined ? value : !this.showMobileActionsPopup;
    if (event) {
      //prevent documentClick being called.
      event.stopImmediatePropagation();
    }

  }

    protected readonly FilterItemType = FilterItemType;
}
