import { Injectable } from '@angular/core';
import { CollectionForm } from '../../../../../models/ts/collection-form.model';
import { CollectionFormField } from '../../../../../models/ts/collection-form-field.model';
import { CollectionFormService } from './collection-form.service';
import { UpdateDeleteState } from '../../../../../models/ts/update-delete-state.model';
import { ViewDataSource } from '../../../../../models/ts/view-data-source.model';
import { ViewDataSourcesInstance } from '../../../../../models/ts/view-data-sources-instance.model';
import { LinkedCollectionType } from '../../../../../models/ts/linked-collection-type.model';
import { CollectionFormLinkedService } from './collection-form-linked.service';
import _ from 'lodash';

/**
 * Service for converting CollectionForm objects to JSON. Functions return plain objects formatted as the back end expects.
 */
@Injectable({
  providedIn: 'root'
})
export class CollectionFormJsonService {

  /**
   * Returns a forms JSON representation
   * @param {CollectionForm} form
   * @return {Object}
   */
  public static formToJson(form: CollectionForm): Object {
    let formJson: Object = {};
    // Base form fields
    Object.assign(formJson, CollectionFormJsonService.baseFieldsToJson(form));
    // Fixed properties
    Object.assign(formJson, CollectionFormJsonService.formPropertiesToJson(form));
    // Relations (ViewDataSources)
    Object.assign(formJson, CollectionFormJsonService.viewDataSourcesToJson(form));
    return formJson;
  }

  /**
   * Returns a fields JSON representation.
   * @param {CollectionFormField} field
   * @param {ViewDataSourcesInstance} instance
   * @return {Object}
   */
  public static fieldToJson(field: CollectionFormField, instance?: ViewDataSourcesInstance): Object {
    const fieldJson = CollectionFormJsonService.getJsonForField(field, instance)
    return _.isEmpty(fieldJson) ? {} : { [field.Bookmark]: fieldJson };
  }

  /**
   * Returns an array of JSON representation of all fields not part of a relation.
   * @param {CollectionForm} form
   * @return {Object}
   */
  public static baseFieldsToJson(form: CollectionForm): Object {
    const fields = this.getFieldsWithValue(CollectionFormService.getFields(form, field => field.ViewDataSourcesID == 0 && !CollectionFormService.fieldIsGrid(field)));
    let jsonFields: Object = {};
    fields.forEach(field => {
      Object.assign(jsonFields, CollectionFormJsonService.fieldToJson(field));
    });
    return jsonFields;
  }

  public static getFieldsWithValue(fields: Array<CollectionFormField>): Array<CollectionFormField>{
      return _.filter(fields, function (field) {
        return ((field.Value != undefined && field.Value != null && field.Value !== "") || field.State == UpdateDeleteState.Update) || field.OrgChartUnitSelector.length > 0 || (field.IsHidden || field.IsRequired || field.Evaluations.length > 0) || (field.Bookmark != null && field.Bookmark.toLowerCase() == "mailinglist");
      });
  }

  /**
   * Returns an array of JSON representation of a number of form properties.
   * @param {CollectionForm} form
   * @return {Object}
   */
  public static formPropertiesToJson(form: CollectionForm): Object {
    const propertiesObject = {
      DocumentProperties: form.DocumentProperties,
      InheritType: form.InheritType,
      DisplayFieldsInheritType: form.DisplayFieldsInheritType,
      ExamSubscriptions: form.ExamSubscriptions,
      TrainingAssessment: form.TrainingAssessment
    };
    // TODO: Back end should handle 0 value correctly. (CollectionJsonMapper => AddFieldsToBaseCollectionsModel => ?m.IsLookup = true?)
    if (form.VersionsID > 0)
      Object.assign(propertiesObject, { versionsID: form.VersionsID });
    return propertiesObject;

  }

  /**
   * Returns the JSON representation of all ViewDataSources in given form.
   * @param {CollectionForm} form
   * @return {Object}
   */
  public static viewDataSourcesToJson(form: CollectionForm): Object {
    let viewDataSourcesJson: Object = {};
    // Only top level ViewDataSources that are linked to the form collection are useful for JSON
    form.ViewDataSources.filter(vds => vds.ParentCollectionsID == form.CollectionsID && vds.ParentDataSourcesID == 0).forEach(viewDataSource => {
      Object.assign(viewDataSourcesJson, CollectionFormJsonService.viewDataSourceToJson(form, viewDataSource));
    });
    return viewDataSourcesJson;
  }

  /**
   * Returns the JSON representation of a ViewDataSource.
   * @param {CollectionForm} form
   * @param {ViewDataSource} viewDataSource
   * @return {Object}
   */
  public static viewDataSourceToJson(form: CollectionForm, viewDataSource: ViewDataSource): Object {
    let instancesJson = {};
    if (viewDataSource.ParentDataSourcesID == 0) {
      instancesJson = CollectionFormJsonService.viewDataSourceInstancesToJson(form, viewDataSource);
    } else {
      instancesJson = CollectionFormJsonService.deeperLevelViewDataSourceInstancesToJson(form, viewDataSource);
    }
    return _.isEmpty(instancesJson) ? {} : { [viewDataSource.Name]: instancesJson };
  }

  /**
   * Returns instances belonging to a deeper level VDS.
   * @param {CollectionForm} form
   * @param {ViewDataSource} viewDataSource
   * @return {Object}
   */
  public static deeperLevelViewDataSourceInstancesToJson(form: CollectionForm, viewDataSource: ViewDataSource): Object[] {
    // Instances are kept in the snapshot ViewDataSource linked to the current ViewDataSource
    // const snapShotViewDataSource = viewDataSource.SingleOrMany == LinkedCollectionType.SingleRecord ? form.ViewDataSources.find(
    //   vds => vds.IsSnapshotRelation && vds.ParentDataSourcesID == viewDataSource.ViewDataSourcesID
    // ) : viewDataSource;
    const snapShotViewDataSource = viewDataSource;
    const parentGridViewDataSource = CollectionFormLinkedService.getParentGridViewDataSource(form, viewDataSource);
    if (snapShotViewDataSource !== undefined) {
      let viewDataSourceInstancesJson: Object[] = [];
      snapShotViewDataSource.Instances.forEach(instance => {
        let viewDataSourceInstance = {};
        // Fields, if any parent is a grid, grid vds is required as well
        if (parentGridViewDataSource !== undefined)
          Object.assign(viewDataSourceInstance, CollectionFormJsonService.viewDataSourceInstanceGridFieldsToJson(form, viewDataSource, parentGridViewDataSource, instance));
        else
          Object.assign(viewDataSourceInstance, CollectionFormJsonService.viewDataSourceInstanceFieldsToJson(form, viewDataSource, instance));
        // Properties
        Object.assign(viewDataSourceInstance, CollectionFormJsonService.viewDataSourceInstancePropertiesToJson(viewDataSource, instance));
        // Children
        Object.assign(viewDataSourceInstance, CollectionFormJsonService.viewDataSourceChildrenToJson(form, snapShotViewDataSource));
        viewDataSourceInstancesJson.push(viewDataSourceInstance);
      });
      return viewDataSourceInstancesJson;
    } else return [];
  }

  /**
   * Returns a ViewDataSource's instances in JSON representation.
   * @param {CollectionForm} form
   * @param viewDataSource
   * @return {Object[]}
   */
  public static viewDataSourceInstancesToJson(form: CollectionForm, viewDataSource: ViewDataSource): Object[] {
    let viewDataSourceInstancesJson: Object[] = [];
    viewDataSource.Instances.forEach(instance => {
      let viewDataSourceInstance = {};
      // Fields
      Object.assign(viewDataSourceInstance, CollectionFormJsonService.viewDataSourceInstanceFieldsToJson(form, viewDataSource, instance));
      // Properties
      Object.assign(viewDataSourceInstance, CollectionFormJsonService.viewDataSourceInstancePropertiesToJson(viewDataSource, instance));
      // Children
      Object.assign(viewDataSourceInstance, CollectionFormJsonService.viewDataSourceChildrenToJson(form, viewDataSource));
      viewDataSourceInstancesJson.push(viewDataSourceInstance);
    });
    return viewDataSourceInstancesJson;
  }

  /**
   * Returns the JSON representation of a ViewDataSource's children.
   * @param {CollectionForm} form
   * @param {ViewDataSource} viewDataSource
   * @return {Object}
   */
  public static viewDataSourceChildrenToJson(form: CollectionForm, viewDataSource: ViewDataSource): Object {
    let childViewDataSources = form.ViewDataSources.filter(vds => vds.ParentDataSourcesID == viewDataSource.ViewDataSourcesID && !vds.IsSnapshotRelation);
    if (childViewDataSources.length > 0) {
      let childViewDataSourcesJson: Object = {};
      childViewDataSources.filter(vds => vds.Instances.length > 0).forEach(childViewDataSource => {
        Object.assign(childViewDataSourcesJson, CollectionFormJsonService.viewDataSourceToJson(form, childViewDataSource));
      });
      return childViewDataSourcesJson;
    } else return {};
  }

  /**
   * Returns the JSON representation of form fields belonging to given instance.
   * @param {CollectionForm} form
   * @param {ViewDataSource} viewDataSource
   * @param {ViewDataSourcesInstance} instance
   * @return {Object}
   */
  public static viewDataSourceInstanceFieldsToJson(form: CollectionForm, viewDataSource: ViewDataSource, instance: ViewDataSourcesInstance): Object {
    let fields = CollectionFormService.getFields(form, field => field.ViewDataSourcesID == viewDataSource.ViewDataSourcesID);
    let jsonFields: Object = {};
    if (viewDataSource.SingleOrMany == LinkedCollectionType.SingleRecord) {
      fields.forEach(field => {
        Object.assign(jsonFields, CollectionFormJsonService.fieldToJson(field, instance));
      });
      return jsonFields;
    } else {
      // TODO: replace with IsGridField once available.
      let gridField = fields.find(field => CollectionFormService.fieldIsGrid(field));
      if (gridField !== undefined) {
        let record = gridField.Records?.find(record => record.CrossLinkedInstancesID == instance.RowDataDesignCrossID);
        if (record) {
          record.Fields.filter(field => field.ViewDataSourcesID == (viewDataSource.IsSnapshotRelation ? viewDataSource.ParentDataSourcesID : viewDataSource.ViewDataSourcesID)).forEach(field => {
            Object.assign(jsonFields, CollectionFormJsonService.fieldToJson(field, instance));
          });
        }
        return jsonFields;
      }
    }
    return {};
  }

  /**
   * Returns the JSON representation of form fields belonging to given instance.
   * @param {CollectionForm} form
   * @param {ViewDataSource} viewDataSource
   * @param {ViewDataSourcesInstance} instance
   * @return {Object}
   */
  public static viewDataSourceInstanceGridFieldsToJson(form: CollectionForm, viewDataSource: ViewDataSource, parentViewDataSource: ViewDataSource, instance: ViewDataSourcesInstance): Object {
    let jsonFields: Object = {};
    let gridField = CollectionFormService.getFields(form, field => field.ViewDataSourcesID == parentViewDataSource.ViewDataSourcesID).find(field => CollectionFormService.fieldIsGrid(field));
    if (gridField !== undefined) {
      let record = gridField.Records?.find(record => record.CrossLinkedInstancesID == instance.RowDataDesignCrossID);
      if (record) {
        record.Fields.filter(field => field.ViewDataSourcesID == (viewDataSource.IsSnapshotRelation ? viewDataSource.ParentDataSourcesID : viewDataSource.ViewDataSourcesID)).forEach(field => {
          Object.assign(jsonFields, CollectionFormJsonService.fieldToJson(field, instance));
        });
      }
      return jsonFields;
    }
    return {};
  }

  /**
   * Returns the JSON representation of a VDS instance properties.
   * @param {ViewDataSource} viewDataSource
   * @param instance
   * @return {Object}
   */
  public static viewDataSourceInstancePropertiesToJson(viewDataSource: ViewDataSource, instance: ViewDataSourcesInstance): Object {
    return {
      ViewDataSourcesID: viewDataSource.ViewDataSourcesID,
      crossLinkedInstancesID: instance.CrossLinkedInstancesID,
      deleteIfExists: instance.State == UpdateDeleteState.Delete,
      originalChildInstancesID: instance.OriginalChildInstancesID,
      previousChildVersionsID: instance.PreviousChildVersionsID,
      rowDataDesignCrossID: instance.RowDataDesignCrossID,
      snapshotCrossLinkInstancesID: instance.SnapshotCrosslinkInstancesID,
      versionsID: instance.ChildVersionsID
    };
  }

  /**
   * Returns the json representation of a field or errors out if no value could be processed to JSON.
   * @param {ViewDataSourcesInstance} instance
   * @param {CollectionFormField} field
   * @return {Object}
   */
  public static getJsonForField(field: CollectionFormField, instance?: ViewDataSourcesInstance): Object {
    let jsonField = CollectionFormService.getValueForField(field);

    if (jsonField !== null && instance !== undefined) {
      // updateIfExists
      CollectionFormJsonService.jsonFieldUpdateIfExists(jsonField, instance, field);
      // deleteIfExists
      CollectionFormJsonService.jsonFieldDeleteIfExists(jsonField, instance, field);
      return jsonField;
    } else return jsonField ?? {};
  }

  /**
   * Mutates the given json field object with updateIfExists property if required.
   * @param {Object} jsonField
   * @param {ViewDataSourcesInstance} instance
   * @param {CollectionFormField} field
   */
  public static jsonFieldUpdateIfExists(jsonField: Object, instance: ViewDataSourcesInstance, field: CollectionFormField): void {
    if (!field.IsCrossLinkedField && instance.CrossLinkedInstancesID > 0)
      Object.assign(jsonField, { updateIfExists: true });
  }

  /**
   * Mutates the given json field object with updateIfExists property if required.
   * @param {Object} jsonField
   * @param {ViewDataSourcesInstance} instance
   * @param {CollectionFormField} field
   */
  public static jsonFieldDeleteIfExists(jsonField: Object, instance: ViewDataSourcesInstance, field: CollectionFormField): void {
    if (instance.CrossLinkedInstancesID > 0 && instance.State == UpdateDeleteState.Delete)
      Object.assign(jsonField, { deleteIfExists: true });
  }
}
