import { Component, computed, EventEmitter, inject, Input, Output, Signal } from '@angular/core';
import { IconComponent } from '../../../../../../../../shared/components/ui/icon/icon.component';
import { TranslatePipe } from '../../../../../../../../shared/pipes/translate/translate.pipe';
import { CollectionFormField, Record } from 'src/models/ts/collection-form-field.model';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { LinkedCollectionStorageType } from '../../../../../../../../../models/ts/linked-collection-storage-type.model';
import { CollectionType } from '../../../../../../../../../models/ts/collection-type.model';
import { AuthService } from '../../../../../../../../core/services/auth/auth.service';
import { Claims } from '../../../../../../../../core/constants/claims';
import { UserType } from '../../../../../../../../../models/ts/user-type.model';
import { UpdateDeleteState } from '../../../../../../../../../models/ts/update-delete-state.model';
import {
  AllowDeletionLinkedRecordsType,
  CollectionFormExternalAccessDto
} from '../../../../../../../../../models/ts/collection-form-external-access-dto';
import { FormType } from '../../../../../../../../../models/ts/form-type.model';

@Component({
  selector: 'bizz-from-field-grid-action-cell',
  standalone: true,
  templateUrl: './from-field-grid-action-cell.component.html',
  styleUrl: './from-field-grid-action-cell.component.scss',
  imports: [IconComponent, TranslatePipe, TooltipsModule]
})
export class FromFieldGridActionCellComponent {

  @Input({ required: true }) public record: Record;
  @Input({ required: true }) public recordsSignal: Signal<Record[]>;
  @Input({ required: true }) public gridFieldSignal: Signal<CollectionFormField | undefined>;
  @Input() public externalAccess: CollectionFormExternalAccessDto | undefined;
  @Input() public formType?: FormType;
  @Input() public actionsDisabled: boolean;

  public recordSignal = computed(() => this.recordsSignal().find(r => r.CrossLinkedInstancesID == this.record.CrossLinkedInstancesID));
  public userType = inject(AuthService).getAccessTokenClaim(Claims.UserType);

  public canReadInStack = computed(() => {
    const gridField = this.gridFieldSignal();
    const record = this.recordSignal();
    if (gridField && record) {
      return this.userType != UserType.AnonymousUser && this.userType != UserType.ExternalUser && gridField.CanReadOnStack && gridField.LinkedCollectionStorageType != LinkedCollectionStorageType.Historical && this.userType != UserType.ExternalUser;
    } else return false;
  });

  public canEditInStack = computed(() => {
    const gridField = this.gridFieldSignal();
    const record = this.recordSignal();
    if (gridField && record) {
      return this.userType != UserType.AnonymousUser && this.userType != UserType.ExternalUser && gridField.CanEditOnStack && gridField.LinkedCollectionStorageType != LinkedCollectionStorageType.Historical && !gridField.IsReadOnly && !record.EditMode && record.State != UpdateDeleteState.Update;
    } else return false;
  });

  public canEditInline = computed(() => {
    const gridField = this.gridFieldSignal();
    const record = this.recordSignal();
    if (gridField && record) {
      return this.externalUserCheck(this.externalAccess?.AllowCreateRecord && this.externalAccess?.AllowLookup) && !gridField.IsReadOnly && !record.EditMode;
    } else return false;
  });

  // @Deprecated
  public canEditDocument = computed(() => {
    const gridField = this.gridFieldSignal();
    const record = this.recordSignal();
    if (gridField && record) {
      return !gridField.IsReadOnly && !record.EditMode && gridField.CollectionType == CollectionType.ControlledDocument && this.userType != UserType.AnonymousUser && this.userType != UserType.ExternalUser;
    } else return false;
  });

  public canDelete = computed(() => {
    const gridField = this.gridFieldSignal();
    if (gridField) {
      return this.externalUserCheck(this.externalAccess?.AllowDeletionLinkedRecords == AllowDeletionLinkedRecordsType.Yes) && !gridField.IsReadOnly;
    } else return false;
  });

  public canSave = computed(() => {
    const gridField = this.gridFieldSignal();
    const record = this.recordSignal();
    if (gridField && record) {
      return !gridField.IsReadOnly && record.EditMode;
    } else return false;
  });

  private externalUserCheck(combineWith?: boolean) : boolean {
    if((this.userType != UserType.AnonymousUser && this.userType != UserType.ExternalUser) || (this.formType != null && this.formType != FormType.ExternalForm)) {
      return true;
    }

    if(this.externalAccess == undefined){
      return false;
    }

    return combineWith ?? true;
  }

  @Output() public openClicked: EventEmitter<SelectedGridData> = new EventEmitter<SelectedGridData>();
  @Output() public editInNewWindowClicked: EventEmitter<SelectedGridData> = new EventEmitter<SelectedGridData>();
  @Output() public editInlineClicked: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() public deleteClicked: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() public saveClicked: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() public editDocumentClicked: EventEmitter<Event> = new EventEmitter<Event>();

  public onOpenClicked() : void {
    const field = this.gridFieldSignal();
    if(field){
      this.openClicked.emit({
        viewDataSourceId: field.DataDesignViewDataSourcesID,
        crossLinkedInstancesId: this.record.RowDataDesignCrossID ?? this.record.CrossLinkedInstancesID
      });
    }
  }

  public onEditInNewWindowClicked() : void {
    const field = this.gridFieldSignal();
    if(field){
      this.editInNewWindowClicked.emit({
        viewDataSourceId: field.DataDesignViewDataSourcesID,
        crossLinkedInstancesId: this.record.RowDataDesignCrossID ?? this.record.CrossLinkedInstancesID
      });
    }
  }

  protected readonly LinkedCollectionStorageType = LinkedCollectionStorageType;
}

export interface SelectedGridData {
  viewDataSourceId: number;
  crossLinkedInstancesId: number;
}