import { DestroyRef, inject, Injectable } from '@angular/core';
import { OAuthEvent, OAuthService, TokenResponse } from 'angular-oauth2-oidc';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, filter, Observable, take } from 'rxjs';
import { ActivatedRoute, Params, Router, UrlTree } from '@angular/router';
import * as moment from 'moment';
import { AccessToken } from '../../constants/access-token';
import { Claims } from '../../constants/claims';
import { BizzMineSessionStorageService } from '../../../shared/services/localStorage/bizzmine-session-storage.service';
import { SignalRService } from '../../signalR/signal-r.service';
import { PasswordResetMessage } from '../../../features/bizzmine/widgets/copilot-widget/interfaces/copilot-widget-message';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EditOnlineApiServiceService } from '../../../api/bizzmine/edit-online/edit-online-api-service.service';
import { BlackListApiService } from '../../../api/bizzmine/black-list/black-list-api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  /**
   * Holds a boolean which will be true when the OAuthService has been initialized
   * @private
   */
  private readonly _oAuthInitialized: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  /**
   * Configures the OAuthService with data required to initialize CodeFlow for IdentityServer
   *
   * On completion will set _oAuthInitialized to true
   */

  private oAuthService = inject(OAuthService);
  private router = inject(Router);
  private sessionStorage = inject(BizzMineSessionStorageService);
  private editOnlineApiService = inject(EditOnlineApiServiceService);
  private signalRService = inject(SignalRService);
  private route = inject(ActivatedRoute);
  private blackListApiService = inject(BlackListApiService);

  public constructor(private destroyRef: DestroyRef) {
    this.signalRService.filterByType(PasswordResetMessage, 'PasswordResetMessage').pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.logoutViaIdentityServer();
    });
  }

  public get isInitialized(): boolean {
    return this._oAuthInitialized.value;
  }

  public get isInitializedObservable(): Observable<boolean> {
    return this._oAuthInitialized.asObservable();
  }

  /**
   * Returns whether a user is logged in.
   * Meant for route guards.
   * @returns {Observable<boolean | UrlTree>}
   */
  public get isLoggedIn(): Observable<boolean | UrlTree> {
    return new Observable<boolean | UrlTree>((observer) => {
      if (this._oAuthInitialized.getValue()) {
        observer.next(
          this.rawAccessToken ? true : this.router.createUrlTree(['/auth'])
        );
        observer.complete();
      } else {
        this._oAuthInitialized.subscribe((value) => {
          if (value) {
            observer.next(
              this.rawAccessToken ? true : this.router.createUrlTree(['/auth'])
            );
            observer.complete();
          }
        });
      }
    });
  }

  public get tenant(): string {
    switch (environment.deploymentType) {
      case 'cloud':
        return window.location.host.includes('localhost')
          ? window.location.host
          : window.location.hostname.split('.')[0];
      case 'onPremise':
        return environment.tenant;
      default:
        throw new Error(
          `Unknown deployment type: ${environment.deploymentType}`
        );
    }
  }

  /**
   * Fetches the access token from session storage.
   */
  public get rawAccessToken(): string | null {
    return this.sessionStorage.getItem('access_token');
  }

  /**
   * Returns the decoded access token from session storage.
   * If no token is found or the token fails to decode, returns undefined.
   */
  public get decodedAccessToken(): AccessToken | undefined {
    if (this.rawAccessToken) {
      try {
        const base64Url = this.rawAccessToken.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
          window
            .atob(base64)
            .split('')
            .map(function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
        );
        return JSON.parse(jsonPayload);
      } catch (error) {
        throw new Error('Error: Failed to decode access token');
      }
    }
    return undefined;
  }

  /**
   * Returns true if the access token is expired, no access token was found or no expiration time was found.
   */
  public get tokenExpired(): boolean {
    return (
      this.rawAccessToken == null ||
      moment.now() >= Number(this.sessionStorage.getItem('expires_at'))
    );
  }

  public events(): Observable<OAuthEvent> {
    return this.oAuthService.events;
  }

  public getAccessTokenClaim(claim: Claims): string | number | boolean {
    if (this.decodedAccessToken) {
      return this.decodedAccessToken[claim];
    } else {
      throw new Error('Could not get claim: Access Token not defined');
    }
  }

  public tokenReceivedListener(): Observable<OAuthEvent> {
    return this.oAuthService.events.pipe(filter(event => event.type == 'token_received'));
  }

  public initLogin(): void {
    this.oAuthService.initCodeFlow();
  }

  public tryLogin(): Promise<void> {
    return this.oAuthService.tryLoginCodeFlow();
  }

  public logoutViaIdentityServer(): void {
    this.blackListApiService.blackListToken(this.rawAccessToken ?? '').subscribe({
      next: () => {
        this.oAuthService.revokeTokenAndLogout({
          acr_values: 'product:bizzmine tenant:' + this.tenant
        }).then((r) => {
          console.log(r);
        });
      }
    });
  }

  public refreshTokenViaIdentityServer(): Promise<TokenResponse> {
    return this.oAuthService.refreshToken();
  }

  public checkOAuthQueryParams(params: Params): boolean {
    return !!(
      params['code'] &&
      params['iss'] &&
      params['scope'] &&
      params['session_state'] &&
      params['state']
    );
  }

  public configureIdentityServer(): void {
    this.tokenReceivedListener().pipe(
      take(1)
    ).subscribe({
      next: () => {
        // removing possible temp onedrive files on login / tokenrefresh
        this.editOnlineApiService.removeTempEditOnlineFiles().pipe(take(1)).subscribe();
        if (this.checkOAuthQueryParams(this.route.snapshot.queryParams)) {
          const foundRequestedUrl = this.sessionStorage.getItem('REQUESTED_URL');
          this.sessionStorage.removeItem('REQUESTED_URL');
          if (foundRequestedUrl != null) {
            this.router.navigate([foundRequestedUrl])
              .then()
              .catch(() => {
                this.router.navigate(['./workspace/' + this.getAccessTokenClaim(Claims.DefaultWorkspacesID)]).then();
              });
          } else { //for redirect from login
            this.router.navigate(['./workspace/' + this.getAccessTokenClaim(Claims.DefaultWorkspacesID)]).then();
          }
        }
      }
    });

    this.oAuthService.configure({
      issuer: environment.idpUri,
      clientId: 'hEoUCt2WCJ',
      scope: 'openid offline_access consume_bizzmineapi external_users_consume_bizzmineapi',
      redirectUri: window.location.origin,
      responseType: 'code',
      showDebugInformation: environment.production,
      customQueryParams: {
        acr_values: 'product:bizzmine tenant:' + this.tenant
      }
    });
    this.oAuthService.loadDiscoveryDocument().then(() => {
      this._oAuthInitialized.next(true);
    });
    //Refreshes the token automatically before it expires 
    this.oAuthService.setupAutomaticSilentRefresh();
  }
}
