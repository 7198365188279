import { DestroyRef, Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { BehaviorSubject, filter, map, Observable, of, switchMap } from 'rxjs';
import { ThemeDto } from '../../../../models/ts/theme-dto.model';
import { ThemeApiService } from '../../../api/bizzmine/theme/theme-api.service';
import { Claims } from '../../constants/claims';

import { BizzMineSessionStorageService } from '../../../shared/services/localStorage/bizzmine-session-storage.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { userSettingsFeature } from 'src/app/store/features/user-settings/user-settings-feature';
import { ThemeHTMLConfig } from 'src/models/ts/theme-htmlconfig.model';
import { ThemeConfigElement } from 'src/models/ts/theme-config-element.model';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private bizzMineStyleSheet: CSSStyleSheet;

  /**
   * Behaviorsubject with the current theme, is either loaded from memory, local storage or
   * retrieved from the backend.
   */
  public currentTheme$: BehaviorSubject<ThemeDto | null> = new BehaviorSubject<ThemeDto | null>(null);

  public currentThemesId: number;

  public constructor(
    private store$: Store,
    private authService: AuthService,
    private themeApiService: ThemeApiService,
    private storage: BizzMineSessionStorageService,
    private destroyRef: DestroyRef
  ) {
    //First load last saved theme from local storage
    const savedTheme = this.getThemeFromLocalStorage();
    if (savedTheme != null) {
      this.currentTheme$.next(savedTheme);
    }

    this.store$.select(userSettingsFeature.selectThemesID)
      .pipe(takeUntilDestroyed(this.destroyRef),
        filter(themeId => themeId != null && themeId > 0),
        map(themeId => this.currentThemesId = themeId),
        switchMap(() => this.getActiveTheme()),
        map(theme => {
          this.currentTheme$.next(theme);
        }))
      .subscribe();

    //always listen to oauth events in case the token is updated and we need to retreive
    //a new theme.
    // token set listener
    this.authService.tokenReceivedListener()
      .pipe(takeUntilDestroyed(this.destroyRef),
        filter(() => this.authService.decodedAccessToken != undefined),
        switchMap(() => this.getActiveTheme()),
        map(theme => {
          this.currentTheme$.next(theme);
        }))
      .subscribe();

    this.currentTheme$.pipe(
      takeUntilDestroyed(this.destroyRef),
      filter(theme => theme != null),
      map(theme => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.applyBranding(theme!);
        this.saveThemeInLocalStorage(theme!);
        this.applyHtmlForEditor(theme!);
        this.applyHtmlForNotification(theme!);
        this.applyHtmlForPopover(theme!);
      })
    ).subscribe();


  }

  /**
   * Retrieve the theme from the backend
   * @param themeId
   */
  public getTheme(themeId: number): Observable<ThemeDto> {
    return this.themeApiService.getTheme(themeId);
  }

  public getActiveTheme(): Observable<ThemeDto> {
    let currentThemesId = this.currentThemesId;
    if (!currentThemesId)
      currentThemesId = this.authService.getAccessTokenClaim(Claims.ThemesID) as number;
    if (!currentThemesId)
      throw new Error('No active theme found.');

    if (this.currentTheme$.value != null && this.currentTheme$.value.ID == currentThemesId) {
      return of(this.currentTheme$.value);
    }
    const savedTheme = this.getThemeFromLocalStorage();
    if (savedTheme && savedTheme.ID == currentThemesId)
      return of(savedTheme);
    else if (!this.authService.tokenExpired) {
      return this.getTheme(currentThemesId);
    }
    throw new Error('No active theme found.');
  }

  /**
   * Apply's the theme and saves it in session storage.
   * @param theme
   */
  public applyCssThemeAndBranding(theme: ThemeDto): void {
    if (theme == null)
      throw new Error('Tried to apply theme with value null.');
    this.currentTheme$.next(theme);
  }

  /**
   * Returns the BizzMine Stylesheet or looks for it in the document stylesheets.
   * @private
   */
  private getBizzMineStyleSheet(): CSSStyleSheet {
    if (this.bizzMineStyleSheet) {
      return this.bizzMineStyleSheet;
    } else {
      const styleSheets = [...document.styleSheets];
      const index = styleSheets.findIndex(
        (e) =>
          e.href &&
          (e.href.includes('styles.css') || e.href.includes('styles.min.css'))
      );

      if (index >= 0) {
        this.bizzMineStyleSheet = styleSheets[index];
      }
      return this.bizzMineStyleSheet;
    }
  }

  /**
   * Returns the first index of a CSSStyleRule where the selector matches.
   * @param selector
   * @param rules
   * @private
   */
  private getCssRuleIndex(selector: string, rules: CSSStyleRule[]): number {
    return rules.findIndex((e) => {
      return e.selectorText != null && e.selectorText == selector;
    });
  }

  /**
   * Return the CSSStyleRule of an element by stylesheet.
   * @param element selector for the element
   * @param stylesheet
   * @private
   */
  private getCssClassByElement(
    element: string,
    stylesheet: CSSStyleSheet
  ): CSSStyleRule {
    let index = this.getCssRuleIndex(element, <Array<CSSStyleRule>>[
      ...stylesheet.cssRules
    ]);

    const cssRulesLength = stylesheet.cssRules.length;
    // Insert rule for element if it doesn't exist
    if (index < 0) {
      index = stylesheet.insertRule(element + ' {}', cssRulesLength);
    }

    return <CSSStyleRule>stylesheet.cssRules[index];
  }

  /**
   * Apply all the css variables of the theme to the root document element
   * @param theme theme object with branding variables
   * @private
   */
  private applyBranding(theme: ThemeDto): void {
    if (theme?.BrandingV2) {
      const branding = theme.BrandingV2;
      for (const [key, value] of Object.entries(branding)) {
        if (value)
          document.documentElement.style.setProperty(key, value);
      }
    }
  }

  /**
   * Reads the current theme from session storage as a ThemeDto object.
   * @private
   */
  private getThemeFromLocalStorage(): ThemeDto | undefined {
    const savedThemeStr = this.storage.get('BizzMine-Theme');
    if (savedThemeStr) {
      return JSON.parse(savedThemeStr) as ThemeDto;
    }
    return undefined;
  }

  /**
   * Saves the current theme in session storage.
   * @param theme
   * @private
   */
  private saveThemeInLocalStorage(theme: ThemeDto): void {
    this.storage.set('BizzMine-Theme', JSON.stringify(theme));
  }


  public applyHtmlForEditor(theme: ThemeDto): void {
    this.setHtmlStyles(theme, '.k-editor-content .ProseMirror >');
  }


  public applyHtmlForPopover(theme: ThemeDto): void {
    this.setHtmlStyles(theme, '.bizz-popup-content');
  }


  public applyHtmlForNotification(theme: ThemeDto): void {
    this.setHtmlStyles(theme, '.html-notification');
  }

  private setHtmlStyles(theme: ThemeDto, replaceClassName: string): void {
    const stylesheet = this.getBizzMineStyleSheet();
    const html = JSON.parse(theme.HTML);

    if (stylesheet && html) {
      for (const [key, value] of Object.entries(html)) {
        const elementClassName = key.replace('html-', replaceClassName + ' ');
        const elementClass = this.getCssClassByElement(elementClassName, stylesheet) as CSSStyleRule | any;
        if (elementClass) {
          // border
          this.setBorder(elementClass, html[key]);

          // border-radius
          this.setBorderRadius(elementClass, html[key]);

          // padding
          this.setPadding(elementClass, html[key]);

          // margin
          this.setMargin(elementClass, html[key]);

          // other properties
          elementClass.style['color'] = html[key].css['color'];
          elementClass.style['font-size'] = html[key].css['font-size'];
          elementClass.style['font-weight'] = html[key].css['font-weight'];
          elementClass.style['font-style'] = html[key].css['font-style'];
          elementClass.style['text-decoration'] = html[key].css['text-decoration'];
          elementClass.style['text-align'] = html[key].css['text-align'];
          elementClass.style['text-transform'] = html[key].css['text-transform'];
          elementClass.style['background'] = html[key].css['background'];
        }
      }
    }
  }




  private setBorder(target: CSSStyleRule | any, source: ThemeConfigElement, ignoreDefaultValues = false): void {
    if (source.settings.borderLink) {
      if (ignoreDefaultValues && source.css['border-width'] == "0px")
                return;
      target.style['border-left-width'] = source.css['border-width'];
      target.style['border-right-width'] = source.css['border-width'];
      target.style['border-top-width'] = source.css['border-width'];
      target.style['border-bottom-width'] = source.css['border-width'];
      // color
      target.style['border-left-color'] = source.css['border-color'];
      target.style['border-right-color'] = source.css['border-color'];
      target.style['border-top-color'] = source.css['border-color'];
      target.style['border-bottom-color'] = source.css['border-color'];
      // style
      target.style['border-left-style'] = source.css['border-style'];
      target.style['border-right-style'] = source.css['border-style'];
      target.style['border-top-style'] = source.css['border-style'];
      target.style['border-bottom-style'] = source.css['border-style'];
    } else {
      target.style['border-left-width'] = source.css['border-left-width'];
      target.style['border-right-width'] = source.css['border-right-width'];
      target.style['border-top-width'] = source.css['border-top-width'];
      target.style['border-bottom-width'] = source.css['border-bottom-width'];
      // color
      target.style['border-left-color'] = source.css['border-left-color'];
      target.style['border-right-color'] = source.css['border-right-color'];
      target.style['border-top-color'] = source.css['border-top-color'];
      target.style['border-bottom-color'] = source.css['border-bottom-color'];
      // style
      target.style['border-left-style'] = source.css['border-left-style'];
      target.style['border-right-style'] = source.css['border-right-style'];
      target.style['border-top-style'] = source.css['border-top-style'];
      target.style['border-bottom-style'] = source.css['border-bottom-style'];
    }
  }


  private setBorderRadius(target: CSSStyleRule | any, source: ThemeConfigElement, ignoreDefaultValues = false): void {
    if (source.settings.borderRadiusLink) {
      if (ignoreDefaultValues && source.css['border-radius'] == "0px")
        return;
      target.style['border-top-left-radius'] = source.css['border-radius'];
      target.style['border-bottom-left-radius'] = source.css['border-radius'];
      target.style['border-top-right-radius'] = source.css['border-radius'];
      target.style['border-bottom-right-radius'] = source.css['border-radius'];
    } else {
      target.style['border-top-left-radius'] = source.css['border-top-left-radius'];
      target.style['border-bottom-left-radius'] = source.css['border-bottom-left-radius'];
      target.style['border-top-right-radius'] = source.css['border-top-right-radius'];
      target.style['border-bottom-right-radius'] = source.css['border-bottom-right-radius'];
    }
  }


  private setPadding(target: CSSStyleRule | any, source: ThemeConfigElement, ignoreDefaultValues = false): void {
    if (source.settings.paddingLink) {
      if (ignoreDefaultValues && source.css['padding'] == "0px")
        return;
      target.style['padding-left'] = source.css['padding'];
      target.style['padding-right'] = source.css['padding'];
      target.style['padding-top'] = source.css['padding'];
      target.style['padding-bottom'] = source.css['padding'];
    } else {
      target.style['padding-left'] = source.css['padding-left'];
      target.style['padding-right'] = source.css['padding-right'];
      target.style['padding-top'] = source.css['padding-top'];
      target.style['padding-bottom'] = source.css['padding-bottom'];
    }
  }

  private setMargin(target: CSSStyleRule | any, source: ThemeConfigElement, ignoreDefaultValues = false): void {
    if (source.settings.marginLink) {
      if (ignoreDefaultValues && source.css['margin'] == "0px")
        return;
      target.style['margin-left'] = source.css['margin'];
      target.style['margin-right'] = source.css['margin'];
      target.style['margin-top'] = source.css['margin'];
      target.style['margin-bottom'] = source.css['margin'];
    } else {
      target.style['margin-left'] = source.css['margin-left'];
      target.style['margin-right'] = source.css['margin-right'];
      target.style['margin-top'] = source.css['margin-top'];
      target.style['margin-bottom'] = source.css['margin-bottom'];
    }
  }

  private applyStylingToEmailContentElement(target: CSSStyleRule | any, source: ThemeConfigElement): void {
      // border
      this.setBorder(target, source, true);

      // border-radius
      this.setBorderRadius(target, source, true);

      // padding
      this.setPadding(target, source, true);

      // margin
      this.setMargin(target, source, true);

      // other properties
      if ((source.css['color'] != "rgb(0,0,0)" && 
          source.css['color'] != "#000000" && 
          !target.style['color'])) {
          target.style['color'] = source.css['color'];
      }

      //always apply font-weight and size
      if (!target.style['font-size'])
          target.style['font-size'] = source.css['font-size'];

      if (!target.style['font-weight'])
          target.style['font-weight'] = source.css['font-weight'];

      if (source.css['font-style'] != "normal" && !target.style['font-style']) {
          target.style['font-style'] = source.css['font-style'];
      }

      if (source.css['text-decoration'] != "none" && !target.style['text-decoration']) {
          target.style['text-decoration'] = source.css['text-decoration'];
      }

      if (source.css['text-align'] != "left" && !target.style['text-align']) {
          target.style['text-align'] = source.css['text-align'];
      }

      if (source.css['text-transform'] != "none" && !target.style['text-transform']) {
          target.style['text-transform'] = source.css['text-transform'];
      }

      if (source.css['background'] != "transparent" && !target.style['background']) {
          target.style['background'] = source.css['background'];
      }
  }

  public applyThemeToEmailContent(htmlContentStr: string): string {
    const parser = new DOMParser();
    const html = parser.parseFromString(htmlContentStr, 'text/html').body;

    if(!html || this.currentTheme$.value == null) {
      return htmlContentStr;
    }

    const htmlStyling = JSON.parse(this.currentTheme$.value.HTML);
    const mailHtmlElements = Array.from(html.children);

    let parsedHtml = '';
    for(const el of mailHtmlElements) {
      this.applyThemeToHtmlElementsRecursive(el, htmlStyling);
      parsedHtml += el.outerHTML;
    }

    return parsedHtml;
  }

  private applyThemeToHtmlElementsRecursive(element: Element, htmlStyling: any) {
    if (!element)
        return;

    var stylingName = 'html-' + element.nodeName.toLowerCase();
    if (htmlStyling.hasOwnProperty(stylingName)) {
        this.applyStylingToEmailContentElement(element, htmlStyling[stylingName]);
    }

    if (element.children && element.children.length > 0) {
      const children = Array.from(element.children);
      for(let el of children) {
        this.applyThemeToHtmlElementsRecursive(el, htmlStyling);
      }
    }
  }

}
