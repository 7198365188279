import { on } from '@ngrx/store';
import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from 'src/app/shared/components/ui/icon/icon.component';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { TooltipComponent } from 'src/app/shared/components/ui/tooltip/tooltip.component';
import { EditorColorPickerComponent, EditorComponent as KendoEditorComponent, EditorModule } from '@progress/kendo-angular-editor';
import {
  alignCenterIcon,
  alignJustifyIcon,
  alignLeftIcon,
  alignRightIcon,
  chevronDownIcon,
  clearCssIcon,
  codeSnippetIcon,
  strikethroughIcon,
  subscriptIcon,
  supscriptIcon,
  tableAddIcon,
  unlinkIcon,
  foregroundColorIcon,
  dropletIcon
} from '@progress/kendo-svg-icons';

@Component({
  selector: 'bizz-editor',
  standalone: true,
  imports: [CommonModule, EditorModule, IconComponent, ButtonModule, TooltipComponent],
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit {

  @ViewChild('editor') public editor: KendoEditorComponent;
  @ViewChild('foreColorPicker') public foreColorPicker: EditorColorPickerComponent;
  @ViewChild('backColorPicker') public backColorPicker: EditorColorPickerComponent;
  public isMobileView = false;
  public chevronDownIcon = chevronDownIcon;

  @Input() public value: string;
  @Output() public valueChange = new EventEmitter<string>();
  @Input() public hideToolbar: boolean = false;
  @Input() public overflowToolbar: boolean = true;
  @Input() public readOnly: boolean = false;
  @Input() public minHeight: number = 250;
  @Input() public id: string = '';
  @Input() public isError: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public iframe: boolean = false;
  @Input() public editorClass:string = '';
  
  public data = [
    {
      text: 'Clean formatting',
      svgIcon: clearCssIcon,
      click: (): void => this.editor.exec('cleanFormatting')
    },
    {
      text: 'Strikethrough',
      svgIcon: strikethroughIcon,
      click: (): void => this.editor.exec('strikethrough')
    },
    {
      text: 'Subscript',
      svgIcon: subscriptIcon,
      click: (): void => this.editor.exec('subscript')
    },
    {
      text: 'Superscript',
      svgIcon: supscriptIcon,
      click: (): void => this.editor.exec('superscript')
    },
    {
      text: 'Foreground color',
      svgIcon: foregroundColorIcon,
      click: (): void => this.foreColorPicker.openDialog()
    },
    {
      text: 'Background color',
      svgIcon: dropletIcon,
      click: (): void => this.backColorPicker.openDialog()
    },
    {
      text: 'Align text left',
      svgIcon: alignLeftIcon,
      click: (): void => this.editor.exec('alignLeft')
    },
    {
      text: 'Align text center',
      svgIcon: alignCenterIcon,
      click: (): void => this.editor.exec('alignCenter')
    },
    {
      text: 'Align text right',
      svgIcon: alignRightIcon,
      click: (): void => this.editor.exec('alignRight')
    },
    {
      text: 'Align text justify',
      svgIcon: alignJustifyIcon,
      click: (): void => this.editor.exec('alignJustify')
    },
    {
      text: 'Unlink',
      svgIcon: unlinkIcon,
      click: (): void => this.editor.exec('unlink')
    },
    {
      text: 'Insert Table',
      svgIcon: tableAddIcon,
      click: (): void => this.editor.exec('insertTable', { rows: 1, cols: 1 })
    },
    {
      text: 'View source',
      svgIcon: codeSnippetIcon,
      click: (): void => this.editor.openDialog('viewSource')
    }
  ];

  // CSS imports (iframe mode)
  public embeddedCss = `
    @font-face {
      font-family: 'Noto Sans';
      font-weight: 100 900;
      font-stretch: 62.5% 100%; // 62.5% is the minimum value for the font-stretch property
      font-style: normal;
      src: url(/assets/fonts/Noto-Sans/NotoSans-Variable.woff2) format("woff2");
    }

    @font-face {
      font-family: Montserrat;
      font-weight: 100 900;
      font-style: normal;
      src: url(/assets/fonts/Montserrat/Montserrat-Variable.woff2) format("woff2");
    }

    .k-content {
      font-family: 'Noto Sans', sans-serif;
    }

    .k-content p {
      font-size: 12px;
    }

    .k-content h1, .k-content h2, .k-content h3, .k-content h4, .k-content h5, .k-content h6 {
      font-family: Montserrat, sans-serif;
    }
  `;

  public constructor() { 
  }

  @HostListener('window:resize')
  public onResize(): void {
    this.isMobileView = window.innerWidth <= 640;
  }

  public ngOnInit(): void {
    //640px is the tailwindcss sm breakpoint (maybe make a class for this)
    this.isMobileView = window.innerWidth <= 640;
  }

  public focus(): void {
      this.editor.focus();
   }

   public onValueChange(value: string): void {
    this.valueChange.emit(value);
   }

}
