import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorColorPickerComponent, EditorComponent, EditorModule } from '@progress/kendo-angular-editor';
import { BaseFormControlComponent } from '../../../classes/base-form-control.component';
import { IconComponent } from 'src/app/shared/components/ui/icon/icon.component';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import {
  alignCenterIcon,
  alignJustifyIcon,
  alignLeftIcon,
  alignRightIcon,
  chevronDownIcon,
  clearCssIcon,
  codeSnippetIcon,
  strikethroughIcon,
  subscriptIcon,
  supscriptIcon,
  tableAddIcon,
  unlinkIcon,
  foregroundColorIcon,
  dropletIcon
} from '@progress/kendo-svg-icons';
import { TooltipComponent } from '../../../../../../shared/components/ui/tooltip/tooltip.component';
import { CollectionFormField } from '../../../../../../../models/ts/collection-form-field.model';
import { EditorComponent as BizzEditorComponent } from "../../../../../../shared/components/editor/editor.component";

/**
 * Represents a control with a rich text editor.
 * Can be simple or contain formatting options and HTML.
 */
@Component({
  selector: 'bizz-memo-control',
  standalone: true,
  imports: [CommonModule, EditorModule, IconComponent, ButtonModule, TooltipComponent, BizzEditorComponent],
  templateUrl: './memo-control.component.html',
  styleUrls: ['./memo-control.component.scss']
})
export class MemoControlComponent extends BaseFormControlComponent implements AfterViewInit {

  @ViewChild('editor') public editor: BizzEditorComponent;

  public ngAfterViewInit(): void{
    const objFrame = document.getElementById('tabID' + this.formFieldSignal()?.Bookmark);
    const iframeObj = objFrame ? objFrame.getElementsByTagName('iframe') : undefined;

    if (iframeObj) {
      iframeObj[0].setAttribute('tabindex', (this.readOnlyOverride || this.formFieldSignal()?.IsReadOnly ? '-1' : '0'))
    }
  }

  public setValue(value: string): void {
    this.formControl.markAsDirty();
    this.formControl.setValue(value);
  }
   // Implement the abstract member from MemoControlComponent
   protected override focus(): void {
     setTimeout(() => {
        this.editor.focus();
      }, 10);
    }

}