import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { formsActions } from '../forms-actions';
import { catchError, exhaustMap, map, of, tap, zip } from 'rxjs';
import { TableFieldDataType } from '../../../../../models/ts/table-field-data-type.model';
import { CollectionFormService } from '../../../../features/bizzmine/form/services/collection-form.service';
import { ProtectedFieldType } from '../../../../../models/ts/protected-field-type.model';
import { Store } from '@ngrx/store';
import { selectForm, selectFormField, selectGridRecordField } from '../forms-selectors';
import { DocumentCheckinType } from '../../../../../models/ts/document-checkin-type.model';
import { getFileExtension, getFileName } from '../../../../shared/functions/helpers/file-name-helpers';
import { concatLatestFrom } from '@ngrx/operators';
import { SelectedOrganizationChartItemDto } from '../../../../../models/ts/selected-organization-chart-item-dto.model';

/**
 * Form Field specific effects, like updating values, setting read only
 */
@Injectable()
export class FormsFieldsEffects {
  private actions$ = inject(Actions);
  private store$ = inject(Store);
  public updateGridFormField$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.updateGridFormField),
    concatLatestFrom(props => [
      this.store$.select(selectGridRecordField(props.formId, props.gridFieldId, props.recordId, field => field.CollectionFieldsID == props.recordFieldId))
    ]),
    map(([props, field]) => {
        if (field !== undefined) {
          switch (field.ComponentType) {
            case TableFieldDataType.EnumList:
              return formsActions.updateGridFormFieldEnumValue({
                formId: props.formId,
                gridFieldId: props.gridFieldId,
                recordId: props.recordId,
                recordFieldId: field.CollectionFieldsID,
                value: Number(props.value)
              });
            case TableFieldDataType.OrganizationChartUnitSelector:
              return formsActions.updateGridOrgChartFieldValue({
                formId: props.formId,
                gridFieldId: props.gridFieldId,
                recordId: props.recordId,
                recordFieldId: field.CollectionFieldsID,
                value: props.value as Array<SelectedOrganizationChartItemDto> ?? []
              });
            default:
              return formsActions.updateGridFormFieldValue({
                formId: props.formId,
                gridFieldId: props.gridFieldId,
                recordId: props.recordId,
                recordFieldId: field.CollectionFieldsID,
                value: props.value
              });
          }
        } else throw new Error(`Could not find field ${props.recordFieldId} on grid ${props.gridFieldId} on form ${props.formId}`);
      }
    )));
  // Used when type of field is unknown in dispatching code
  public updateFormField$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.updateFormField),
    concatLatestFrom(({ formId, fieldId, value }) => this.store$.select(selectFormField(formId, fieldId))),
    map(([{ formId, fieldId, value }, field]) => {
        if (field)
          switch (field.ComponentType) {
            case TableFieldDataType.EnumList:
              return {
                type: formsActions.updateFormFieldEnumValue.type,
                formId: formId,
                fieldId: field.Id,
                value: value
              };
            case TableFieldDataType.OrganizationChartUnitSelector:
              return {
                type: formsActions.updateOrgChartFieldValue.type,
                formId: formId,
                fieldId: field.Id,
                value: value ?? []
              };
            default:
              return {
                type: formsActions.updateFormFieldValue.type,
                formId: formId,
                fieldId: field.Id,
                value: value
              };
          }
        else throw new Error('No field found to update');
      }
    ),
    catchError(err => of(formsActions.updateFormFieldFailed()))
  ));
  public updateFileFields$ = createEffect(() => this.actions$.pipe(
      ofType(formsActions.updateFileFields),
      exhaustMap(({ formId, field, file }) => {
        return zip([this.store$.select(selectForm(formId)), of({ formId, field, file })]);
      }),
      tap(([form, { formId, field, file }]) => {
          if (form) {
            const titleField =
              CollectionFormService.getField(form.data, (f) => {
                return f.ProtectedFieldType == ProtectedFieldType.Title && f.CollectionsID == field.CollectionsID;
              });
            const fileField =
              CollectionFormService.getField(form.data, (f) => {
                return f.ProtectedFieldType == ProtectedFieldType.File && f.CollectionsID == field.CollectionsID;
              });
            const extensionField =
              CollectionFormService.getField(form.data, (f) => {
                return f.ProtectedFieldType == ProtectedFieldType.Extension && f.CollectionsID == field.CollectionsID;
              });
            const sizeField =
              CollectionFormService.getField(form.data, (f) => {
                return f.ProtectedFieldType == ProtectedFieldType.Size && f.CollectionsID == field.CollectionsID;
              });
            const documentChekInStatusField =
              CollectionFormService.getField(form.data, (f) => {
                return f.ProtectedFieldType == ProtectedFieldType.DocumentCheckinStatus
                  && f.CollectionsID == field.CollectionsID;
              });
            if (fileField) {
              this.store$.dispatch(formsActions.updateFormField({ formId, fieldId: fileField.Id, value: file?.MediasID }));
            }
            if (titleField) {
              if (titleField.Value == null || titleField.Value.trim() == '') {
                this.store$.dispatch(formsActions.updateFormField({
                  formId,
                  fieldId: titleField.Id,
                  value: file ? getFileName(file?.FileName) : null
                }));
              }
            }
            if (extensionField) {
              this.store$.dispatch(formsActions.updateFormField({
                formId,
                fieldId: extensionField.Id,
                value: file ? getFileExtension(file.FileName) : null
              }));
            }
            if (documentChekInStatusField) {
              this.store$.dispatch(formsActions.updateFormFieldEnumValue({
                formId,
                fieldId: documentChekInStatusField.Id,
                value: DocumentCheckinType.CheckedIn
              }));
            }
            if (sizeField) {
              this.store$.dispatch(formsActions.updateFormField({
                formId,
                fieldId: sizeField.Id,
                value: file?.Size
              }));
            }
          }
        }
      )),
    {
      dispatch: false
    }
  );


  public clearFileFields$ = createEffect(() => this.actions$.pipe(
      ofType(formsActions.clearFileFields),
      exhaustMap(({ formId, field }) => {
        return zip([this.store$.select(selectForm(formId)), of({ formId, field })]);
      }),
      tap(([form, { formId, field }]) => {
          if (form) {
            const titleField =
              CollectionFormService.getField(form.data, (f) => {
                return f.ProtectedFieldType == ProtectedFieldType.Title && f.CollectionsID == field.CollectionsID;
              });
            const fileField =
              CollectionFormService.getField(form.data, (f) => {
                return f.ProtectedFieldType == ProtectedFieldType.File && f.CollectionsID == field.CollectionsID;
              });
            const extensionField =
              CollectionFormService.getField(form.data, (f) => {
                return f.ProtectedFieldType == ProtectedFieldType.Extension && f.CollectionsID == field.CollectionsID;
              });
            const sizeField =
              CollectionFormService.getField(form.data, (f) => {
                return f.ProtectedFieldType == ProtectedFieldType.Size && f.CollectionsID == field.CollectionsID;
              });
            const documentChekInStatusField =
              CollectionFormService.getField(form.data, (f) => {
                return f.ProtectedFieldType == ProtectedFieldType.DocumentCheckinStatus
                  && f.CollectionsID == field.CollectionsID;
              });

            if (fileField) {
              this.store$.dispatch(formsActions.updateFormFieldValue({ formId, fieldId: fileField.Id, value: null }));
            }
            if (titleField) {
              this.store$.dispatch(formsActions.updateFormFieldValue({ formId, fieldId: titleField.Id, value: null }));
            }
            if (extensionField) {
              this.store$.dispatch(formsActions.updateFormFieldValue({ formId, fieldId: extensionField.Id, value: null }));
            }
            if (documentChekInStatusField) {
              this.store$.dispatch(formsActions.updateFormFieldValue({
                formId,
                fieldId: documentChekInStatusField.Id,
                value: DocumentCheckinType.NoDocument
              }));
            }
            if (sizeField) {
              this.store$.dispatch(formsActions.updateFormFieldValue({ formId, fieldId: sizeField.Id, value: null }));
            }
          }
        }
      )),
    {
      dispatch: false
    }
  );
}