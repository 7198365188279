import { Component, DestroyRef, OnInit } from '@angular/core';
import { AuthService } from './core/services/auth/auth.service';
import { LoaderService } from './shared/services/loader/loader.service';
import { Router, RouterOutlet } from '@angular/router';
import { LoaderComponent } from './shared/components/ui/loader/loader.component';
import { AppSettingsService } from './core/services/app-settings/app-settings.service';
import { validateEnvironment } from './core/functions/validate-environment';
import { configureSentry } from './core/functions/configure-sentry';
import { environment } from '../environments/environment';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { userSettingsFeature } from './store/features/user-settings/user-settings-feature';
import { LocalizationService } from './core/services/localization/localization.service';
import { ViewStackService } from './shared/services/view-stack/view-stack.service';
import { BizzMineSessionStorageService } from './shared/services/localStorage/bizzmine-session-storage.service';
import { SignalRService } from './core/signalR/signal-r.service';
import { NetworkService } from './core/services/network/network.service';
import { PwaService } from './core/services/pwa/pwa.service';
import { Platform } from '@angular/cdk/platform';
import { IconComponent } from './shared/components/ui/icon/icon.component';
import { UserSessionsService } from './core/services/user-sessions/user-sessions.service';


@Component({
  selector: 'bizz-root',
  templateUrl: './bizz.component.html',
  styleUrls: ['./bizz.component.scss'],
  standalone: true,
  imports: [LoaderComponent, RouterOutlet, IconComponent]
})
export class BizzComponent implements OnInit {
  public title = 'BizzMineAngular';

  public showLoader: boolean;

  public constructor(
    private store$: Store,
    private localizationService: LocalizationService,
    private authService: AuthService,
    private destroyRef: DestroyRef,
    private loaderService: LoaderService,
    private appSettingsService: AppSettingsService,
    private sessionStorage: BizzMineSessionStorageService,
    private signalRService: SignalRService,
    private viewStackService: ViewStackService,
    private router: Router,
    public networkService: NetworkService,
    public pwaService:PwaService,
    public platform: Platform,
    public userSessionsService: UserSessionsService) {
    // pick the landing page -- only 1 time on start up - never again
    const foundRequestedUrl = this.sessionStorage.getItem('REQUESTED_URL');
    if (foundRequestedUrl == null) {
      const path = window.location.pathname?.replace('#', '');
      if (path != "/" && !path?.includes('auth')) {
        this.sessionStorage.set('REQUESTED_URL', path);
      }
    }

    if (environment.production && environment.deploymentType == 'onPremise') {
      this.appSettingsService.getAppSettingsFromJson().subscribe(settings => {
        this.appSettingsService.loadAppSettings(settings);
        this.configureApplication();
      });
    } else {
      this.configureApplication();
    }

    this.networkService.checkNetworkStatus();
  }



  public configureApplication(): void {
    validateEnvironment();
    this.signalRService.initApiHub();
    this.signalRService.initIdpHub();
    this.signalRService.initAiHub();
    this.userSessionsService.init();
    configureSentry();
    this.initializeOAuth();
  }

  public ngOnInit(): void {
    this.loaderService.getObservable().pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: value => {
        this.showLoader = value;
      }
    });

    this.store$.select(userSettingsFeature.selectUserSettingsState).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe({
      next: userSettings => {
        if (userSettings.UserLanguagesID !== 0 && userSettings.CompanyLanguagesID !== 0){
          this.localizationService.updateLocaleWithUserSettings(userSettings);
        }
      }
    });
  }

  private initializeOAuth(): void {
    this.authService.configureIdentityServer();
  }


}
