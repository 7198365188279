import { StoreCollectionForm } from './forms-state';
import { createEntityAdapter, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { formsActions } from './forms-actions';
import { CollectionFormService } from '../../../features/bizzmine/form/services/collection-form.service';
import { DocumentCheckinType } from '../../../../models/ts/document-checkin-type.model';
import { viewStackActions } from './view-stack-actions';
import { CollectionFormFieldGridLookupData } from '../../../../models/ts/collection-form-field-grid-lookup-data.model';


export const formsAdapter = createEntityAdapter<StoreCollectionForm>();

export const formsReducer = createReducer(
  formsAdapter.getInitialState(),
  /**
   * Add form to store
   */
  on(formsActions.formFetched, formsActions.formFetchedWithFiles, formsActions.formFetchedWithTemplate, formsActions.formFetchedByWidgetIdWithCategory, (state, { form }) => {
    return formsAdapter.addOne(form, state);
  }),
  /**
   * Update entire form
   */
  on(formsActions.updateForm, formsActions.updateScriptingForm, (state, { update }) => {
    return formsAdapter.updateOne(update, state);
  }),
  /**
   * Update integrity state of a form
   */
  on(formsActions.updateIntegrity, (state, { formId, integrity }) => {
    const stateForm = state.entities[formId];
    if (stateForm) {
      return formsAdapter.updateOne(
        CollectionFormService.toStoreUpdateIntegrity(
          structuredClone(stateForm.data),
          formId,
          integrity
        ),
        state
      );
    } else
      return state;
  }),
  /**
   * Update scheduler data of a (scheduler) form
   */
  on(formsActions.updateScheduler, (state, { formId, scheduler }) => {
    const stateForm = state.entities[formId];
    if (stateForm) {
      return formsAdapter.updateOne(
        CollectionFormService.toStoreUpdateScheduler(
          structuredClone(stateForm.data),
          formId,
          scheduler
        ),
        state
      );
    } else
      return state;
  }),
  /**
   * Show Loading
   */
  on(
    formsActions.createLinkedFormInstance,
    formsActions.saveLinkedFormInstance,
    formsActions.deleteSelfLock,
    formsActions.editReadOnlyForm,
    formsActions.deleteLock,
    formsActions.addEmptyRowToGrid,
    formsActions.addRowsToGrid,
    formsActions.lookupChanged,
    formsActions.refreshForm,
    formsActions.getGridLinkedForm,
    formsActions.getExternalGridLinkedForm,
    formsActions.getGridLinkedFormWithFile,
    formsActions.getGridLinkedFormWithTemplate,
    formsActions.getGridLinkedInstance,
    (state, { formId }) => {
      const stateForm = state.entities[formId];
      if (stateForm && !stateForm.loading) {
        return formsAdapter.updateOne(
          CollectionFormService.setLoading(stateForm, true),
          state
        );
      } else
        return state;
    }),
  /**
   * Clear Loading
   */
  on(
    formsActions.selfLockDeleted,
    formsActions.lockDeleted,
    formsActions.rowsAddedToGrid,
    formsActions.getEmptyRowForGridFailed,
    formsActions.createLinkedFormInstanceFailed,
    formsActions.saveLinkedFormInstanceFailed,
    formsActions.editReadOnlyFormFetched,
    formsActions.gridLookupFailed,
    formsActions.lookupFailed,
    formsActions.formGroupReset,
    formsActions.lookupFetched,
    (state, { formId }) => {
      const stateForm = state.entities[formId];
      if (stateForm && stateForm.loading) {
        return formsAdapter.updateOne(
          CollectionFormService.setLoading(stateForm, false),
          state
        );
      } else
        return state;
    }),
  /**
   * Clear Parent Loading on close
   */
  on(formsActions.formClosed, (state, { formId }) => {
    const stateForm = state.entities[formId];
    if (stateForm?.options?.parentFormId !== undefined) {
      const parentStateForm = state.entities[stateForm.options.parentFormId];
      if (parentStateForm !== undefined && parentStateForm.loading)
        return formsAdapter.updateOne(
          CollectionFormService.setLoading(parentStateForm, false),
          state
        );
    }
    return state;
  }),
  /**
   * Clear Loading & Parent Loading on linked save
   */
  on(formsActions.createLinkedFormInstanceSucceeded, formsActions.createGridLinkedFormInstanceSucceeded, (state, {
    formId,
    parentFormId
  }) => {
    const stateForm = state.entities[formId];
    const parentStateForm = state.entities[parentFormId];
    const updates: Array<Update<StoreCollectionForm>> = [];
    if (stateForm)
      updates.push(CollectionFormService.setLoading(stateForm, false));
    if (parentStateForm)
      updates.push(CollectionFormService.setLoading(parentStateForm, false));
    if (updates.length > 0)
      return formsAdapter.updateMany(
        updates,
        state
      ); else return state;
  }),
  /**
   * Remove from store
   */
  on(viewStackActions.removeFormFromViewStack, (state, { formId }) => {
    return formsAdapter.removeOne(formId, state);
  }),
  /**
   * Update Org Chart
   */
  on(formsActions.updateOrgChartFieldValue, (state, { formId, fieldId, value }) => {
    const stateForm = state.entities[formId];
    if (stateForm)
      return formsAdapter.updateOne(
        CollectionFormService.toStoreUpdate(
          CollectionFormService.setOrgChartValue(
            structuredClone(stateForm.data),
            fieldId,
            value
          ),
          formId
        ),
        state
      );
    else return state;
  }),
  /**
   * Update Enum
   */
  on(formsActions.updateFormFieldEnumValue, (state, { formId, fieldId, value }) => {
    const stateForm = state.entities[formId];
    if (stateForm)
      return formsAdapter.updateOne(
        CollectionFormService.toStoreUpdate(
          CollectionFormService.setFieldEnumValue(
            structuredClone(stateForm.data),
            fieldId,
            value
          ),
          formId
        ),
        state
      );
    else return state;
  }),
  /**
   * Update CheckOut
   */
  on(formsActions.updateFormCheckedOutBy, (state, { formId, checkOutByUserId }) => {
      const stateForm = state.entities[formId];
      if (stateForm) {
        const current = structuredClone(stateForm.data);
        current.DocumentProperties.CheckedOutByID = checkOutByUserId;
        current.DocumentProperties.DocumentCheckinStatus = DocumentCheckinType.CheckedOut;
        return formsAdapter.updateOne(
          CollectionFormService.toStoreUpdate(
            current,
            formId
          ),
          state
        );
      } else return state;
    }
  ),
  /**
   * Update CheckOut
   */
  on(formsActions.updateFormCheckedIn, (state, { formId, file }) => {
      const stateForm = state.entities[formId];
      if (stateForm) {
        const current = structuredClone(stateForm.data);
        current.DocumentProperties.CheckedOutByID = 0;
        current.DocumentProperties.DocumentCheckinStatus = DocumentCheckinType.Uploaded;
        current.DocumentProperties.File = file.id;
        current.DocumentProperties.Extension = file.extension;
        current.DocumentProperties.Title = file.title;
        current.DocumentProperties.Size = file.size;
        return formsAdapter.updateOne(
          CollectionFormService.toStoreUpdate(
            current,
            formId
          ),
          state
        );
      } else return state;
    }
  ),
  /**
   * Update Field Value
   */
  on(formsActions.updateFormFieldValue, (state, { formId, fieldId, value }) => {
    const stateForm = state.entities[formId];
    if (stateForm)
      return formsAdapter.updateOne(
        CollectionFormService.toStoreUpdate(
          CollectionFormService.setFieldValue(
            structuredClone(stateForm.data),
            fieldId,
            value
          ),
          formId
        ),
        state
      );
    else return state;
  }),
  /**
   * Update Grid field value
   */
  on(formsActions.updateGridFormFieldValue, (state, { formId, gridFieldId, recordId, recordFieldId, value }) => {
    const stateForm = state.entities[formId];
    if (stateForm)
      return formsAdapter.updateOne(
        CollectionFormService.toStoreUpdate(
          CollectionFormService.setGridFieldValue(
            structuredClone(stateForm.data),
            gridFieldId,
            recordId,
            recordFieldId,
            value
          ),
          formId
        ),
        state
      );
    else return state;
  }),
  /**
   * Update Org Chart in Grid
   */
  on(formsActions.updateGridOrgChartFieldValue, (state, { formId, gridFieldId, recordId, recordFieldId, value }) => {
    const stateForm = state.entities[formId];
    if (stateForm)
      return formsAdapter.updateOne(
        CollectionFormService.toStoreUpdate(
          CollectionFormService.setGridFieldOrgChartValue(
            structuredClone(stateForm.data),
            gridFieldId,
            recordId,
            recordFieldId,
            value
          ),
          formId
        ),
        state
      );
    else return state;
  }),
  /**
   * Update result of Formula
   */
  on(formsActions.updateFormulaOutputField, (state, { formId, collectionFieldId, value }) => {
    const stateForm = state.entities[formId];
    if (stateForm)
      return formsAdapter.updateOne(
        CollectionFormService.toStoreUpdate(
          CollectionFormService.setFieldValuePredicate(
            structuredClone(stateForm.data),
            field => field.CollectionFieldsID == collectionFieldId,
            value
          ),
          formId
        ),
        state
      );
    else return state;
  }),
  /**
   * update result of formula (in a grid)
   */
  on(formsActions.updateFormulaOutputGridField, (state, {
    formId,
    gridFieldId,
    recordId,
    collectionFieldId,
    value
  }) => {
    const stateForm = state.entities[formId];
    if (stateForm)
      return formsAdapter.updateOne(
        CollectionFormService.toStoreUpdate(
          CollectionFormService.setGridFieldValue(
            structuredClone(stateForm.data),
            gridFieldId,
            recordId,
            collectionFieldId,
            value
          ),
          formId
        ),
        state
      );
    else return state;
  }),
  /**
   * Update IsReadOnly property of a field (accordion to priority)
   */
  on(formsActions.setFieldReadOnly, (state, { formId, formFieldId, isReadOnly, readOnlyPriority }) => {
    const stateForm = state.entities[formId];
    // TODO: add check if readonly level is lower than existing field
    if (stateForm)
      return formsAdapter.updateOne(
        CollectionFormService.toStoreUpdate(
          CollectionFormService.setFieldReadOnly(
            structuredClone(stateForm.data),
            formFieldId,
            isReadOnly,
            readOnlyPriority
          ),
          formId
        ),
        state
      );
    else return state;
  }),
  /**
   * Update single VDS
   */
  on(formsActions.updateFormViewDataSource, (state, { formId, viewDataSource }) => {
    const stateForm = state.entities[formId];
    if (stateForm) {
      const updatedForm = structuredClone(stateForm.data);
      return formsAdapter.updateOne(
        CollectionFormService.toStoreUpdate(CollectionFormService.updateViewDataSource(updatedForm, viewDataSource), formId),
        state
      );
    } else return state;
  }),
  /**
   * Update multiple VDS's
   */
  on(formsActions.updateFormViewDataSources, (state, { formId, viewDataSources }) => {
    const stateForm = state.entities[formId];
    if (stateForm) {
      const updatedForm = structuredClone(stateForm.data);
      return formsAdapter.updateOne(
        CollectionFormService.toStoreUpdate(CollectionFormService.updateViewDataSources(updatedForm, viewDataSources), formId),
        state
      );
    } else return state;
  }),
  /**
   * Add valdiationErrors to form
   */
  on(formsActions.formValidationFailed, (state, { formId, validationErrors }) => {
    const stateForm = state.entities[formId];
    if (stateForm) {
      const updatedForm = structuredClone(stateForm);
      updatedForm.validationResult = validationErrors;
      return formsAdapter.updateOne({ id: formId, changes: updatedForm }, state);
    } else return state;
  }),
  /**
   * Clear reset boolean marker from form
   */
  on(formsActions.formGroupReset, (state, { formId }) => {
    const stateForm = state.entities[formId];
    if (stateForm) {
      const updatedState = structuredClone(stateForm);
      updatedState.reset = false;
      return formsAdapter.updateOne(
        { id: formId, changes: updatedState },
        state
      );
    } else
      return state;
  }),
  /**
   * Add records to a grid
   */
  on(formsActions.addRowsToGrid, (state, { formId, gridFieldId, records }) => {
    const stateForm = state.entities[formId];
    if (stateForm) {
      const updatedState = structuredClone(stateForm);
      return formsAdapter.updateOne(
        CollectionFormService.toStoreUpdate(
          CollectionFormService.addRecords(updatedState.data, gridFieldId, records),
          formId
        ),
        state
      );
    } else
      return state;
  }),
  /**
   * Remove records from a grid
   */
  on(formsActions.removeRecordFromGrid, (state, { formId, gridFieldId, recordId }) => {
    const stateForm = state.entities[formId];
    if (stateForm) {
      const updatedState = structuredClone(stateForm);
      return formsAdapter.updateOne(
        CollectionFormService.toStoreUpdate(
          CollectionFormService.removeRecord(updatedState.data, gridFieldId, recordId),
          formId
        ),
        state
      );
    } else
      return state;
  }),
  /**
   * Clear all records from a grid
   */
  on(formsActions.clearGrid, (state, { formId, gridFieldId }) => {
    const stateForm = state.entities[formId];
    if (stateForm) {
      const updatedState = structuredClone(stateForm);
      return formsAdapter.updateOne(
        CollectionFormService.toStoreUpdate(
          CollectionFormService.clearGrid(updatedState.data, gridFieldId),
          formId
        ),
        state
      );
    } else
      return state;
  }),
  /**
   * Update a grid record with lookup data
   */
  on(formsActions.updateGridRecordWithLookup, (state, { formId, gridFieldId, recordId, lookupData, lookupItem }) => {
    const stateForm = state.entities[formId];
    if (stateForm) {
      let form = CollectionFormService.updateRecordWithLookup(structuredClone(stateForm.data), gridFieldId, recordId, lookupData, lookupItem);
      if(lookupData.ViewDataSources.length > 0) {
        form = CollectionFormService.updateViewDataSources(form, lookupData.ViewDataSources);
      }
      return formsAdapter.updateOne(
        CollectionFormService.toStoreUpdate(
          form,
          formId
        ),
        state
      );
    }
    return state;
  }),
  /**
   * Update the EditMode of a grid record
   */
  on(formsActions.updateGridRecordEditMode, (state, { formId, gridFieldId, recordId, editMode }) => {
    const stateForm = state.entities[formId];
    if (stateForm) {
      return formsAdapter.updateOne(
        CollectionFormService.toStoreUpdate(
          CollectionFormService.setRecordEditMode(structuredClone(stateForm.data), gridFieldId, recordId, editMode),
          formId
        ),
        state
      );
    }
    return state;
  }),
  on(formsActions.gridLookupCleared, (state, {  formId, gridFieldId, recordId }) => {
    const stateForm = state.entities[formId];
    if (stateForm) {
      return formsAdapter.updateOne(
        CollectionFormService.toStoreUpdate(
          CollectionFormService.clearRecordData(structuredClone(stateForm.data), gridFieldId, recordId),
          formId
        ),
        state
      );
    }
    return state;
  }),
  on(formsActions.updateGridRecord, (state, {  formId, viewDataSourceId, lookupData, recordId, newRecordId }) => {
    const stateForm = state.entities[formId];
    if (stateForm) {
      return formsAdapter.updateOne(
        CollectionFormService.toStoreUpdate(
          CollectionFormService.updateGridRecord(structuredClone(stateForm.data), viewDataSourceId, lookupData, recordId, newRecordId),
          formId
        ),
        state
      );
    }
    return state;
  }),
  on(formsActions.gridRecordDataLookupUpdated, (state, {
    formId,
    recordId,
    lookupFieldId,
    lookupData
  }) => {
    const stateForm = state.entities[formId];
    if (stateForm) {
      return formsAdapter.updateOne(
        CollectionFormService.toStoreUpdate(
          CollectionFormService.updateRecordData(structuredClone(stateForm.data), lookupFieldId, recordId,lookupData),
          formId
        ),
        state
      );
    }
    return state;
  }),
  /**
   * Update the instance ID's of a grid record
   */
  on(formsActions.updateGridRecordInstance, (state, { formId, gridFieldId, recordId, instanceId }) => {
    const stateForm = state.entities[formId];
    if (stateForm) {
      return formsAdapter.updateOne(
        CollectionFormService.toStoreUpdate(
          CollectionFormService.setRecordInstanceId(structuredClone(stateForm.data), gridFieldId, recordId, instanceId),
          formId
        ),
        state
      );
    }
    return state;
  })
);
