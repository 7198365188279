import { Injectable } from '@angular/core';
import { CollectionFormField, Record } from '../../../../models/ts/collection-form-field.model';
import { ViewDataSource } from '../../../../models/ts/view-data-source.model';
import { TranslationService } from '../../../core/services/translation/translation.service';
import { ViewDataSourcesInstance } from '../../../../models/ts/view-data-sources-instance.model';
import { LinkedCollectionType } from '../../../../models/ts/linked-collection-type.model';
import { UpdateDeleteState } from '../../../../models/ts/update-delete-state.model';
import { LinkedCollectionStorageType } from '../../../../models/ts/linked-collection-storage-type.model';
import { ProtectedCollectionType } from '../../../../models/ts/protected-collection-type.model';
import { FormFieldType } from '../../../../models/ts/form-field-type.model';
import { ProtectedFieldType } from '../../../../models/ts/protected-field-type.model';
import { CollectionForm } from '../../../../models/ts/collection-form.model';
import { AuthService } from '../../../core/services/auth/auth.service';
import { Claims } from '../../../core/constants/claims';
import { UserType } from '../../../../models/ts/user-type.model';
import { CollectionFormLookupApiService } from '../../../api/bizzmine/collection-form-lookup/collection-form-lookup-api.service';
import { CollectionListApiService } from '../../../api/bizzmine/collection-list/collection-list-api.service';
import { combineLatestWith, map, Observable, take, zip } from 'rxjs';
import { MustBeUniqueLinkedInstanceType } from 'src/models/ts/must-be-unique-linked-instance-type.model';
import { CollectionFormService } from '../../../features/bizzmine/form/services/collection-form.service';
import { GridColumnDisplayPropertiesDto } from '../../../../models/ts/grid-column-display-properties-dto.model';
import { CollectionFormExternalAccessDto } from '../../../../models/ts/collection-form-external-access-dto';
import { FormType } from '../../../../models/ts/form-type.model';
import { CollectionListSummaryItem } from '../../../../models/ts/collection-list-summary-item.model';
import { Store } from '@ngrx/store';
import { selectForm, selectFormField } from '../../../store/features/forms/forms-selectors';
import { formsActions } from '../../../store/features/forms/forms-actions';
import { CollectionFormFieldGridLookupData } from '../../../../models/ts/collection-form-field-grid-lookup-data.model';

export interface CachedRecord {
  ViewDataSourcesID: number;
  Record: Record;
}


@Injectable({
  providedIn: 'root'
})
export class LookupService {

  private gridRecordCache: Array<CachedRecord> = [];

  public constructor(
    private translationService: TranslationService,
    private collectionListApiService: CollectionListApiService,
    private collectionFormLookupApiService: CollectionFormLookupApiService,
    private authService: AuthService
  ) {
  }

  /**
   * Returns the next (negative) ID for new records.
   * @param {Record[]} records
   * @return {number}
   */
  public static getNextNewRecordId(records: Record[]): number {
    let lowestId = LookupService.getLowestRecordId(records);
    return lowestId > 0 ? -1 : --lowestId;
  }

  /**
   * Returns the lowest ID of provided records. If records array is empty, returns Infinity.
   * @param {Record[]} records
   * @return {number}
   */
  public static getLowestRecordId(records: Record[]): number {
    return Math.min(...records.map(record => record.CrossLinkedInstancesID));
  }

  /**
   * Returns the lowest ID of provided instances. If records array is empty, returns Infinity.
   * @return {number}
   * @param instances
   */
  public static getLowestCrossLinkId(instances: ViewDataSourcesInstance[]): number {
    let currentLowest = Math.min(...instances.map(instance => instance.CrossLinkedInstancesID));
    return currentLowest > 0 || currentLowest == Infinity ? -1 : --currentLowest;
  }

  public static addRecordToGridField(form: CollectionForm | undefined, formFieldId: number, newRecord: Record): CollectionForm {
    if (form) {
      const formField = CollectionFormService.getField(form, f => f.Id == formFieldId);

      if (formField && formField.Records) {
        // enable disable logic on new fields
        formField.Records.push(newRecord);
        LookupService.enableDisableFields(newRecord.Fields, form.ViewDataSources);
        return form;
      } else
        console.error(`Could not find formField ${formFieldId} while attempted to add empty record row`);
      return form;
    } else {
      throw new Error(`Could not add record to grid, form is undefined`);
    }
  }

  public static enableDisableFields(fields: CollectionFormField[], viewDataSources: ViewDataSource[], rowDataDesignCrossId = 0, byCrossLinkCollectionsId = 0): void {
    //const fields = CollectionFormService.extractFieldsFromForm(form);
    const parentViewDataSources = viewDataSources.filter(v => v.ParentDataSourcesID == 0);
    // get all the non snapshots
    const allVds = viewDataSources.filter(v => !v.IsSnapshotRelation);

    parentViewDataSources.forEach(vds => {
      // enable disable
      // only skip this when it's a reversed relation (always disabled)
      const mustBeDisabled = vds.CrossLinkCollectionsID == byCrossLinkCollectionsId;
      if (vds.CrossLinkCollectionsID == byCrossLinkCollectionsId)
        LookupService.disableReversedAllFields(allVds, vds, fields, rowDataDesignCrossId);
      else {
        const disableOtherFields = LookupService.enableDisableAddViewRelation(vds, vds, fields, mustBeDisabled, rowDataDesignCrossId);
        LookupService.iterateChildViewDataSources(allVds, vds, fields, disableOtherFields, rowDataDesignCrossId);
      }
    });
  }

  public static buildRecordFromFields(fields: CollectionFormField[], formField: CollectionFormField): Record {
    if (formField.GridOptions) {
      let columns: Map<number, GridColumnDisplayPropertiesDto> = new Map();
      formField.GridOptions.columns.forEach(column => {
        columns.set(Number.parseInt(column.field.split('F_')[0]), column);
      });

      // Copy column properties to fields
      fields.forEach(field => {
        const column = columns.get(field.CollectionFieldsID);
        if (column) {
          field.Width = column.width;
          field.IsReadOnly = false;
        }
      });

      // Create new record
      const newRecord: Record = {
        Fields: fields,
        CrossLinkedInstancesID: 0,
        RowDataDesignCrossID: 0,
        State: UpdateDeleteState.Update,
        EditMode: false
        //TODO: RV Handle ext form permissions on new rows
        //HideButtonEditInExtForms: true
      };
      return newRecord;
    } else throw new Error(`Attempted to cast Dummy Instance to Record but related FormField ${formField.Id} is missing GridOptions`);
  }

  private static enableDisableAddViewRelation(parentVds: ViewDataSource, vds: ViewDataSource, fields: CollectionFormField[], isDeeperLevel: boolean, rowDataDesignCrossId?: number): boolean {
    const isCurrentActive = LookupService.isVdsActive(parentVds, rowDataDesignCrossId);
    //// if the lookup is active, the fields must not be disabled
    const disableLookup = isDeeperLevel && !isCurrentActive;
    //// enable/disable all fields
    const disableOtherFields = (!isCurrentActive && vds.LinkedCollectionStorageType == LinkedCollectionStorageType.Historical && vds.MustBeInList) || (vds.LinkedCollectionStorageType == LinkedCollectionStorageType.Relational); // if the parent is active, the fields must not be disabled
    //// enable/disable all fields
    LookupService.disableFields(fields, vds, disableLookup, disableOtherFields);

    return disableOtherFields;
  }

  private static isVdsActive(vds: ViewDataSource, rowDataDesignCrossIc?: number): boolean {
    let checkInstance: ViewDataSourcesInstance | undefined;
    switch (vds.SingleOrMany) {
      case LinkedCollectionType.SingleRecord:
        checkInstance = vds.Instances[0];
        break;
      case LinkedCollectionType.GridRecord:
        checkInstance = vds.Instances.find(i => i.CrossLinkedInstancesID == rowDataDesignCrossIc);
        break;
    }

    return checkInstance != undefined && checkInstance.ChildInstancesID > 0 && checkInstance.State != UpdateDeleteState.Delete;
  }

  // TODO: GL replace logic with generic service handling enable disable (also to be used in formulaservice)
  private static disableFields(fields: CollectionFormField[], vds: ViewDataSource, disableLookupFields: boolean, disableOtherFields: boolean): void {
    const filterFields = fields.filter(field => field.ViewDataSourcesID == vds.ViewDataSourcesID);

    if (!disableOtherFields) {
      // TODO: GL figure out what this does
      //$rootScope.$broadcast("formField:enableDisable");
    } else {
      filterFields.forEach((field) => {
        if (field.IsLookupField || field.FormFieldType == FormFieldType.List) {
          field.IsReadOnly = disableLookupFields;
        } else if (field.IsCrossLinkedField) {
          field.IsReadOnly = false;
        } else {
          field.IsReadOnly = true;
          if (vds.ProtectedCollectionType == ProtectedCollectionType.LinkTrainingDocument && field.ProtectedFieldType == ProtectedFieldType.File) {
            field.IsReadOnly = true;
          }
        }
      });
    }
  }

  private static disableReversedAllFields(allVds: ViewDataSource[], vds: ViewDataSource, fields: CollectionFormField[], rowDataDesignCrossId?: number): void {
    const childViewDataSources = allVds.filter(v => v.ParentDataSourcesID == vds.ViewDataSourcesID && !v.IsSnapshotRelation);

    const filterFields = fields.filter(field => field.ViewDataSourcesID == vds.ViewDataSourcesID);
    filterFields.forEach(field => {
      //field.Placeholder = this.translationService.translate('WillBeFilledInAutomatically');
      field.IsReadOnly = true;
    });

    childViewDataSources.forEach(childVds => {
      this.disableReversedAllFields(allVds, childVds, fields, rowDataDesignCrossId);
    });
  }

  private static iterateChildViewDataSources(allVds: ViewDataSource[], vds: ViewDataSource, fields: CollectionFormField[], disableOtherFields: boolean, rowDataDesignCrossId?: number): void {
    const childViewDataSources = allVds.filter(v => v.ParentDataSourcesID == vds.ViewDataSourcesID && !v.IsSnapshotRelation);
    childViewDataSources.forEach(childvds => {
      if (childvds.IsRecursiveDetailRelation) {
        // do the same as the current
        LookupService.disableFields(fields, childvds, disableOtherFields, disableOtherFields);
      } else {
        LookupService.enableDisableAddViewRelation(vds, childvds, fields, true, rowDataDesignCrossId);
      }
      LookupService.iterateChildViewDataSources(allVds, childvds, fields, disableOtherFields, rowDataDesignCrossId);
    });
  }

  public getEmptyGridRecord(collectionFormId: number, field: CollectionFormField | undefined): Observable<Record> {
    // obsolete (never hit) -> reworked to normal service as caching was broken -> Check Guillaume
   return new Observable<Record>(observer => {
      if (field) {
        const cachedRecord = this.gridRecordCache.find(i => i.ViewDataSourcesID == field.ViewDataSourcesID);
        if (cachedRecord) {
          observer.next(cachedRecord.Record);
          observer.complete();
        } else {
          this.collectionFormLookupApiService.getEmptyRow(collectionFormId, field.ViewDataSourcesID).pipe(take(1)).subscribe({
            next: (fields: CollectionFormField[]) => {
              let record = LookupService.buildRecordFromFields(fields, field);
              this.gridRecordCache.push({ ViewDataSourcesID: field.ViewDataSourcesID, Record: record });
              observer.next(structuredClone(record));
              observer.complete();
            },
            error: (err) => {
              observer.error(err);
            }
          });
        }
      } else {
        observer.error(new Error('Unable to get empty row, missing store form/field'));
      }
    });
  }

  public openLookupSearchModal(form: CollectionForm, field: CollectionFormField): void {
    const lookupData = this.getHigherLookupData(form.ViewDataSources, field.ViewDataSourcesID);

    if (lookupData) {

      if (lookupData.linkedLevel > 1) {
        this.openDeeperLevelLookupSearchModal(form.ViewDataSources, field.ViewDataSourcesID);
      } else {
        this.openFirstLevelLookupSearchModal(lookupData);
      }
    }
  }

  public openFirstLevelLookupSearchModal(lookupData: LookupData): void {
    // TODO: add ?formtype? to if statement
    const userType = this.authService.getAccessTokenClaim(Claims.UserType);
    if (userType == UserType.ExternalUser || userType == UserType.AnonymousUser) {

    } else {
      this.collectionListApiService.getListIdByViewsId(lookupData.viewsId).pipe(take(1)).subscribe({
        next: (listId) => {

        }
      });
    }

    // var vds = _.findWhere($scope.viewDataSources, { ViewDataSourcesID: $scope.field.ViewDataSourcesID });
    //
    // var modalInstance = $uibModal.open({
    //   templateUrl: "/page/LookupField-Modal",
    //   controller: 'LookupSearchModalFirstController',
    //   size: "lg",
    //   resolve: {
    //     listId: ["$http", "$q", function ($http, $q) {
    //       if ($scope.userType == Enums.UserType.ExternalUser && $scope.$parent.base.FormType == Enums.FormType.ExternalForm) {
    //         return $scope.externalAccess.ChildListID;
    //       } else {
    //         var q = $q.defer();
    //         $http.get('/api/view/' + lookupData.viewsID + '/listid').then(function (result) {
    //           q.resolve(result.data);
    //         });
    //         return q.promise;
    //       }
    //     }],
    //     parentFormType: function () { return $scope.$parent.base.FormType },
    //     parentFormsId: function() { return $scope.formsId },
    //     singleOrMany: function () { return Enums.LinkedCollectionType.SingleRecord },
    //     isOrgChartItem: function () { return lookupData.IsOrgchartCollection },
    //     canCreate: function () {
    //       if ($scope.userType == Enums.UserType.ExternalUser && $scope.$parent.base.FormType == Enums.FormType.ExternalForm) {
    //         return $scope.field.CanCreate && $scope.externalAccess.AllowCreateRecord;
    //       } else {
    //         return $scope.field.CanCreate;
    //       }
    //     },
    //     crosslinkData: function () { return { ViewDataSourcesID: vds.ViewDataSourcesID, CrossLinkCollectionsID: vds.CrossLinkCollectionsID, MustBeUniqueLinkedInstanceType: vds.MustBeUniqueLinkedInstanceType } },
    //     viewsId: function () { return vds.ViewsID },
    //     canViewHiddenDocuments: function () { return null },
    //   }
    // });
    // modalInstance.result.then(function (result) {
    //   if (result.createNew === true) {
    //     // button "create new" pressed in the modal window
    //     $scope.addNewInstance(field);
    //   } else if (result.length > 0) {
    //     //process a selected result
    //     var lookupFieldData = LookupService.createLookupObject(result[0].ID, result[0].VersionsID, $scope.field.ViewDataSourcesID, $scope.linkedCollectionType, result[0].OriginalChildInstancesID);
    //
    //     if (result[0].LinkedToParentVersions != undefined && result[0].LinkedToParentVersions.length > 0) {
    //       var promise = AlreadyLinkedInstanceService.openModalForAlreadyLinked(result[0]);
    //       promise.then(function (result) {
    //         if (result.status) {
    //           LookupService.clearViewDataSources($scope.viewDataSources, $scope.parentFormField, $scope.field.ViewDataSourcesID, $scope.linkedCollectionType);
    //           LookupService.addInstance(lookupFieldData, $scope.formsId, $scope.viewDataSources, $scope.parentFormField, $scope.stackCrosscollectionsid, false);
    //         }
    //       })
    //     }
    //     else {
    //       LookupService.clearViewDataSources($scope.viewDataSources, $scope.parentFormField, $scope.field.ViewDataSourcesID, $scope.linkedCollectionType);
    //       LookupService.addInstance(lookupFieldData, $scope.formsId, $scope.viewDataSources, $scope.parentFormField, $scope.stackCrosscollectionsid, false);
    //     }
    //
    //     forceUpdateFieldIfNecessary();
    //   }
    // });
  }

  public openDeeperLevelLookupSearchModal(viewDataSources: Array<ViewDataSource>, viewDataSourceId: number): void {

    // Prep modal data
    this.getHigherLookupData(viewDataSources, viewDataSourceId);
    // var modalInstance = $uibModal.open({
    //   templateUrl: "/page/LookupField-ModalSecondLevel",
    //   controller: "LookupSearchModalDeeperController",
    //   size: "lg",
    //   resolve: {
    //     lookupData: function () {
    //       var lookupData = LookupService.GetHigherLookupData($scope.viewDataSources, $scope.field.ViewDataSourcesID);
    //       if ($scope.userType == Enums.UserType.ExternalUser && $scope.base.FormType == Enums.FormType.ExternalForm) {
    //         lookupData.listID = $scope.externalAccess.ChildListID;
    //       } else {
    //         lookupData.listID = 0;
    //       }
    //       return lookupData;
    //     },
    //     sourceCollectionsID: function () {
    //       return sourceCollectionsID;
    //     },
    //     singleOrMany: function () { return Enums.LinkedCollectionType.SingleRecord },
    //     formsID: function () { return $scope.formsId }
    //   }
    // });
    //
    // modalInstance.result.then(function (result) {
    //   if (result.createNew === true) {
    //     // button "create new" pressed in the modal window
    //     $scope.addNewInstance();
    //   } else {
    //     //process a selected result
    //     var lookupFieldData = LookupService.createLookupObject(result[0].ID, result[0].VersionsID, $scope.field.ViewDataSourcesID, $scope.linkedCollectionType, result[0].OriginalChildInstancesID, result[0].CrossLinkInstancesID);
    //     var lookupRelationData = LookupService.GetHigherLookupData($scope.viewDataSources, $scope.field.ViewDataSourcesID);
    //     LookupService.addInstanceViaSecondLevelLookup(lookupFieldData, $scope.formsId, $scope.viewDataSources, $scope.parentFormField, lookupRelationData.childCollectionsID, false);
    //   }
    // });
  }

  public getHigherLookupData(viewDataSources: Array<ViewDataSource>, fieldDataSource: number, externalAccess?: CollectionFormExternalAccessDto): LookupData | undefined {
    let linkedLevel = 1;
    const lookupData: Partial<LookupData> = {};

    const currentDataSource = viewDataSources.find(v => v.ViewDataSourcesID === fieldDataSource);
    // check if datasources exists
    if (currentDataSource) {
      const secondLevelDataSource = viewDataSources.find(v => v.ViewDataSourcesID === currentDataSource.ParentDataSourcesID);
      if (secondLevelDataSource) {
        // we'rea secondlevel deep
        linkedLevel = 2;

        // check if we're thirdlevel or deeper
        const thirdLevelDataSource = viewDataSources.find(v => v.ViewDataSourcesID === secondLevelDataSource.ParentDataSourcesID);

        // check if the secondlevel datasource has an instance
        if (secondLevelDataSource.Instances[0]) {
          lookupData.childCollectionsId = currentDataSource.ChildCollectionsID;
          lookupData.childOriginalCollectionsId = secondLevelDataSource.ChildOriginalCollectionsID;
          lookupData.viewsId = secondLevelDataSource.ViewsID;
          if (thirdLevelDataSource) {
            linkedLevel = 3;
            lookupData.instancesId = secondLevelDataSource.Instances[0].OriginalChildInstancesID;
          } else {
            if (secondLevelDataSource.Instances[0].OriginalChildInstancesID != undefined && secondLevelDataSource.Instances[0].OriginalChildInstancesID != 0)
              lookupData.instancesId = secondLevelDataSource.Instances[0].OriginalChildInstancesID;
            else
              lookupData.instancesId = secondLevelDataSource.Instances[0].ChildInstancesID;
          }
        }
      }
      lookupData.viewsId = currentDataSource.ViewsID;
      lookupData.isOrgchartCollection = currentDataSource.IsOrgchartCollection;
      lookupData.linkedLevel = linkedLevel;
      lookupData.IsLookupEnabled = !currentDataSource.IsLookupDisabled;
      lookupData.dataDesignViewDataSourcesId = currentDataSource.DataDesignViewDataSourcesID;
      lookupData.mustBeUniqueLinkedInstanceType = currentDataSource.MustBeUniqueLinkedInstanceType;
      if (externalAccess != null) {
        lookupData.viewsId = externalAccess.ViewsID;
        lookupData.childCollectionsId = externalAccess.ChildCollectionsID;
      }
      lookupData.viewDataSourcesID = lookupData.dataDesignViewDataSourcesId;
      lookupData.crossLinkCollectionsID = lookupData.childCollectionsId;
      /*if ($scope.userType == UserType.ExternalUser && $scope.base.FormType == FormType.ExternalForm) {
        lookupData.listID = externalAccess.ChildListID;
      } else {
          lookupData.listID = 0;
      }*/
      return lookupData as LookupData;
    }
    return undefined;
  }
}

export class Lookup {

  public constructor(public InstancesID: number,
                     public VersionsID: number,
                     public ViewDataSourcesID: number,
                     public OriginalChildInstancesID: number,
                     public Type: unknown,
                     public CrosslinkInstancesID: number) {
  }
}

export interface LookupData {
  childCollectionsId: number;
  childOriginalCollectionsId: number;
  viewsId: number;
  instancesId: number;
  listId: number;
  isOrgchartCollection: boolean;
  linkedLevel: number;
  IsLookupEnabled: boolean;
  dataDesignViewDataSourcesId: number;
  parentFormType?: FormType;
  parentFormsId: number;
  viewDataSourcesID: number;
  crossLinkCollectionsID: number;
  mustBeUniqueLinkedInstanceType: MustBeUniqueLinkedInstanceType;
}

export interface LookupFieldData {
  instancesID: number;
  versionsID: number;
  viewDataSourcesID: number;
  originalChildInstancesID: number;
  type: LinkedCollectionType;
  crossLinkInstancesID: number;
}