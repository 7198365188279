<div class="h-full w-full max-w-full">
    <div class="absolute bottom-10 left-10 flex flex-col">
        <bizz-brand-logo/>
        <p class="truncate cursor-pointer font-sans text-sm font-medium text-blue-500 no-underline hover:text-blue-600 text-center">
            <a href="https://www.bizzmine.com">Learn more</a></p>
    </div>
    <div class="h-full w-full">
        <div class="flex h-full w-full flex-col items-center justify-center gap-16">
            <div class="text-center">

                <h1><strong>{{ title() | translate }}</strong></h1>
                @if(info(); as inf){
                    <p>{{ inf | translate }}</p>
                }


            </div>

            <svg width="500" height="512" version="1.1" id="Layer_1"
                 xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 viewBox="0 0 636 651" style="max-width: 90%;
                    max-height: 28vh;enable-background:new 0 0 636 651;" xml:space="preserve">
<style type="text/css">
    .st0 {
        fill: #BDD0FB;
    }

    .st1 {
        fill: url(#SVGID_1_);
    }

    .st2 {
        fill: #91B3FA;
    }

    .st3 {
        fill: #679AF4;
    }

    .st4 {
        fill: #004E9A;
    }

    .st5 {
        fill: #91CFF9;
    }

    .st6 {
        fill: #139CD8;
    }

    .st7 {
        fill: url(#SVGID_00000083808472543888099900000017813700938061089922_);
    }

    .st8 {
        fill: #F1F5FE;
    }

    .st9 {
        fill: #E5ECFD;
    }

    .st10 {
        fill: #FFFFFF;
    }

    .st11 {
        fill: url(#SVGID_00000160161164843668206360000014995169691563732619_);
    }

    .st12 {
        fill: url(#SVGID_00000054268171010094910870000016744509468041915808_);
    }

    .st13 {
        fill: url(#SVGID_00000140711896071608492890000015993857531555617170_);
    }

    .st14 {
        fill: url(#SVGID_00000111910220064319792510000001007551856091935125_);
    }

    .st15 {
        fill: url(#SVGID_00000076565985763443240830000010273044790793520290_);
    }

    .st16 {
        fill: url(#SVGID_00000111888568762877230630000008714766728479223196_);
    }

    .st17 {
        fill: #003968;
    }

    .st18 {
        fill: url(#SVGID_00000067195873295980592300000000762207562906268085_);
    }

    .st19 {
        fill: url(#SVGID_00000042007197252471365910000003653846705746612881_);
    }

    .st20 {
        fill: url(#SVGID_00000073711620654561573570000010812094853285849745_);
    }

    .st21 {
        fill: url(#SVGID_00000044861345512563874740000014843556718120810633_);
    }

    .st22 {
        fill: url(#SVGID_00000140014701971628855680000000589684548127696272_);
    }

    .st23 {
        fill: url(#SVGID_00000178191030083562674490000001954782041801636259_);
    }

    .st24 {
        fill: url(#SVGID_00000095326453132343502960000002218147312712182165_);
    }

    .st25 {
        fill: url(#SVGID_00000176002238364664745760000010423302276768552121_);
    }

    .st26 {
        fill: url(#SVGID_00000183947208972458730980000017139865265579839925_);
    }

    .st27 {
        fill: url(#SVGID_00000034787024710683171530000005099098964240288683_);
    }

    .st28 {
        fill: url(#SVGID_00000180324935094533800070000011908888003036532391_);
    }

    .st29 {
        fill: url(#SVGID_00000160902775420173952700000013108234980877897603_);
    }

    .st30 {
        fill: url(#SVGID_00000056415810025922690000000012904333510691925171_);
    }

    .st31 {
        fill: url(#SVGID_00000176036966964574004080000015886341262042084019_);
    }

    .st32 {
        fill: url(#SVGID_00000052071870747968094230000000069477761104865681_);
    }

    .st33 {
        fill: url(#SVGID_00000059279714191600332890000008077986632508769679_);
    }

    .st34 {
        fill: url(#SVGID_00000159455060248671980450000013938039428017152397_);
    }

    .st35 {
        fill: url(#SVGID_00000002343391622750145440000003879095784086791567_);
    }

    .st36 {
        fill: url(#SVGID_00000094612666773676759990000018197479289593953963_);
    }

    .st37 {
        fill: url(#SVGID_00000175295978598998696900000002109350199014585000_);
    }

    .st38 {
        fill: url(#SVGID_00000176736755077449499760000003724952464649263258_);
    }

    .st39 {
        fill: url(#SVGID_00000027598234347265659620000009486146226242248328_);
    }

    .st40 {
        fill: url(#SVGID_00000047018526010506876040000007232015739241886904_);
    }

    .st41 {
        fill: url(#SVGID_00000018944777707410684130000011541846959535794603_);
    }

    .st42 {
        fill: url(#SVGID_00000057832865078169089830000005273737277015056545_);
    }

    .st43 {
        fill: url(#SVGID_00000050661054764508628790000009377213227382809476_);
    }

    .st44 {
        fill: url(#SVGID_00000085946466529235380810000012880077817228628638_);
    }

    .st45 {
        fill: url(#SVGID_00000163764856940568645820000011930077020092241339_);
    }

    .st46 {
        fill: url(#SVGID_00000049196672690133787300000014789017978969494148_);
    }

    .st47 {
        fill: url(#SVGID_00000153673831037281568670000012321716866124818578_);
    }

    .st48 {
        fill: url(#SVGID_00000028282932515526146990000017569418164853974684_);
    }

    .st49 {
        fill: url(#SVGID_00000136389262457566164060000013045147597391688365_);
    }
</style>
                <path class="st0" d="M141.5,345.7l-5.4,2.4l0,0c-0.4,0.2-0.9,0.1-1.2-0.1c-0.3-0.2-0.2-0.5,0.2-0.7l0,0l5.5-2.4
	c0.4-0.2,0.9-0.1,1.1,0.1C142,345.3,141.9,345.6,141.5,345.7L141.5,345.7z"/>
                <path class="st0" d="M122.8,354.7c-3.4,1.7-6.7,3.5-10,5.4c-0.5,0.3-1.2,0.2-1.6,0c-0.4-0.3-0.4-0.7,0.1-1l0,0
	c3.4-1.8,6.8-3.6,10.2-5.3c0.4-0.2,1.1-0.2,1.5,0.1C123.2,354.1,123.2,354.5,122.8,354.7z"/>
                <path class="st0" d="M100.3,367.3c-3.1,1.9-6.2,3.8-9.2,5.8c-0.5,0.3-1.4,0.4-1.9,0.1c-0.6-0.3-0.6-0.8-0.1-1.1l0,0
	c3.1-2,6.2-3.9,9.4-5.8c0.5-0.3,1.3-0.3,1.8,0C100.8,366.5,100.8,367,100.3,367.3z"/>
                <path class="st0" d="M79.8,380.9c-2.9,2.1-5.6,4.1-8.4,6.3c-0.5,0.4-1.5,0.5-2.2,0.2c-0.7-0.3-0.8-0.9-0.3-1.3l0,0
	c2.8-2.1,5.7-4.2,8.6-6.2c0.5-0.4,1.5-0.4,2.1-0.1C80.2,379.9,80.3,380.5,79.8,380.9z"/>
                <path class="st0" d="M61.3,395.4c-2.5,2.2-5,4.4-7.4,6.7c-0.5,0.5-1.6,0.6-2.5,0.3c-0.8-0.3-1.1-1-0.6-1.5l0,0
	c2.5-2.3,5-4.5,7.6-6.7c0.5-0.5,1.6-0.6,2.4-0.2C61.6,394.4,61.8,395,61.3,395.4z"/>
                <path class="st0" d="M45.1,410.9c-2.2,2.3-4.3,4.7-6.3,7c-0.5,0.6-1.7,0.8-2.6,0.5c-1-0.3-1.4-1-0.9-1.5l0,0
	c2.1-2.4,4.2-4.7,6.5-7.1c0.5-0.5,1.7-0.7,2.6-0.4C45.3,409.7,45.6,410.4,45.1,410.9z"/>
                <path class="st0" d="M31.4,427.2c-1.8,2.4-3.5,4.9-5.1,7.3c-0.4,0.6-1.6,0.9-2.7,0.7c-1.1-0.2-1.6-1-1.2-1.6l0,0
	c1.7-2.5,3.4-5,5.3-7.4c0.5-0.6,1.7-0.9,2.7-0.6C31.4,425.9,31.8,426.6,31.4,427.2z"/>
                <path class="st0" d="M20.5,444.1c-1.4,2.5-2.7,5.1-3.9,7.6c-0.3,0.7-1.5,1.1-2.7,0.9c-1.2-0.2-1.9-0.9-1.5-1.6l0,0
	c1.2-2.6,2.6-5.1,4-7.7c0.4-0.7,1.6-1,2.7-0.8C20.2,442.7,20.8,443.5,20.5,444.1z"/>
                <path class="st0" d="M12.5,461.6c-0.9,2.6-1.8,5.2-2.5,7.8c-0.2,0.8-1.4,1.3-2.7,1.1c-1.3-0.1-2.1-0.8-1.9-1.6l0,0
	c0.8-2.6,1.7-5.3,2.7-7.9c0.3-0.7,1.5-1.2,2.7-1C12,460.2,12.7,460.9,12.5,461.6z"/>
                <path class="st0" d="M7.7,479.5c-0.5,2.6-0.9,5.3-1.2,7.9c-0.1,0.8-1.3,1.4-2.6,1.4s-2.4-0.8-2.3-1.6l0,0c0.4-2.7,0.8-5.3,1.3-8
	c0.1-0.8,1.3-1.3,2.7-1.3C6.8,478,7.8,478.7,7.7,479.5z"/>
                <path class="st0" d="M5.6,497.6c-0.1,2.6-0.2,5.3-0.2,7.9c0,0.8-1.2,1.5-2.6,1.5c-1.4,0-2.6-0.7-2.6-1.5v0c0-2.7,0.1-5.4,0.3-8
	c0.1-0.8,1.2-1.5,2.6-1.4C4.6,496.1,5.7,496.8,5.6,497.6z"/>
                <path class="st0" d="M5.8,515.8c0.2,2.6,0.5,5.3,0.8,7.9c0.1,0.9-1,1.6-2.4,1.7c-1.5,0.1-2.7-0.6-2.8-1.4l0,0
	c-0.3-2.7-0.6-5.4-0.8-8c-0.1-0.9,1.1-1.6,2.5-1.6C4.5,514.3,5.8,514.9,5.8,515.8z"/>
                <path class="st0" d="M8.3,533.9c0.5,2.6,1.2,5.2,1.9,7.9c0.2,0.9-0.8,1.7-2.2,1.8c-1.5,0.1-2.8-0.4-3.1-1.3l0,0
	c-0.7-2.7-1.4-5.3-1.9-8c-0.2-0.9,0.9-1.6,2.3-1.7C6.8,532.4,8.1,533,8.3,533.9z"/>
                <path class="st0" d="M13.5,551.7c1,2.6,2,5.1,3.2,7.7c0.4,0.9-0.5,1.7-1.9,2c-1.5,0.2-2.9-0.3-3.3-1.1l0,0c-1.2-2.6-2.2-5.2-3.2-7.8
	c-0.3-0.9,0.6-1.7,2.1-1.9C11.7,550.3,13.1,550.9,13.5,551.7z"/>
                <path class="st0" d="M21.6,569.2c1.4,2.5,3,5,4.6,7.4c0.6,0.8-0.1,1.8-1.5,2.1c-1.4,0.3-3-0.1-3.6-0.9l0,0c-1.7-2.5-3.2-5-4.7-7.6
	c-0.5-0.8,0.3-1.8,1.7-2C19.6,567.9,21.1,568.3,21.6,569.2z"/>
                <path class="st0" d="M33.2,585.9c2,2.4,4,4.7,6.3,7c0.8,0.8,0.3,1.8-1.1,2.3c-1.3,0.5-3.1,0.2-3.8-0.6l0,0c-2.3-2.4-4.4-4.7-6.4-7.2
	c-0.7-0.8-0.1-1.8,1.3-2.2C30.9,584.7,32.5,585.1,33.2,585.9z"/>
                <path class="st0" d="M48.6,601.4c2.5,2.2,5.2,4.3,8,6.3c1,0.7,0.8,1.8-0.4,2.4c-1.2,0.6-3,0.5-4-0.3l0,0c-2.9-2.1-5.6-4.3-8.2-6.5
	c-0.9-0.8-0.6-1.8,0.7-2.3C46,600.5,47.7,600.7,48.6,601.4z"/>
                <path class="st0" d="M68,615.3c3.1,1.9,6.3,3.7,9.7,5.4c1.2,0.6,1.3,1.7,0.3,2.4c-1.1,0.7-2.9,0.8-4.1,0.1l0,0
	c-3.4-1.8-6.8-3.7-10-5.6c-1.1-0.7-1.1-1.7,0.1-2.4C65,614.6,66.8,614.7,68,615.3z"/>
                <path class="st0" d="M91,627.1c1.8,0.8,3.6,1.5,5.5,2.2c1.8,0.7,3.7,1.4,5.6,2.1l0.1,0c1.3,0.5,1.7,1.6,0.9,2.4
	c-0.9,0.8-2.6,1-4,0.5c-1.9-0.7-3.9-1.4-5.8-2.2c-1.9-0.7-3.8-1.5-5.7-2.3c-1.3-0.6-1.6-1.6-0.7-2.4C87.9,626.7,89.7,626.5,91,627.1
	L91,627.1z"/>
                <path class="st0" d="M117.2,636.3c4,1.2,8.1,2.2,12.2,3.2c1.5,0.3,2.2,1.3,1.6,2.2c-0.6,0.9-2.2,1.3-3.7,0.9
	c-4.3-1-8.5-2.1-12.6-3.3c-1.4-0.4-2-1.4-1.3-2.2C114,636.3,115.7,635.9,117.2,636.3L117.2,636.3z"/>
                <path class="st0" d="M145.6,642.8c4.3,0.7,8.6,1.4,13,2c1.5,0.2,2.4,1.1,2.1,1.9c-0.3,0.9-1.8,1.4-3.3,1.2c-4.5-0.6-8.9-1.2-13.4-2
	c-1.5-0.3-2.3-1.2-1.9-2C142.6,643.1,144.1,642.6,145.6,642.8L145.6,642.8z"/>
                <path class="st0" d="M175.6,646.6c4.4,0.3,8.9,0.6,13.3,0.8c1.5,0.1,2.6,0.8,2.5,1.7c-0.1,0.9-1.4,1.5-2.9,1.5
	c-4.6-0.2-9.1-0.4-13.7-0.8c-1.5-0.1-2.6-0.9-2.4-1.8C172.7,647.1,174,646.5,175.6,646.6L175.6,646.6z"/>
                <path class="st0" d="M206.2,647.7c4.5,0,9-0.1,13.4-0.2c1.5,0,2.7,0.6,2.8,1.5c0.1,0.9-1,1.6-2.5,1.6c-4.6,0.2-9.1,0.3-13.7,0.3
	c-1.5,0-2.7-0.7-2.7-1.6C203.5,648.4,204.7,647.7,206.2,647.7L206.2,647.7z"/>
                <path class="st0" d="M236.8,646.6c4.5-0.3,8.9-0.7,13.3-1.1c1.4-0.1,2.8,0.4,3,1.3c0.2,0.8-0.7,1.6-2.1,1.8c-4.5,0.4-9,0.8-13.6,1.2
	c-1.4,0.1-2.8-0.5-2.9-1.3C234.3,647.5,235.4,646.7,236.8,646.6L236.8,646.6z"/>
                <path class="st0" d="M267.2,643.6c4.4-0.6,8.8-1.2,13.1-1.8c1.3-0.2,2.6,0.3,3,1c0.3,0.8-0.4,1.5-1.7,1.8c-4.4,0.7-8.8,1.3-13.3,1.9
	c-1.4,0.2-2.7-0.3-3-1.1C264.9,644.6,265.8,643.8,267.2,643.6L267.2,643.6z"/>
                <path class="st0" d="M297,639c4.3-0.8,8.6-1.6,12.8-2.5c1.2-0.2,2.5,0.1,2.9,0.8c0.4,0.7-0.2,1.4-1.4,1.7c-4.3,0.9-8.6,1.8-12.9,2.6
	c-1.2,0.2-2.6-0.2-3-0.9C295.1,640,295.7,639.2,297,639L297,639z"/>
                <path class="st0" d="M326.1,633c4.2-1,8.4-2,12.5-3c1.1-0.3,2.3,0,2.8,0.6c0.5,0.6,0,1.3-1.1,1.6c-4.2,1.1-8.4,2.1-12.6,3.1
	c-1.1,0.3-2.4-0.1-2.8-0.7C324.5,634,325,633.3,326.1,633L326.1,633z"/>
                <path class="st0" d="M354.5,625.7c4.1-1.1,8.1-2.3,12.1-3.6c0.9-0.3,2.1-0.1,2.6,0.5c0.5,0.5,0.1,1.2-0.8,1.5
	c-4,1.3-8.1,2.5-12.1,3.7c-1,0.3-2.2,0-2.7-0.5C353.1,626.7,353.5,626,354.5,625.7L354.5,625.7z"/>
                <path class="st0" d="M381.8,617.3c3.9-1.3,7.7-2.7,11.5-4.2c0.8-0.3,1.8-0.2,2.3,0.3c0.5,0.5,0.3,1.1-0.5,1.4
	c-3.8,1.5-7.6,2.9-11.5,4.3c-0.8,0.3-2,0.1-2.5-0.4C380.6,618.2,380.9,617.6,381.8,617.3L381.8,617.3z"/>
                <path class="st0" d="M407.6,607.3c3.7-1.6,7.3-3.1,10.8-4.8c0.6-0.3,1.5-0.2,2,0.1c0.5,0.4,0.4,0.9-0.2,1.1
	c-3.5,1.7-7.1,3.3-10.7,4.9c-0.7,0.3-1.6,0.2-2.1-0.2C406.8,608.2,407,607.6,407.6,607.3L407.6,607.3z"/>
                <path class="st0" d="M431.8,596c3.4-1.7,6.7-3.5,10-5.4c0.4-0.2,1.1-0.2,1.6,0c0.4,0.3,0.4,0.7,0,0.9c-3.2,1.9-6.5,3.7-9.9,5.5
	c-0.5,0.3-1.3,0.2-1.8,0C431.3,596.7,431.3,596.3,431.8,596L431.8,596z"/>
                <path class="st0" d="M454.2,583.4l4.7-2.9l0,0c0.3-0.2,0.8-0.2,1.2,0c0.3,0.2,0.3,0.5,0,0.7l-4.6,3c-0.3,0.2-0.9,0.2-1.3,0
	C453.9,584,453.9,583.7,454.2,583.4L454.2,583.4L454.2,583.4z"/>
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="735.203" y1="826.5598"
                                x2="154.72" y2="118.3177" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#DBEDFF;stop-opacity:0.23"/>
                    <stop offset="1" style="stop-color:#E9EFFD"/>
</linearGradient>
                <path class="st1" d="M359.4,554.4c-33.4,0-58.9-4.7-69.1-6.7c-30.8-6.3-85.7-17.4-133-56.3h389.4c-5.2,4.2-10.1,7.7-14.4,10.8
	C473.4,544.5,408,554.4,359.4,554.4z M106.8,431c-51-90.8-35.3-213.5,18.3-297.9c11.1-17.5,69.6-114.4,188.5-129.9
	c11.8-1.5,23.9-2.3,36.2-2.3c62.6,0,129.6,20.2,180.4,62.8c103.4,86.6,117,244.8,72.7,349.7l3-204.4c0-4.7-5.3-13.6-15-13.6
	c-0.4,0-0.7,0-1.1,0l-60-1.6l-5.5-107.4C523.5,72.2,511.7,61,497.5,61H264.2c-10-1.2-18.1-2.2-23.9-3c-0.4-0.1-0.8-0.1-1.2-0.1
	c-3.1,0-5.9,1.6-7.5,4.1c-12.9,3.5-22.5,15.3-22.5,29.3v94.2l-84.9-2.2c-0.2,0-0.4,0-0.6,0c-5.9,0-11.5,2.3-15.7,6.5
	c-4.3,4.3-6.7,10.2-6.5,16.2L106.8,431z"/>
                <path class="st2" d="M574.6,66.4c-0.2,0.2-0.5,0.2-0.7,0.2c-1-0.3-2-0.5-3-0.7c-0.4-0.1-0.7-0.5-0.6-0.9c0.1-0.4,0.5-0.7,0.9-0.6
	c1,0.2,2,0.5,3,0.8c0.4,0.1,0.7,0.5,0.5,1C574.8,66.1,574.7,66.3,574.6,66.4z"/>
                <path class="st2" d="M590.1,72.5c-0.2,0.2-0.6,0.3-0.9,0.1c-1.8-1-3.8-1.9-5.7-2.7c-0.4-0.2-0.6-0.6-0.4-1c0.2-0.4,0.6-0.6,1-0.4
	c2,0.8,3.9,1.8,5.8,2.8c0.4,0.2,0.5,0.7,0.3,1.1C590.2,72.3,590.2,72.4,590.1,72.5z M603.8,81.9c-0.3,0.2-0.7,0.3-1,0
	c-1.6-1.4-3.2-2.7-4.9-3.9c-0.3-0.3-0.4-0.7-0.2-1.1c0.3-0.3,0.7-0.4,1.1-0.2c1.7,1.3,3.4,2.6,5,4C604.1,81,604.2,81.5,603.8,81.9
	C603.9,81.8,603.8,81.8,603.8,81.9z M615.1,94.1L615.1,94.1c-0.4,0.3-0.9,0.2-1.1-0.1c-1.1-1.5-2.3-2.9-3.5-4.3
	c-0.2-0.2-0.3-0.4-0.5-0.6c-0.3-0.3-0.3-0.8,0.1-1.1l0,0c0.3-0.3,0.8-0.2,1.1,0.1c0.2,0.2,0.3,0.4,0.5,0.6c1.2,1.4,2.4,2.9,3.5,4.4
	C615.5,93.3,615.4,93.8,615.1,94.1z M623.4,108.5c-0.1,0-0.1,0.1-0.2,0.1c-0.4,0.2-0.9,0-1-0.4c-0.8-1.9-1.8-3.8-2.7-5.6
	c-0.2-0.4-0.1-0.8,0.3-1.1c0.4-0.2,0.8-0.1,1.1,0.3c1,1.9,2,3.8,2.8,5.8C623.7,107.9,623.6,108.3,623.4,108.5z M628.3,124.4
	c-0.1,0.1-0.2,0.1-0.4,0.2c-0.4,0.1-0.8-0.2-0.9-0.6c-0.4-2.1-0.9-4.1-1.4-6.1c-0.1-0.4,0.1-0.8,0.5-1c0.4-0.1,0.8,0.1,1,0.5
	c0.6,2,1.1,4.1,1.5,6.2C628.6,123.9,628.5,124.2,628.3,124.4z M629.5,140.9c-0.1,0.1-0.3,0.2-0.5,0.2c-0.4,0-0.8-0.4-0.7-0.8
	c0.1-2.1,0.1-4.2,0-6.3c0-0.4,0.3-0.8,0.7-0.8c0.4,0,0.8,0.3,0.8,0.7c0.1,2.1,0.1,4.3,0,6.4C629.8,140.6,629.7,140.8,629.5,140.9z
	 M627.1,157.4c-0.2,0.2-0.4,0.2-0.7,0.2c-0.4-0.1-0.7-0.5-0.5-0.9c0.5-2,1-4.1,1.4-6.1c0.1-0.4,0.5-0.7,0.9-0.6
	c0.4,0.1,0.7,0.5,0.6,0.9c-0.4,2.1-0.8,4.2-1.4,6.3C627.3,157.1,627.2,157.3,627.1,157.4z M621,172.8c-0.2,0.2-0.6,0.3-0.9,0.1
	c-0.4-0.2-0.5-0.7-0.3-1.1c1-1.8,1.9-3.8,2.7-5.7c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1c-0.8,2-1.8,3.9-2.8,5.8
	C621.1,172.7,621,172.8,621,172.8z M611.6,186.6c-0.3,0.2-0.7,0.3-1,0c-0.3-0.3-0.4-0.8-0.1-1.1c1.4-1.6,2.7-3.2,3.9-4.9
	c0.2-0.4,0.7-0.4,1.1-0.2c0.3,0.3,0.4,0.7,0.2,1.1C614.4,183.2,613.1,184.9,611.6,186.6C611.7,186.5,611.7,186.6,611.6,186.6z
	 M603.7,194.5c-1.4,1.2-2.8,2.3-4.3,3.4c-0.3,0.3-0.8,0.2-1.1-0.2c-0.3-0.3-0.2-0.8,0.2-1.1c1.7-1.2,3.3-2.6,4.9-3.9l0,0
	c0.3-0.3,0.8-0.2,1.1,0.1c0.3,0.3,0.3,0.8-0.1,1.1C604.2,194.1,603.9,194.3,603.7,194.5z"/>
                <path class="st2" d="M590.7,203.5c0,0-0.1,0.1-0.1,0.1c-0.9,0.5-1.9,1-2.8,1.4c-0.4,0.2-0.9,0-1-0.4c-0.2-0.4,0-0.8,0.4-1
	c0.9-0.4,1.8-0.9,2.7-1.4c0.4-0.2,0.8-0.1,1.1,0.3C591.1,202.8,591,203.2,590.7,203.5z"/>
                <path class="st2" d="M532.7,209c-0.2,0.2-0.5,0.2-0.7,0.2c-1-0.3-2-0.7-3-1c-0.4-0.1-0.6-0.6-0.5-1c0.1-0.4,0.6-0.6,1-0.5
	c1,0.4,1.9,0.7,2.9,1c0.4,0.1,0.6,0.6,0.5,1C532.9,208.8,532.8,208.9,532.7,209z"/>
                <path class="st2" d="M513.9,76.3C513.9,76.3,513.9,76.3,513.9,76.3c-1.8,1.2-3.5,2.4-5.1,3.7c-0.3,0.3-0.8,0.2-1.1-0.1
	c-0.3-0.3-0.2-0.8,0.1-1.1c1.6-1.3,3.3-2.6,5.1-3.7c0.4-0.2,0.8-0.1,1.1,0.2C514.3,75.6,514.2,76,513.9,76.3z M497.3,91.5
	c-0.3,0.2-0.7,0.3-1,0c-0.3-0.3-0.4-0.8-0.1-1.1c1.3-1.6,2.7-3.2,4.2-4.7c0.3-0.3,0.8-0.3,1.1,0c0.3,0.3,0.3,0.8,0,1.1
	c-1.4,1.5-2.8,3-4.1,4.7C497.4,91.5,497.3,91.5,497.3,91.5z M488.5,105.3c-0.2,0.2-0.6,0.2-0.9,0.1c-0.4-0.2-0.5-0.7-0.4-1
	c0.9-1.9,1.9-3.7,3-5.5c0.2-0.4,0.7-0.5,1.1-0.3c0.4,0.2,0.5,0.7,0.3,1.1c-1.1,1.8-2.1,3.6-3,5.4
	C488.6,105.2,488.6,105.2,488.5,105.3z M482.9,120.7c-0.2,0.2-0.4,0.2-0.7,0.2c-0.4-0.1-0.7-0.5-0.6-0.9c0.5-2,1.1-4.1,1.7-6.1
	c0.1-0.4,0.6-0.6,1-0.5c0.4,0.1,0.6,0.6,0.5,1c-0.6,1.9-1.2,3.9-1.7,5.9C483.1,120.4,483,120.6,482.9,120.7z M480.9,136.9
	c-0.1,0.1-0.3,0.2-0.5,0.2c-0.4,0-0.8-0.4-0.8-0.8c0-2.1,0.2-4.2,0.4-6.3c0-0.4,0.4-0.7,0.9-0.7c0.4,0,0.7,0.4,0.7,0.8
	c-0.2,2-0.3,4.1-0.4,6.2C481.1,136.6,481,136.8,480.9,136.9z M482.4,153.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.4,0.1-0.8-0.2-0.9-0.6
	c-0.4-2-0.8-4.1-1-6.2c-0.1-0.4,0.2-0.8,0.7-0.9c0.4-0.1,0.8,0.3,0.9,0.7c0.2,2,0.6,4.1,1,6.1C482.7,152.7,482.6,153,482.4,153.2z
	 M487.4,168.7c-0.1,0-0.1,0.1-0.2,0.1c-0.4,0.2-0.9,0-1-0.4c-0.9-1.9-1.6-3.9-2.3-5.9c-0.1-0.4,0.1-0.8,0.5-1c0.4-0.1,0.9,0.1,1,0.5
	c0.7,1.9,1.5,3.9,2.3,5.7C487.8,168.2,487.7,168.5,487.4,168.7z M495.8,182.8L495.8,182.8c-0.4,0.3-0.9,0.2-1.1-0.1
	c-1.3-1.7-2.5-3.4-3.6-5.2c-0.2-0.4-0.1-0.8,0.2-1.1c0.4-0.2,0.8-0.1,1.1,0.2c1.1,1.7,2.3,3.5,3.5,5.1
	C496.1,182.1,496.1,182.6,495.8,182.8z M507,194.7c-0.3,0.2-0.7,0.3-1,0c-1.6-1.4-3.2-2.8-4.6-4.3c-0.3-0.3-0.3-0.8,0-1.1
	c0.3-0.3,0.8-0.3,1.1,0c1.4,1.5,3,2.9,4.5,4.2C507.3,193.8,507.4,194.3,507,194.7C507,194.7,507,194.7,507,194.7z M520.5,203.9
	c-0.2,0.2-0.6,0.3-0.9,0.1c-1.8-1-3.7-2-5.4-3.2c-0.4-0.2-0.5-0.7-0.2-1.1c0.2-0.4,0.7-0.5,1.1-0.2c1.7,1.1,3.5,2.2,5.3,3.1
	c0.4,0.2,0.5,0.7,0.3,1C520.7,203.8,520.6,203.8,520.5,203.9z"/>
                <path class="st2" d="M525.6,69.9c-0.1,0.1-0.1,0.1-0.2,0.1c-0.9,0.4-1.9,0.8-2.8,1.3c-0.4,0.2-0.9,0-1-0.4c-0.2-0.4,0-0.9,0.4-1
	c0.9-0.5,1.9-0.9,2.9-1.3c0.4-0.2,0.9,0,1,0.4C525.9,69.4,525.8,69.7,525.6,69.9z"/>
                <path class="st3" d="M538.7,82.7c-0.4,0.1-0.7,0.4-0.7,0.7l-1.7,10.7c-4.7,2-9,4.8-12.9,8.3l-10.4-3c-0.4-0.1-0.8,0-1,0.3l-7.4,10.7
	c-0.2,0.3-0.2,0.7,0,1l6.4,8.8c-1.9,4.8-3,9.9-3.3,15l-9.5,5.3c-0.3,0.2-0.5,0.6-0.4,0.9l2.3,12.8c0.1,0.4,0.4,0.7,0.7,0.7l10.7,1.7
	c2,4.7,4.8,9,8.3,12.9l-3,10.4c-0.1,0.4,0,0.8,0.3,1l10.7,7.5c0.3,0.2,0.7,0.2,1,0l8.8-6.4c4.8,1.9,9.9,3,15,3.3l5.3,9.5
	c0.2,0.3,0.6,0.5,0.9,0.4l12.8-2.3c0.4-0.1,0.7-0.4,0.7-0.7l1.7-10.7c4.7-2,9-4.8,12.9-8.3l10.4,3c0.4,0.1,0.8,0,1-0.3l7.5-10.7
	c0.2-0.3,0.2-0.7,0-1l-6.4-8.8c1.9-4.8,3-9.9,3.3-15l9.5-5.3c0.3-0.2,0.5-0.6,0.4-0.9l-2.3-12.8c-0.1-0.4-0.4-0.7-0.7-0.7l-10.7-1.7
	c-2-4.7-4.8-9-8.3-12.9l3-10.4c0.1-0.4,0-0.8-0.3-1l-10.7-7.5c-0.3-0.2-0.7-0.2-1,0l-8.8,6.4c-4.8-1.9-9.9-3-15-3.3l-5.3-9.5
	c-0.2-0.3-0.6-0.5-0.9-0.4L538.7,82.7z"/>
                <path class="st0" d="M548.4,130c-1.3,1.1-2.3,2.6-2.9,4.2c-1,2.6-0.9,5.4,0.2,8c1.1,2.5,3.2,4.5,5.8,5.4c2.6,1,5.4,0.9,8-0.2
	c2.5-1.1,4.5-3.2,5.4-5.8c1-2.6,0.9-5.4-0.2-8c-1.1-2.5-3.2-4.5-5.8-5.5c-2.6-1-5.4-0.9-8,0.2C550,128.8,549.1,129.4,548.4,130z
	 M563.5,147.5c-0.9,0.8-2,1.4-3.1,2c-3.1,1.4-6.6,1.5-9.7,0.3c-3.2-1.2-5.7-3.6-7.1-6.7c-1.4-3.1-1.5-6.6-0.3-9.7
	c1.2-3.2,3.6-5.7,6.7-7.1c3.1-1.4,6.6-1.5,9.7-0.3c3.2,1.2,5.7,3.6,7.1,6.7c1.4,3.1,1.5,6.6,0.3,9.7
	C566.3,144.4,565.1,146.2,563.5,147.5z"/>
                <path class="st0" d="M576.5,162.9c-11,9.5-27.5,10.7-39.9,2c-2.3-1.6-4.3-3.4-6.1-5.5c-9.5-11-10.7-27.5-2-39.9
	c0.2-0.2,0.3-0.4,0.5-0.7c1.1-1.5,2.3-2.9,3.6-4.1c0.2-0.2,0.3-0.3,0.5-0.4l1.6,1.7c-1.6,1.5-3,3.1-4.2,4.9l0,0.1
	c-9.5,13.7-6.1,32.6,7.6,42.1c13.7,9.5,32.6,6.2,42.1-7.5c8.1-11.6,6.9-26.8-1.9-37.1c-1.6-1.9-3.5-3.6-5.6-5.1
	c-4.5-3.1-9.8-5-15.2-5.4l0.2-2.3c5.9,0.4,11.5,2.4,16.4,5.8c2.3,1.6,4.3,3.4,6.1,5.5c9.5,11,10.7,27.5,2,39.9
	C580.4,159.1,578.6,161.1,576.5,162.9z"/>
                <path class="st2" d="M513.3,9.6c-0.1,0.1-0.3,0.1-0.4,0.1c-0.6-0.2-1.2-0.3-1.8-0.4c-0.2-0.1-0.4-0.3-0.4-0.6
	c0.1-0.3,0.3-0.4,0.6-0.4c0.6,0.1,1.2,0.3,1.8,0.5c0.2,0.1,0.4,0.3,0.3,0.6C513.4,9.5,513.3,9.6,513.3,9.6z"/>
                <path class="st2" d="M522.6,13.3c-0.1,0.1-0.3,0.1-0.5,0.1c-1.1-0.6-2.3-1.1-3.4-1.6c-0.2-0.1-0.3-0.4-0.2-0.6
	c0.1-0.2,0.4-0.3,0.6-0.2c1.2,0.5,2.3,1.1,3.5,1.7c0.2,0.1,0.3,0.4,0.2,0.6C522.6,13.2,522.6,13.3,522.6,13.3z M530.8,18.9
	c-0.2,0.1-0.4,0.2-0.6,0c-0.9-0.8-1.9-1.6-3-2.3c-0.2-0.1-0.3-0.4-0.1-0.7c0.2-0.2,0.4-0.3,0.7-0.1c1,0.8,2.1,1.6,3,2.4
	C531,18.4,531,18.7,530.8,18.9L530.8,18.9z M537.6,26.3L537.6,26.3c-0.2,0.2-0.5,0.1-0.7-0.1c-0.7-0.9-1.4-1.8-2.1-2.6
	c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.2-0.2-0.5,0-0.7l0,0c0.2-0.2,0.5-0.1,0.7,0c0.1,0.1,0.2,0.2,0.3,0.3c0.7,0.9,1.4,1.7,2.1,2.7
	C537.8,25.8,537.8,26.1,537.6,26.3z M542.6,34.9c0,0-0.1,0.1-0.1,0.1c-0.2,0.1-0.5,0-0.6-0.2c-0.5-1.2-1.1-2.3-1.6-3.4
	c-0.1-0.2,0-0.5,0.2-0.6c0.2-0.1,0.5,0,0.6,0.2c0.6,1.1,1.2,2.3,1.7,3.5C542.8,34.6,542.7,34.8,542.6,34.9z M545.5,44.5
	c-0.1,0.1-0.1,0.1-0.2,0.1c-0.3,0-0.5-0.1-0.5-0.4c-0.2-1.2-0.5-2.5-0.9-3.7c-0.1-0.2,0.1-0.5,0.3-0.6c0.2-0.1,0.5,0.1,0.6,0.3
	c0.3,1.2,0.6,2.5,0.9,3.8C545.7,44.2,545.6,44.4,545.5,44.5z M546.3,54.4c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3,0-0.5-0.2-0.4-0.5
	c0-1.3,0-2.5,0-3.8c0-0.3,0.2-0.5,0.4-0.5c0.3,0,0.5,0.2,0.5,0.4c0.1,1.3,0.1,2.6,0,3.9C546.4,54.2,546.4,54.4,546.3,54.4z
	 M544.8,64.3c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2-0.1-0.4-0.3-0.3-0.6c0.3-1.2,0.6-2.4,0.8-3.7c0-0.3,0.3-0.4,0.5-0.4
	c0.3,0,0.4,0.3,0.4,0.5c-0.2,1.3-0.5,2.5-0.8,3.8C544.9,64.2,544.8,64.3,544.8,64.3z M541.1,73.6c-0.1,0.1-0.4,0.2-0.5,0.1
	c-0.2-0.1-0.3-0.4-0.2-0.6c0.6-1.1,1.1-2.3,1.6-3.4c0.1-0.2,0.4-0.3,0.6-0.2c0.2,0.1,0.3,0.4,0.2,0.6c-0.5,1.2-1.1,2.3-1.7,3.5
	C541.2,73.5,541.2,73.6,541.1,73.6z M535.5,81.9c-0.2,0.1-0.4,0.2-0.6,0c-0.2-0.2-0.2-0.5-0.1-0.7c0.8-1,1.6-2,2.3-3
	c0.2-0.2,0.4-0.3,0.7-0.1c0.2,0.1,0.3,0.4,0.1,0.7C537.2,79.8,536.4,80.9,535.5,81.9C535.5,81.8,535.5,81.9,535.5,81.9z M530.7,86.7
	c-0.8,0.7-1.7,1.4-2.6,2c-0.2,0.2-0.5,0.1-0.7-0.1c-0.2-0.2-0.1-0.5,0.1-0.7c1-0.7,2-1.5,2.9-2.4l0,0c0.2-0.2,0.5-0.1,0.7,0
	c0.2,0.2,0.2,0.5,0,0.7C531,86.4,530.9,86.5,530.7,86.7z"/>
                <path class="st2" d="M522.9,92C522.9,92,522.9,92.1,522.9,92c-0.6,0.4-1.2,0.6-1.8,0.9c-0.2,0.1-0.5,0-0.6-0.2
	c-0.1-0.2,0-0.5,0.2-0.6c0.5-0.3,1.1-0.6,1.6-0.8c0.2-0.1,0.5,0,0.6,0.2C523.2,91.6,523.1,91.9,522.9,92z"/>
                <path class="st2" d="M488.1,95.4c-0.1,0.1-0.3,0.1-0.4,0.1c-0.6-0.2-1.2-0.4-1.8-0.6c-0.2-0.1-0.4-0.4-0.3-0.6
	c0.1-0.2,0.4-0.4,0.6-0.3c0.6,0.2,1.2,0.4,1.8,0.6c0.2,0.1,0.4,0.3,0.3,0.6C488.2,95.2,488.2,95.3,488.1,95.4z"/>
                <path class="st2" d="M476.8,15.6L476.8,15.6c-1.1,0.7-2.1,1.5-3,2.2c-0.2,0.2-0.5,0.1-0.7-0.1c-0.2-0.2-0.1-0.5,0.1-0.7
	c1-0.8,2-1.5,3.1-2.2c0.2-0.1,0.5-0.1,0.6,0.1C477,15.2,477,15.4,476.8,15.6z M466.8,24.7c-0.2,0.1-0.4,0.2-0.6,0
	c-0.2-0.2-0.2-0.5-0.1-0.7c0.8-1,1.6-1.9,2.5-2.9c0.2-0.2,0.5-0.2,0.7,0c0.2,0.2,0.2,0.5,0,0.7C468.4,22.8,467.6,23.7,466.8,24.7
	C466.8,24.7,466.8,24.7,466.8,24.7z M461.5,33c-0.1,0.1-0.3,0.1-0.5,0.1c-0.2-0.1-0.3-0.4-0.2-0.6c0.6-1.1,1.2-2.2,1.8-3.3
	c0.1-0.2,0.4-0.3,0.6-0.2c0.2,0.1,0.3,0.4,0.2,0.6c-0.6,1.1-1.2,2.2-1.8,3.3C461.6,32.9,461.5,33,461.5,33z M458.1,42.3
	c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2-0.1-0.4-0.3-0.3-0.6c0.3-1.2,0.6-2.5,1-3.6c0.1-0.2,0.3-0.4,0.6-0.3c0.2,0.1,0.4,0.3,0.3,0.6
	c-0.4,1.2-0.7,2.4-1,3.6C458.3,42.1,458.2,42.2,458.1,42.3z M456.9,52c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3,0-0.5-0.2-0.5-0.5
	c0-1.3,0.1-2.5,0.2-3.8c0-0.3,0.3-0.4,0.5-0.4c0.3,0,0.4,0.3,0.4,0.5c-0.1,1.2-0.2,2.5-0.2,3.7C457.1,51.8,457,51.9,456.9,52z
	 M457.8,61.8c-0.1,0-0.1,0.1-0.2,0.1c-0.3,0.1-0.5-0.1-0.6-0.4c-0.3-1.2-0.5-2.5-0.6-3.7c0-0.3,0.2-0.5,0.4-0.5
	c0.3,0,0.5,0.2,0.5,0.4c0.1,1.2,0.3,2.5,0.6,3.7C458,61.5,458,61.7,457.8,61.8z M460.9,71.2c0,0-0.1,0.1-0.1,0.1
	c-0.2,0.1-0.5,0-0.6-0.2c-0.5-1.1-1-2.3-1.4-3.5c-0.1-0.2,0-0.5,0.3-0.6c0.2-0.1,0.5,0,0.6,0.3c0.4,1.2,0.9,2.3,1.4,3.4
	C461.1,70.8,461,71,460.9,71.2z M465.9,79.6L465.9,79.6c-0.2,0.2-0.5,0.1-0.7-0.1c-0.8-1-1.5-2.1-2.1-3.1c-0.1-0.2-0.1-0.5,0.1-0.6
	c0.2-0.1,0.5-0.1,0.6,0.1c0.7,1,1.4,2.1,2.1,3.1C466.1,79.2,466.1,79.5,465.9,79.6z M472.6,86.8c-0.2,0.1-0.4,0.2-0.6,0
	c-1-0.8-1.9-1.7-2.8-2.6c-0.2-0.2-0.2-0.5,0-0.7c0.2-0.2,0.5-0.2,0.7,0c0.9,0.9,1.8,1.7,2.7,2.5C472.8,86.2,472.8,86.5,472.6,86.8
	C472.6,86.7,472.6,86.8,472.6,86.8z M480.8,92.3c-0.1,0.1-0.3,0.2-0.5,0.1c-1.1-0.6-2.2-1.2-3.3-1.9c-0.2-0.1-0.3-0.4-0.1-0.6
	c0.1-0.2,0.4-0.3,0.6-0.1c1,0.7,2.1,1.3,3.2,1.9c0.2,0.1,0.3,0.4,0.2,0.6C480.8,92.2,480.8,92.3,480.8,92.3z"/>
                <path class="st2" d="M483.8,11.8c0,0-0.1,0.1-0.1,0.1c-0.6,0.2-1.1,0.5-1.7,0.8c-0.2,0.1-0.5,0-0.6-0.2c-0.1-0.2,0-0.5,0.2-0.6
	c0.6-0.3,1.1-0.5,1.7-0.8c0.2-0.1,0.5,0,0.6,0.2C484,11.4,483.9,11.6,483.8,11.8z"/>
                <path class="st3" d="M491.7,19.4c-0.2,0-0.4,0.2-0.4,0.4l-1,6.4c-2.8,1.2-5.4,2.9-7.8,5l-6.3-1.8c-0.2-0.1-0.5,0-0.6,0.2l-4.5,6.4
	c-0.1,0.2-0.1,0.4,0,0.6l3.8,5.3c-1.2,2.9-1.8,6-2,9l-5.7,3.2c-0.2,0.1-0.3,0.3-0.3,0.6l1.4,7.7c0,0.2,0.2,0.4,0.4,0.4l6.4,1
	c1.2,2.8,2.9,5.4,5,7.8l-1.8,6.3c-0.1,0.2,0,0.5,0.2,0.6l6.4,4.5c0.2,0.1,0.4,0.1,0.6,0l5.3-3.8c2.9,1.2,6,1.8,9,2l3.2,5.7
	c0.1,0.2,0.3,0.3,0.6,0.3l7.7-1.4c0.2,0,0.4-0.2,0.4-0.4l1-6.5c2.8-1.2,5.4-2.9,7.8-5l6.3,1.8c0.2,0.1,0.5,0,0.6-0.2l4.5-6.4
	c0.1-0.2,0.1-0.4,0-0.6l-3.8-5.3c1.2-2.9,1.8-6,2-9l5.7-3.2c0.2-0.1,0.3-0.3,0.3-0.6l-1.4-7.7c0-0.2-0.2-0.4-0.4-0.4l-6.4-1
	c-1.2-2.8-2.9-5.4-5-7.8l1.8-6.3c0.1-0.2,0-0.5-0.2-0.6l-6.4-4.5c-0.2-0.1-0.4-0.1-0.6,0l-5.3,3.8c-2.9-1.2-6-1.8-9-2l-3.2-5.7
	c-0.1-0.2-0.3-0.3-0.6-0.3L491.7,19.4z"/>
                <path class="st0" d="M497.5,47.9c-0.8,0.7-1.4,1.5-1.8,2.5c-0.6,1.6-0.5,3.3,0.1,4.8c0.7,1.5,1.9,2.7,3.5,3.3
	c1.6,0.6,3.3,0.5,4.8-0.1c1.5-0.7,2.7-1.9,3.3-3.5c0.6-1.6,0.5-3.3-0.1-4.8c-0.7-1.5-1.9-2.7-3.5-3.3c-1.6-0.6-3.3-0.5-4.8,0.1
	C498.5,47.2,498,47.5,497.5,47.9z M506.6,58.4c-0.6,0.5-1.2,0.9-1.9,1.2c-1.9,0.8-3.9,0.9-5.9,0.2c-1.9-0.7-3.4-2.1-4.3-4
	c-0.8-1.9-0.9-3.9-0.2-5.9c0.7-1.9,2.1-3.4,4-4.3s3.9-0.9,5.9-0.2c1.9,0.7,3.4,2.1,4.3,4c0.8,1.9,0.9,3.9,0.2,5.9
	C508.3,56.5,507.5,57.6,506.6,58.4z"/>
                <path class="st0" d="M514.4,67.6c-6.6,5.7-16.5,6.4-24,1.2c-1.4-1-2.6-2.1-3.7-3.3c-5.7-6.6-6.4-16.5-1.2-24
	c0.1-0.1,0.2-0.3,0.3-0.4c0.7-0.9,1.4-1.7,2.2-2.5c0.1-0.1,0.2-0.2,0.3-0.3l1,1c-0.9,0.9-1.8,1.9-2.5,2.9l0,0
	c-5.7,8.2-3.7,19.6,4.5,25.3c8.2,5.7,19.6,3.7,25.3-4.5c4.8-6.9,4.2-16.1-1.1-22.3c-1-1.1-2.1-2.2-3.4-3.1c-2.7-1.9-5.9-3-9.1-3.2
	l0.1-1.4c3.5,0.2,6.9,1.4,9.8,3.5c1.4,1,2.6,2.1,3.6,3.3c5.7,6.6,6.4,16.5,1.2,24C516.7,65.3,515.6,66.6,514.4,67.6z"/>
                <path class="st4" d="M593,491.4c-186,0-290.3,0-476.2,0c-4.8,0-8.8-4-8.8-8.8l-1.2-51.6l-5.3-225c-0.1-6.1,2.2-11.9,6.5-16.2
	c4.2-4.2,9.8-6.5,15.7-6.5c0.2,0,0.4,0,0.6,0l84.9,2.2V196l-83.1-2.2c-0.1,0-0.3,0-0.4,0c-4.1,0-8.1,1.6-11,4.6
	c-3,3-4.7,7.1-4.6,11.4l6.6,264.5c0,4.6,3.7,8.3,8.3,8.3h91.2c3.2,2.5,7.2,4,11.6,4c0.1,0,0.2,0,0.3,0l221.8-4h131.6
	c4.6,0,8.3-3.8,8.3-8.3l3.7-248.4c-0.4-14.5-12.8-20-20.8-20c-0.2,0-0.5,0-0.7,0l-41.9-1.1l-0.5-10.8l60,1.6c0.4,0,0.7,0,1.1,0
	c9.7,0,15,8.9,15,13.6l-3,204.4l-1,69.3C601.9,487.5,597.9,491.4,593,491.4z"/>
                <path class="st5" d="M116.7,474.2c0,4.6,3.7,8.3,8.3,8.3h456.5c4.6,0,8.3-3.8,8.3-8.3l3.7-248.4c-0.4-15-13.5-20.3-21.5-20
	l-446.1-12c-4.3-0.1-8.4,1.5-11.4,4.5c-3,3-4.7,7.1-4.6,11.4L116.7,474.2z"/>
                <path class="st0" d="M585,356.1L585,356.1c-0.7,0-1.2-0.6-1.2-1.2l0.3-20.1c0-0.7,0.6-1.2,1.2-1.2h0c0.7,0,1.2,0.6,1.2,1.2
	l-0.3,20.1C586.2,355.6,585.6,356.1,585,356.1z"/>
                <path class="st6" d="M585.7,306.8L585.7,306.8c-0.7,0-1.2-0.6-1.2-1.2l1.1-77.2c0-4.6-1.4-8.2-4.1-10.9c-5.1-4.9-13.2-4.6-13.5-4.6
	l-142.6-3.8c-0.7,0-1.2-0.6-1.2-1.3c0-0.7,0.5-1.2,1.3-1.2l142.5,3.8c0.9-0.1,9.5-0.2,15.2,5.3c3.2,3.1,4.9,7.3,4.9,12.6l-1.1,77.2
	C586.9,306.2,586.4,306.8,585.7,306.8z"/>
                <linearGradient id="SVGID_00000047041426244797060940000010397186046126107804_"
                                gradientUnits="userSpaceOnUse" x1="1558.4431" y1="-231.8056" x2="293.976"
                                y2="164.7745" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#09005D"/>
                    <stop offset="1" style="stop-color:#1A0F91"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000047041426244797060940000010397186046126107804_);" d="M70.5,514.1h559.2c0.6,0,1.1-0.5,1.1-1.1
	v-21.8c0-0.6-0.5-1.1-1.1-1.1H70.5c-0.6,0-1.1,0.5-1.1,1.1V513C69.4,513.6,69.9,514.1,70.5,514.1z"/>
                <path class="st4" d="M227.8,486.5c-4.4,0-8.4-1.5-11.6-4c-4.3-3.4-7.1-8.7-7.1-14.7V196v-10.5V91.3c0-14,9.5-25.8,22.5-29.3
	c-0.9,1.4-1.3,3-1.3,4.7l2.3,394.7c0,3.8,2.9,7,6.6,7.4c15.1,1.6,36.8,2.2,61.7,2.2c77,0,184-6,215.8-7.9c5.2-0.3,9.1-4.7,8.8-9.9
	l-20-375.7c-0.2-4.4-3.8-7.7-8.1-7.7c-0.2,0-0.4,0-0.6,0c-20,1.5-40.9,2.2-61.8,2.2c-66.2,0-132.3-6.4-170.8-11h233.3
	c14.2,0,26,11.2,26.7,25.4l5.5,107.4l0.5,10.8l13,255.9c0.6,11.1-8.2,20.5-19.3,20.7l-74,1.3l-221.8,4
	C228.1,486.5,228,486.5,227.8,486.5z"/>
                <path class="st6" d="M271.4,480.2L271.4,480.2l-33.1-0.4c-11.1-0.1-20.1-9.3-20.1-20.3v-50c0-0.4,0.3-0.7,0.7-0.7
	c0.4,0,0.7,0.3,0.7,0.7v50c0,10.3,8.4,18.8,18.7,18.9l33.1,0.4c0.4,0,0.7,0.3,0.7,0.7C272.1,479.9,271.8,480.2,271.4,480.2z"/>
                <path class="st2" d="M218.9,394.7c-0.4,0-0.7-0.3-0.7-0.7v-8.1c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7v8.1
	C219.7,394.4,219.3,394.7,218.9,394.7z"/>
                <path class="st6" d="M230.3,66.8c0-5.4,4.7-9.5,10-8.8c35.2,4.8,156.4,19.6,256.6,11.9c4.6-0.3,8.5,3.1,8.8,7.7l20,375.7
	c0.3,5.2-3.7,9.6-8.8,9.9c-42.1,2.5-215.6,12.1-277.5,5.6c-3.8-0.4-6.6-3.6-6.6-7.4L230.3,66.8z"/>
                <path class="st5" d="M234.4,76.2c0-5.2,4.5-9.2,9.6-8.5c33.9,4.6,150.5,18.8,246.9,11.4c4.4-0.3,8.2,3,8.4,7.4l19.2,361.6
	c0.3,5-3.5,9.2-8.5,9.5c-40.5,2.4-207.5,11.7-267.1,5.4c-3.6-0.4-6.4-3.4-6.4-7.1L234.4,76.2z"/>
                <path class="st8" d="M326.9,192.4c-23.4,0-47.6-0.6-57.8-0.9c-2.8-0.1-5.1-2.4-5.3-5.3l-4-87.6c-0.2-3.4,2.5-6.2,5.8-6.2
	c0.1,0,0.1,0,0.2,0l93.6,3.4c3.2,0.1,5.9,2.5,6.5,5.7c3.7,20,2.5,67.8,2,84.2c-0.1,3.2-2.6,5.8-5.7,6
	C352.2,192.2,339.7,192.4,326.9,192.4z"/>
                <path class="st0" d="M326.4,188.9c-21.9,0-44.6-0.6-54.1-0.8c-2.7-0.1-4.8-2.2-4.9-4.9l-3.7-80.2c-0.1-3.1,2.3-5.7,5.4-5.7
	c0.1,0,0.1,0,0.2,0l87.6,3.1c3,0.1,5.5,2.3,6.1,5.2c3.4,18.3,2.3,62.1,1.8,77.1c-0.1,3-2.4,5.3-5.4,5.5
	C350.1,188.7,338.4,188.9,326.4,188.9z"/>
                <path class="st8" d="M326.2,186.2c-20.6,0-41.9-0.5-50.9-0.8c-2.5-0.1-4.5-2-4.6-4.5l-3.5-74.7c-0.1-2.9,2.2-5.3,5.1-5.3
	c0.1,0,0.1,0,0.2,0l82.4,2.9c2.8,0.1,5.2,2.1,5.7,4.9c3.2,17,2.2,57.8,1.7,71.8c-0.1,2.8-2.3,5-5.1,5.1
	C348.5,186,337.4,186.2,326.2,186.2z"/>
                <path class="st9" d="M478.1,110.8h-90.8c-1.7,0-3.5-1.3-4-2.9l-0.4-1.3c-0.5-1.5,0.3-2.8,2-2.8c0,0,0,0,0.1,0l93.1,1.7
	c1.7,0,3.1,1.2,3.1,2.7C481.1,109.6,479.8,110.8,478.1,110.8z"/>
                <path class="st9" d="M478.1,125.3h-90.8c-1.7,0-3.5-1.3-4-2.9l-0.4-1.3c-0.5-1.6,0.3-2.8,2-2.8c0,0,0,0,0.1,0L478,120
	c1.7,0,3.1,1.2,3.1,2.7C481.1,124.1,479.8,125.3,478.1,125.3z"/>
                <path class="st9" d="M478.1,139.9h-90.8c-1.7,0-3.5-1.3-4-2.9l-0.4-1.3c-0.5-1.6,0.3-2.8,2-2.8c0,0,0,0,0.1,0l93.1,1.7
	c1.7,0,3.1,1.2,3.1,2.7C481.1,138.7,479.8,139.9,478.1,139.9z"/>
                <path class="st9" d="M487.2,210.6H274.4c-1.7,0-3.9-1.1-4.9-2.4l-1.8-2.2c-1-1.3-0.5-2.3,1.1-2.3c0,0,0,0,0,0l218,1.7
	c1.7,0,3.1,1.2,3.2,2.6C490.1,209.5,488.8,210.6,487.2,210.6z"/>
                <path class="st9" d="M487.2,229.2H274.4c-1.7,0-3.9-1.1-4.9-2.4l-1.8-2.2c-1-1.3-0.5-2.3,1.1-2.3c0,0,0,0,0,0l218,1.7
	c1.7,0,3.1,1.2,3.2,2.6C490.1,228,488.8,229.2,487.2,229.2z"/>
                <path class="st10" d="M274.5,294.4c-0.7,0-1.4,0.4-1.8,0.9c-0.4,0.4-0.5,0.9-0.5,1.5l1.6,15.4c0.2,1.7,1.7,2.9,3.7,2.9l18.4,0.2h0
	c1.8,0,3.3-1.3,3.3-2.8c0.2-7,0.3-13.6-0.6-17c-0.4-1.5-2-2.6-3.8-2.6c-3.4,0-9.4,0.6-14.2,1C278.3,294.1,276.1,294.3,274.5,294.4z
	 M296,316.7L296,316.7l-18.4-0.2c-2.6,0-4.8-1.8-5-4.2l-1.6-15.4c-0.1-0.9,0.2-1.8,0.8-2.5c0.7-0.8,1.7-1.3,2.8-1.4
	c1.6-0.1,3.7-0.3,6-0.5c4.9-0.4,10.9-0.9,14.3-1c2.5,0,4.6,1.5,5.2,3.6c1,3.5,0.9,10.3,0.7,17.4C300.6,314.8,298.5,316.7,296,316.7z
	"/>
                <path class="st9" d="M293.7,313.8C293.7,313.8,293.7,313.8,293.7,313.8l-14.7-0.1c-1.8,0-3.3-1.3-3.5-2.9l-1.3-12.3
	c-0.1-1.3,0.9-2.4,2.4-2.5c3.9-0.3,12.3-1.1,16.3-1.2c0,0,0,0,0.1,0c1.7,0,3.1,1,3.5,2.5c0.8,2.8,0.7,8.5,0.5,13.7
	C296.8,312.5,295.4,313.8,293.7,313.8z"/>
                <path class="st9" d="M316.5,309.8c-2.1,0-3.9-1.4-4.4-3.4c-0.8-2.9,1.4-5.7,4.4-5.7c0,0,0,0,0,0l170.2,0.8c1.9,0,3.4,1.5,3.4,3.4
	c0,1.9-1.5,3.5-3.4,3.5L316.5,309.8C316.6,309.8,316.5,309.8,316.5,309.8z"/>
                <path class="st10" d="M274.5,333.8c-0.7,0-1.4,0.4-1.8,0.9c-0.4,0.4-0.5,0.9-0.5,1.5l1.6,15.4c0.2,1.7,1.7,2.9,3.7,2.9l18.4,0.2h0
	c1.8,0,3.3-1.3,3.3-2.8c0.2-7,0.3-13.6-0.6-17c-0.4-1.5-2-2.6-3.8-2.6c-3.4,0-9.4,0.6-14.2,1C278.3,333.5,276.1,333.7,274.5,333.8z
	 M296,356.1L296,356.1l-18.4-0.2c-2.6,0-4.8-1.8-5-4.2l-1.6-15.4c-0.1-0.9,0.2-1.8,0.8-2.5c0.7-0.8,1.7-1.3,2.8-1.4
	c1.6-0.1,3.7-0.3,6-0.5c4.9-0.4,10.9-0.9,14.3-1c0,0,0.1,0,0.1,0c2.4,0,4.5,1.5,5.1,3.6c1,3.5,0.9,10.3,0.7,17.4
	C300.6,354.2,298.5,356.1,296,356.1z"/>
                <path class="st9" d="M293.7,353.2C293.7,353.2,293.7,353.2,293.7,353.2l-14.7-0.1c-1.8,0-3.3-1.2-3.5-2.9l-1.3-12.3
	c-0.1-1.3,0.9-2.4,2.4-2.5c3.9-0.3,12.3-1.1,16.3-1.2c0,0,0,0,0.1,0c1.7,0,3.1,1,3.5,2.5c0.8,2.8,0.7,8.5,0.5,13.7
	C296.8,352,295.4,353.2,293.7,353.2z"/>
                <path class="st9" d="M316.5,349.3c-2.1,0-3.9-1.4-4.4-3.4c-0.8-2.9,1.4-5.7,4.4-5.7c0,0,0,0,0,0l170.2,0.8c1.9,0,3.4,1.5,3.4,3.4
	c0,1.9-1.5,3.5-3.4,3.5L316.5,349.3C316.6,349.3,316.5,349.3,316.5,349.3z"/>
                <path class="st10" d="M274.5,373.2c-0.7,0.1-1.4,0.4-1.8,0.9c-0.4,0.4-0.5,0.9-0.5,1.5l1.6,15.4c0.2,1.7,1.7,2.9,3.7,2.9l18.4,0.2h0
	c1.8,0,3.3-1.3,3.3-2.8c0.2-7,0.3-13.6-0.6-17c-0.4-1.5-2-2.6-3.8-2.6c-3.4,0-9.4,0.6-14.2,1C278.3,372.9,276.1,373.1,274.5,373.2z
	 M296,395.6L296,395.6l-18.4-0.2c-2.6,0-4.8-1.8-5-4.2l-1.6-15.4c-0.1-0.9,0.2-1.8,0.8-2.5c0.7-0.8,1.7-1.3,2.8-1.4
	c1.6-0.1,3.7-0.3,6-0.5c4.9-0.4,10.9-0.9,14.3-1c0,0,0.1,0,0.1,0c2.4,0,4.5,1.5,5.1,3.6c1,3.5,0.9,10.3,0.7,17.4
	C300.6,393.7,298.5,395.6,296,395.6z"/>
                <path class="st9" d="M293.7,392.7C293.7,392.7,293.7,392.7,293.7,392.7l-14.7-0.1c-1.8,0-3.3-1.2-3.5-2.9l-1.3-12.3
	c-0.1-1.3,0.9-2.4,2.4-2.5c3.9-0.3,12.3-1.1,16.3-1.2c0,0,0,0,0.1,0c1.7,0,3.1,1,3.5,2.5c0.8,2.8,0.7,8.5,0.5,13.7
	C296.8,391.4,295.4,392.7,293.7,392.7z"/>
                <path class="st9" d="M316.5,388.7c-2.1,0-3.9-1.4-4.4-3.4c-0.8-2.9,1.4-5.7,4.4-5.7c0,0,0,0,0,0l170.2,0.8c1.9,0,3.4,1.5,3.4,3.4
	c0,1.9-1.5,3.5-3.4,3.5L316.5,388.7C316.6,388.7,316.5,388.7,316.5,388.7z"/>
                <path class="st10" d="M274.5,412.7c-0.7,0.1-1.4,0.4-1.8,0.9c-0.4,0.4-0.5,0.9-0.5,1.5l1.6,15.4c0.2,1.7,1.7,2.9,3.7,2.9l18.4,0.2h0
	c1.8,0,3.3-1.3,3.3-2.8c0.2-7,0.3-13.6-0.6-17c-0.4-1.5-2-2.6-3.8-2.6c-3.4,0-9.4,0.6-14.2,1C278.3,412.4,276.1,412.6,274.5,412.7z
	 M296,435L296,435l-18.4-0.2c-2.6,0-4.8-1.8-5-4.2l-1.6-15.4c-0.1-0.9,0.2-1.8,0.8-2.5c0.7-0.8,1.7-1.3,2.8-1.4
	c1.6-0.1,3.7-0.3,6-0.5c4.9-0.4,10.9-0.9,14.3-1c0,0,0.1,0,0.1,0c2.4,0,4.5,1.5,5.1,3.6c1,3.5,0.9,10.3,0.7,17.4
	C300.6,433.1,298.5,435,296,435z"/>
                <path class="st9" d="M293.7,432.1C293.7,432.1,293.7,432.1,293.7,432.1l-14.7-0.1c-1.8,0-3.3-1.2-3.5-2.9l-1.3-12.3
	c-0.1-1.3,0.9-2.4,2.4-2.5c3.9-0.3,12.3-1.1,16.3-1.2c0,0,0,0,0.1,0c1.7,0,3.1,1,3.5,2.5c0.8,2.8,0.7,8.5,0.5,13.7
	C296.8,430.9,295.4,432.1,293.7,432.1z"/>
                <linearGradient id="SVGID_00000093866236050160868490000015941370542371765648_"
                                gradientUnits="userSpaceOnUse" x1="1694.1154" y1="-2957.2441" x2="551.4653"
                                y2="-358.6382" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#FF928E"/>
                    <stop offset="1" style="stop-color:#FE7062"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000093866236050160868490000015941370542371765648_);" d="M277.7,414.6c1.6-0.9,3.6-0.4,4.4,1.2
	l4.1,7.4c0.4,0.7,1.5,0.7,1.8-0.1l10.2-22.8c0.5-1.1,2.2-0.8,2.2,0.5c-0.1,7-2,20.2-13.1,31.4c0,0-6.1-2.4-11.1-13.3
	C275.6,417.3,276.2,415.5,277.7,414.6z"/>
                <linearGradient id="SVGID_00000062161914531979999950000015694233751884978311_"
                                gradientUnits="userSpaceOnUse" x1="1709.6716" y1="-2950.4038" x2="567.0216"
                                y2="-351.7979" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#FF928E"/>
                    <stop offset="1" style="stop-color:#FE7062"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000062161914531979999950000015694233751884978311_);" d="M277.8,372.7c1.6-0.9,3.6-0.4,4.4,1.2
	l4.1,7.4c0.4,0.7,1.5,0.7,1.8-0.1l10.2-22.8c0.5-1.1,2.2-0.8,2.2,0.5c-0.1,7-2,20.2-13.1,31.4c0,0-6.1-2.4-11.1-13.3
	C275.8,375.5,276.3,373.6,277.8,372.7z"/>
                <linearGradient id="SVGID_00000125581684235605708390000003075850460301431950_"
                                gradientUnits="userSpaceOnUse" x1="1723.0464" y1="-2944.5227" x2="580.3964"
                                y2="-345.9167" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#FF928E"/>
                    <stop offset="1" style="stop-color:#FE7062"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000125581684235605708390000003075850460301431950_);" d="M276.3,333c1.6-0.9,3.6-0.4,4.4,1.2
	l4.1,7.4c0.4,0.7,1.5,0.7,1.8-0.1l10.2-22.8c0.5-1.1,2.2-0.8,2.2,0.5c-0.1,7-2,20.2-13.1,31.4c0,0-6.1-2.4-11.1-13.3
	C274.2,335.7,274.8,333.8,276.3,333z"/>
                <linearGradient id="SVGID_00000121262280836905654460000014642946285445199540_"
                                gradientUnits="userSpaceOnUse" x1="1734.7035" y1="-2939.397" x2="592.0535"
                                y2="-340.7909" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#FF928E"/>
                    <stop offset="1" style="stop-color:#FE7062"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000121262280836905654460000014642946285445199540_);" d="M273.3,294.4c1.6-0.9,3.6-0.4,4.4,1.2
	l4.1,7.4c0.4,0.7,1.5,0.7,1.8-0.1l10.2-22.8c0.5-1.1,2.2-0.8,2.2,0.5c-0.1,7-2,20.2-13.1,31.4c0,0-6.1-2.4-11.1-13.3
	C271.2,297.2,271.8,295.3,273.3,294.4z"/>
                <path class="st9" d="M292.1,279.5C292,279.5,292,279.5,292.1,279.5l-14.7-0.1c-1.8,0-3.3-1.2-3.5-2.9l-1.3-12.3
	c-0.1-1.3,0.9-2.4,2.4-2.5c3.9-0.3,12.3-1.1,16.2-1.2c0,0,0,0,0.1,0c1.7,0,3.1,1,3.5,2.5c0.8,2.8,0.7,8.5,0.5,13.7
	C295.2,278.2,293.8,279.5,292.1,279.5z"/>
                <path class="st10" d="M272.9,260c-0.7,0-1.4,0.4-1.8,0.9c-0.4,0.4-0.5,0.9-0.5,1.5l1.6,15.4c0.2,1.7,1.7,2.9,3.7,2.9l18.4,0.2h0
	c1.8,0,3.3-1.3,3.3-2.8c0.2-7,0.3-13.6-0.6-17c-0.4-1.5-2-2.6-3.8-2.6c-3.4,0-9.4,0.6-14.2,1C276.7,259.8,274.5,259.9,272.9,260z
	 M294.3,282.4L294.3,282.4l-18.5-0.2c-2.6,0-4.8-1.8-5-4.2l-1.6-15.4c-0.1-0.9,0.2-1.8,0.8-2.5c0.7-0.8,1.7-1.3,2.8-1.4
	c1.5-0.1,3.7-0.3,6-0.5c4.9-0.4,10.9-0.9,14.3-1c0,0,0,0,0.1,0c2.4,0,4.5,1.5,5.1,3.6c1,3.5,0.9,10.3,0.7,17.4
	C299,280.5,296.9,282.4,294.3,282.4z"/>
                <linearGradient id="SVGID_00000114033488279442587850000009561850126575081654_"
                                gradientUnits="userSpaceOnUse" x1="1750.1598" y1="-2932.6003" x2="607.5098"
                                y2="-333.9945" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#FF928E"/>
                    <stop offset="1" style="stop-color:#FE7062"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000114033488279442587850000009561850126575081654_);" d="M276.3,259.4c1.6-0.9,3.6-0.4,4.4,1.2
	l4.1,7.4c0.4,0.7,1.5,0.7,1.8-0.1l10.2-22.8c0.5-1.1,2.2-0.8,2.2,0.5c-0.1,7-2,20.2-13.1,31.4c0,0-6.1-2.4-11.1-13.3
	C274.2,262.1,274.8,260.3,276.3,259.4z"/>
                <linearGradient id="SVGID_00000176023853492212673030000007843921848243757993_"
                                gradientUnits="userSpaceOnUse" x1="1829.0332" y1="-2897.9185" x2="686.3832"
                                y2="-299.3126" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#FF928E"/>
                    <stop offset="1" style="stop-color:#FE7062"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000176023853492212673030000007843921848243757993_);" d="M290.6,132.8c4.5-2.6,10.2-1.1,12.8,3.5
	l11.9,21.3c1.2,2.1,4.3,2,5.3-0.2L350,91.7c1.5-3.3,6.3-2.2,6.3,1.4c-0.3,20.1-5.6,58.3-37.7,90.2c0,0-17.7-6.9-31.9-38.2
	C284.6,140.7,286.3,135.3,290.6,132.8z"/>
                <path class="st9" d="M316.5,428.1c-2.1,0-3.9-1.4-4.4-3.4c-0.8-2.9,1.4-5.7,4.4-5.7c0,0,0,0,0,0l170.2,0.8c1.9,0,3.4,1.5,3.4,3.4
	c0,1.9-1.5,3.5-3.4,3.5L316.5,428.1C316.6,428.1,316.5,428.1,316.5,428.1z"/>
                <path class="st9" d="M314.9,275.5c-2.1,0-3.9-1.4-4.4-3.4c-0.8-2.9,1.4-5.7,4.4-5.7c0,0,0,0,0,0l170.2,0.8c1.9,0,3.4,1.5,3.4,3.4
	c0,1.9-1.5,3.5-3.4,3.5L314.9,275.5C314.9,275.5,314.9,275.5,314.9,275.5z"/>
                <path class="st17" d="M371.6,31.7c-4,0-7.3-3.3-7.3-7.3s3.3-7.3,7.3-7.3c4,0,7.3,3.3,7.3,7.3S375.6,31.7,371.6,31.7z M384.5,10
	c-9.3-6.5-17.4-6.4-23.1-4.7c-5.2,1.5-8.6,6.6-8.1,12l2.5,26.9l29.3,2.6C385,46.7,402.8,22.7,384.5,10z"/>
                <path class="st2" d="M357.9,24.5c-0.4,0-0.7-0.3-0.7-0.6l-0.3-3.2c-0.5-5.1,2.8-9.9,7.7-11.4c3.5-1,7.1-1.2,10.7-0.4
	c0.4,0.1,0.6,0.5,0.5,0.8c-0.1,0.4-0.4,0.6-0.8,0.6c-3.4-0.7-6.7-0.6-10,0.4c-4.3,1.3-7.1,5.4-6.7,9.9l0.3,3.2
	C358.6,24.1,358.3,24.4,357.9,24.5C357.9,24.5,357.9,24.5,357.9,24.5z M358.7,32.8c-0.4,0-0.7-0.3-0.7-0.6l-0.4-4.3
	c0-0.4,0.2-0.7,0.6-0.8c0.4,0,0.7,0.3,0.8,0.6l0.4,4.3C359.4,32.5,359.1,32.8,358.7,32.8C358.7,32.8,358.7,32.8,358.7,32.8z"/>
                <path class="st17"
                      d="M315.8,61.5V48c0-4.7,3.9-8.4,8.5-8.2l93.1,3.2c4.1,0.1,7.5,3.4,7.7,7.5l0.8,13L315.8,61.5z"/>
                <path class="st2"
                      d="M350.5,44.8h-4c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h4c0.4,0,0.7,0.3,0.7,0.7S350.9,44.8,350.5,44.8z"/>
                <path class="st2" d="M320.8,57.3c-0.4,0-0.7-0.3-0.7-0.7v-5.5c0-4.2,3.4-7.7,7.7-7.7h14.7c0.4,0,0.7,0.3,0.7,0.7s-0.3,0.7-0.7,0.7
	h-14.7c-3.4,0-6.2,2.8-6.2,6.2v5.5C321.5,56.9,321.2,57.3,320.8,57.3z"/>
                <path class="st4" d="M277.9,64.9c0.2-4.7,4.1-8.4,8.8-8.4l162.8,0c5.9,0,10.9,4.2,11.9,10l0.1,0.8c1.1,6.3-3.8,12.1-10.2,12.1h-167
	c-3.8,0-6.9-3.2-6.7-7L277.9,64.9z"/>
                <linearGradient id="SVGID_00000049920911179833042830000002565230081438321316_"
                                gradientUnits="userSpaceOnUse" x1="680.8888" y1="-151.8579" x2="-2453.804"
                                y2="1431.1013" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#09005D"/>
                    <stop offset="1" style="stop-color:#1A0F91"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000049920911179833042830000002565230081438321316_);" d="M195,549.4c0,0-1.1,6-1.6,12
	c-0.2,2.3,1.6,4.4,3.9,4.5c8,0.3,25,1,31.1,0.7c0.9,0,1.5-1,1.2-1.8c-0.3-0.7-0.8-1.3-1.5-1.6l-28.1-12.9L195,549.4z"/>
                <linearGradient id="SVGID_00000044139793073701025380000006348616403992489405_"
                                gradientUnits="userSpaceOnUse" x1="0.2639" y1="99.9061" x2="635.877"
                                y2="99.9061" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#FEBBBA"/>
                    <stop offset="1" style="stop-color:#FF928E"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000044139793073701025380000006348616403992489405_);" d="M194.1,545.8l0.9,7.2c0,0,4.9,2.3,8.3-0.3
	l-2-8.1L194.1,545.8z"/>
                <linearGradient id="SVGID_00000117634925123562150290000001133703024388342935_"
                                gradientUnits="userSpaceOnUse" x1="245.6074" y1="-159.6783" x2="107.9173"
                                y2="677.1776" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#4042E2"/>
                    <stop offset="1" style="stop-color:#4F52FF"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000117634925123562150290000001133703024388342935_);" d="M194.7,410c-0.4,1.9-2,94.1,7.9,135.5
	c0.2,0.7-0.2,1.4-0.8,1.6c-1.5,0.6-4.5,1.5-8,1.6c-1.1,0-2.1-0.7-2.4-1.7c-2.8-9.6-16.3-57.7-18.1-103.6c-2-51.3,0-1.7,0-1.7
	l1.2-31.7L194.7,410z"/>
                <linearGradient id="SVGID_00000147204838995899908440000003880317902644813238_"
                                gradientUnits="userSpaceOnUse" x1="1769.059" y1="-1622.9362" x2="-2782.4111"
                                y2="3235.3638" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#09005D"/>
                    <stop offset="1" style="stop-color:#1A0F91"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000147204838995899908440000003880317902644813238_);" d="M162.6,552.5c0,0-1.7,3.1-3.3,11.2
	c-0.3,1.5,0.7,2.9,2.2,3c2.6,0.3,6.7,0.6,9.9,0.6c1.5,0,2.7-1.3,2.5-2.8c-0.4-3-1.5-7.8-4.9-11.6L162.6,552.5z"/>
                <linearGradient id="SVGID_00000098933387486325907770000011146462040627819661_"
                                gradientUnits="userSpaceOnUse" x1="0.2639" y1="100.3748" x2="635.862"
                                y2="100.3748" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#FEBBBA"/>
                    <stop offset="1" style="stop-color:#FF928E"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000098933387486325907770000011146462040627819661_);" d="M161.1,543.3l2,10.9c0,0,2,1.5,5.9,0.4
	l-1-11.8L161.1,543.3z"/>
                <linearGradient id="SVGID_00000127749354242976262890000014425912847656555939_"
                                gradientUnits="userSpaceOnUse" x1="261.8643" y1="-193.1555" x2="77.9973"
                                y2="608.5925" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#4042E2"/>
                    <stop offset="1" style="stop-color:#4F52FF"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000127749354242976262890000014425912847656555939_);" d="M155.7,412.9
	c-8.1,48.7,1.7,120.3,5.1,134.3c0.2,0.9,0.9,1.5,1.8,1.7c1.5,0.2,3.9,0.4,6.3-0.3c0.8-0.2,1.4-1,1.4-1.8
	c0.4-11.3-0.4-80.9,7.2-108.4c0.4-1.4,1.2-2.6,2.4-3.4l14.6-10.4l1.1-16.8L155.7,412.9z"/>
                <linearGradient id="SVGID_00000167360905859132522780000004386612325139321017_"
                                gradientUnits="userSpaceOnUse" x1="431.7234" y1="9.5434" x2="-433.7916"
                                y2="999.5744" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#FE7062"/>
                    <stop offset="1" style="stop-color:#FF928E"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000167360905859132522780000004386612325139321017_);" d="M207.1,320.6c-24.2-0.4-50.8,6.3-50.8,6.3
	c-7.2,42-1.3,90.5-1.3,90.5c18.7,4.9,39.8-3,39.8-3c3.1-17.6,17.6-67,17.6-67c11.8-1.5,21.5-4.9,29.5-9.4c-6.1-7.1-9-16.1-10.2-21.4
	C223.2,319.3,214.6,320.8,207.1,320.6z"/>
                <linearGradient id="SVGID_00000120556762096632548140000002352069270030065553_"
                                gradientUnits="userSpaceOnUse" x1="465.8468" y1="-343.2855" x2="294.9319"
                                y2="217.5645" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#FEBBBA"/>
                    <stop offset="1" style="stop-color:#FF928E"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000120556762096632548140000002352069270030065553_);" d="M278.5,285c-2.4-2.9-5.4-1.5-5.4-1.5
	c-3.6,15-22.2,27.2-41.4,33.2c1.3,5.3,4.1,14.3,10.2,21.4C273.8,320.2,278.5,285,278.5,285z"/>
                <linearGradient id="SVGID_00000005953017184672609030000005917244753162939802_"
                                gradientUnits="userSpaceOnUse" x1="360.6436" y1="-1.6529" x2="106.9595"
                                y2="1205.215" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#FEBBBA"/>
                    <stop offset="1" style="stop-color:#FF928E"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000005953017184672609030000005917244753162939802_);" d="M277.9,285.8c0.2,0.8-2.6,2-4.3-1.2
	c-0.1-1.5-1-8.2-0.1-9.4c4.9-6.7,14.5-15.1,14.5-15.1c1.6,1.6,0.3,3.2,0.3,3.2l-4.6,4.8c1,1.8,2.2,3.2,2.7,5.3
	c0.4,1.6,0.2,3.3-0.6,4.8c-0.8,1.5-1.9,3.4-3,4.9C280.8,285.7,277.9,285.8,277.9,285.8z"/>
                <linearGradient id="SVGID_00000044876036445918964260000007113594450407280561_"
                                gradientUnits="userSpaceOnUse" x1="33.7278" y1="1220.0577" x2="503.5298"
                                y2="-398.2342" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#FEBBBA"/>
                    <stop offset="1" style="stop-color:#FF928E"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000044876036445918964260000007113594450407280561_);" d="M281.6,271.1l-4.2,3.6l-1.3,3.6l3.8,4.2
	c0,0,1.3-7.1,2.2-8.6C283,272.3,282.5,271.5,281.6,271.1z"/>
                <linearGradient id="SVGID_00000014636379179105799830000006052160027663100049_"
                                gradientUnits="userSpaceOnUse" x1="959.7937" y1="-612.2773" x2="-1258.9563"
                                y2="1879.5227" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#FE7062"/>
                    <stop offset="1" style="stop-color:#FF928E"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000014636379179105799830000006052160027663100049_);" d="M165.5,350.4l-9.3-23.4
	c0,0-22.6,15.3-26.3,34.8c4.3,0.3,12,1.4,19.6,5.3C151.3,357.9,165.5,350.4,165.5,350.4z"/>
                <linearGradient id="SVGID_00000127746487485140508690000003223673282029483453_"
                                gradientUnits="userSpaceOnUse" x1="0.2642" y1="272.4018" x2="635.846"
                                y2="272.4018" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#FEBBBA"/>
                    <stop offset="1" style="stop-color:#FF928E"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000127746487485140508690000003223673282029483453_);" d="M185.3,372.4c0,0-25.3,14.3-33.9,2.1
	c-1.8-2.5-2.2-5.1-1.8-7.5c-7.6-3.8-15.4-4.9-19.6-5.3c-1.1,5.9-0.5,12.1,2.9,18.4c15,27.4,55.7-1.5,55.7-1.5L185.3,372.4z"/>
                <linearGradient id="SVGID_00000145044586491199543230000008280474554406201522_"
                                gradientUnits="userSpaceOnUse" x1="151.1024" y1="64.3359" x2="603.434"
                                y2="1646.9668" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#09005D"/>
                    <stop offset="1" style="stop-color:#1A0F91"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000145044586491199543230000008280474554406201522_);" d="M182.2,390.5l13.8-48.3
	c0.2-0.5,0.6-0.9,1.2-1c2.5-0.3,9.8-1.1,18.2-1.8c10.1-0.9,21.8-1.5,28.8-1c1,0.1,1.6,1.1,1.2,2c-4,8.1-20.4,40.9-28.6,51.6
	c-0.3,0.4-0.7,0.6-1.1,0.6l-32.1-0.3C182.6,392.3,182,391.4,182.2,390.5z"/>
                <path class="st2" d="M216.8,335.2l10-1.7c0.5-0.1,1,0.4,0.8,0.9l-1,3c-0.1,0.3-0.3,0.5-0.6,0.5l-9.7,1.3c-0.5,0.1-0.9-0.4-0.8-0.9
	l0.6-2.6C216.3,335.5,216.5,335.2,216.8,335.2z"/>
                <path class="st2" d="M209.3,343.7l0.8-4.3c0.1-0.4,0.4-0.8,0.9-0.8l20.3-2.1c0.7-0.1,1.2,0.6,1.1,1.2l-1.3,5.4
	c-0.1,0.4-0.5,0.7-0.9,0.8l-19.8,1C209.7,344.9,209.2,344.3,209.3,343.7z"/>
                <linearGradient id="SVGID_00000000203971186021704220000004732099445108206469_"
                                gradientUnits="userSpaceOnUse" x1="0.2124" y1="278.6038" x2="635.821"
                                y2="278.6038" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#FEBBBA"/>
                    <stop offset="1" style="stop-color:#FF928E"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000000203971186021704220000004732099445108206469_);" d="M185.6,378.9c0,0-1.9-7.9-1.1-10.7
	c0.8-2.8,2.3-6.4,7.3-5.6c5,0.8,10.9,2.9,10.9,2.9s-0.3,3.7-4.1,2.5l2.4,1.8c0,0-0.3,2.9-3.6,1.4l2.2,2.4c0,0-1.4,2.4-3.8,0.9
	C193.4,372.8,192.3,379.8,185.6,378.9z"/>
                <linearGradient id="SVGID_00000054260966753168875850000003341405260512366259_"
                                gradientUnits="userSpaceOnUse" x1="0.2663" y1="335.6429" x2="635.853"
                                y2="335.6429" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#FEBBBA"/>
                    <stop offset="1" style="stop-color:#FF928E"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000054260966753168875850000003341405260512366259_);" d="M182.6,301l-7.7,22.2c-0.1,0.4,0,0.8,0.4,1
	c2.1,1.2,9.7,4.5,18.8-1.3c0.9-0.6,1.6-1.6,1.7-2.7l2.5-19.2H182.6z"/>
                <linearGradient id="SVGID_00000120560944152250878280000010900446617328398001_"
                                gradientUnits="userSpaceOnUse" x1="506.362" y1="539.6639" x2="-103.8026"
                                y2="177.3089" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#FEBBBA"/>
                    <stop offset="1" style="stop-color:#FF928E"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000120560944152250878280000010900446617328398001_);" d="M199.4,280.4c0,0,8.4,15.9,7.4,22.3
	c-1.1,6.4-11.5,6-20.7,0.7l-6.4-17.2L199.4,280.4z"/>
                <linearGradient id="SVGID_00000004505231432754188850000014597502386720543118_"
                                gradientUnits="userSpaceOnUse" x1="-167.287" y1="92.4385" x2="438.7186"
                                y2="556.7435" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#09005D"/>
                    <stop offset="1" style="stop-color:#1A0F91"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000004505231432754188850000014597502386720543118_);" d="M186.4,295.1c0.4-0.2,0.5-0.6,0.3-1
	c-0.7-1-1.9-3.1-2.4-5c0,0-1.5-1,3.9-2.3c5.4-1.3,13.4-3.3,15.1-7.9c1.6-4.5-2.7-15.7-9.6-6.7c-0.3,0.3-0.8,0.3-1,0
	c-1.1-1.5-4.1-4.5-8.2-2.7c-3.9,1.7-4.3,4.6-4.2,6c0,0.4-0.4,0.7-0.8,0.7c-2-0.4-6.6-0.7-8.8,4.1c-1.8,4-0.6,6.2,0.4,7.3
	c0.3,0.4,0.2,0.9-0.3,1.1c-1.3,0.4-2.9,1.5-2.8,4.5c0.2,4.2,4.9,3.8,6.2,3.6c0.2,0,0.4,0,0.6,0.2c1.1,1.3,6.2,6.6,11.8,7.2
	c0.5,0.1,0.8-0.4,0.7-0.9l-2.4-7c-0.1-0.3,0-0.7,0.4-0.8L186.4,295.1z"/>
                <linearGradient id="SVGID_00000047779063284821060890000015585862627640662701_"
                                gradientUnits="userSpaceOnUse" x1="1391.7258" y1="834.1706" x2="-695.5641"
                                y2="0.9089" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#FEBBBA"/>
                    <stop offset="1" style="stop-color:#FF928E"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000047779063284821060890000015585862627640662701_);" d="M187.4,296c0,0-6-4.1-6.5-0.7
	c-0.4,3.5,5.4,5,8.3,3.7L187.4,296z"/>
                <linearGradient id="SVGID_00000174584705682144906000000011343018421249431961_"
                                gradientUnits="userSpaceOnUse" x1="126.8713" y1="-317.9491" x2="637.8663"
                                y2="577.8662" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#FEBBBA"/>
                    <stop offset="1" style="stop-color:#FF928E"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000174584705682144906000000011343018421249431961_);" d="M473.5,291.4c0.4-0.8,1.5-0.6,1.7,0.2
	c0.8,3,2.9,8.4,7.2,10.2c0.2,0.1,0.4,0.2,0.6,0.3c0.2-0.6,0.2-1.3-0.3-2c-1.3-2,1.1-4.8,1.1-4.8s0.2,2.5,2,4
	c1.6,1.3,2.4,4.3,1.3,6.7c-2.3,9-5.8,21,7.8,29.7c13.6,8.7,32.7-16.5,32.7-16.5l-9.5,30.1c0,0-14.6,10.8-31.7-4.4
	c-17-15.2-5.7-37.3-5.7-37.3S467.8,302.4,473.5,291.4z"/>
                <linearGradient id="SVGID_00000173130876456893358940000018125661226740580256_"
                                gradientUnits="userSpaceOnUse" x1="893.9622" y1="85.2269" x2="435.5072"
                                y2="435.4559" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#09005D"/>
                    <stop offset="1" style="stop-color:#1A0F91"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000173130876456893358940000018125661226740580256_);" d="M530,275.1c0,0-3.9-17.6,16.3-13
	c0,0,9.8-1.6,10.3,7.7c0,0,6.3,4.2,1.7,12.2c0,0,18.8-7.3,27,9.2c8.2,16.5,0.7,25.5-7.2,27.9c0,0,6.4,3.2,3.4,10.5
	c-3,7.2-18.3,12.4-27.4,3.5c0,0-25.7,3.4-25.2-14.8c0,0-12-7.7,0.6-22.8C542,280.5,530,275.1,530,275.1z"/>
                <linearGradient id="SVGID_00000083796502946791321260000012974711885339261058_"
                                gradientUnits="userSpaceOnUse" x1="486.7465" y1="345.7662" x2="1686.6544"
                                y2="-807.0117" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#FE7062"/>
                    <stop offset="1" style="stop-color:#FF928E"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000083796502946791321260000012974711885339261058_);" d="M528.9,316c4.1-1.2,15.9-3.5,27.1,6.9
	c1.9,1.8,2.7,4.4,2,6.9c-3,11.1-11,44-4,58.2c0,0-12.3,13.6-25.7,2.6c0,0-6-26.3-13.9-37.8c-5.4-8,8.7-29.9,12.3-35.2
	C527.2,316.8,528,316.3,528.9,316z"/>
                <linearGradient id="SVGID_00000005268294257288897270000017269768215824863389_"
                                gradientUnits="userSpaceOnUse" x1="375.8072" y1="957.7369" x2="536.9628"
                                y2="345.2849" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#FEBBBA"/>
                    <stop offset="1" style="stop-color:#FF928E"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000005268294257288897270000017269768215824863389_);" d="M540.1,293.9c0,0,6,17,6.5,24.5
	c0,0-6.6,3-12.9-2.1c0,0-0.7-17-1.6-20L540.1,293.9z"/>
                <linearGradient id="SVGID_00000019649993336382532510000001467882978274838704_"
                                gradientUnits="userSpaceOnUse" x1="398.1353" y1="15.5578" x2="775.7313"
                                y2="970.4747" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#FEBBBA"/>
                    <stop offset="1" style="stop-color:#FF928E"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000019649993336382532510000001467882978274838704_);" d="M531.8,270.7c0,0-15.7,22-6.3,28.4
	c9.4,6.4,20.6-9.2,20.6-9.2s3.6-12-0.2-16.1C542.1,269.7,531.8,270.7,531.8,270.7z"/>
                <linearGradient id="SVGID_00000009577764300681410830000000912662164727016841_"
                                gradientUnits="userSpaceOnUse" x1="1899.8523" y1="-663.3303" x2="492.0963"
                                y2="413.2149" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#09005D"/>
                    <stop offset="1" style="stop-color:#1A0F91"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000009577764300681410830000000912662164727016841_);" d="M539.1,265.5c0,0-7.2,1.7-8.2,5
	c-1,3.3,10.2,6.3,13.4,6.4l2.5,13.2c0,0,7.5-12.4,4.5-19.5C548.3,263.4,539.1,265.5,539.1,265.5z"/>
                <linearGradient id="SVGID_00000034803379118028316130000009487653410813139380_"
                                gradientUnits="userSpaceOnUse" x1="-217.463" y1="-1094.3735" x2="1470.1061"
                                y2="2116.3162" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#FEBBBA"/>
                    <stop offset="1" style="stop-color:#FF928E"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000034803379118028316130000009487653410813139380_);" d="M543,288.2c0,0,6.1-7.1,8.5-2.5
	c2.4,4.6-5.5,8.1-8.2,5.8L543,288.2z"/>
                <linearGradient id="SVGID_00000048485016917456185730000010561993782070276224_"
                                gradientUnits="userSpaceOnUse" x1="728.7169" y1="889.0397" x2="492.0419"
                                y2="-19.9594" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#FEBBBA"/>
                    <stop offset="1" style="stop-color:#FF928E"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000048485016917456185730000010561993782070276224_);" d="M512.6,445.8c0,1.2-2,10.6-3.2,15.7
	c-0.5,2-0.1,4.2,0.9,6l39.5,66.4c0.5,0.8,0.8,1.7,1,2.6c0.7,3.1,2.2,10.8,1.4,17.7l-2,4.2l8.8-2l1.9-23.7l-2.5-1.5
	c-2.2-1.3-3.8-3.3-4.8-5.6c-3.6-8.7-12.4-30.7-14.7-41.5c-2.6-12.2-16.2-22.1-16.2-22.1l2.4-5.6L512.6,445.8z"/>
                <linearGradient id="SVGID_00000103986585316706578350000017726728937765122206_"
                                gradientUnits="userSpaceOnUse" x1="639.4133" y1="104.5832" x2="6.9995"
                                y2="96.3173" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#09005D"/>
                    <stop offset="1" style="stop-color:#1A0F91"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000103986585316706578350000017726728937765122206_);" d="M568.9,548.2c0,0-4.8-7.6-5.3-10.5
	c-0.6-2.9-2.1-8.1-5.1-6.6c0,0-1.4,14.3-0.9,19.9c0.5,5.6-6.3,5.4-6.3,5.4s-1.9,0.3-3.2,1.6c-0.9,0.9-0.3,2.6,1.1,2.7
	c2.2,0.1,5.8,0.2,9.5-0.5c1.3-0.2,2.3-1.4,2.3-2.7l1-16.4l6,7.6L568.9,548.2z"/>
                <linearGradient id="SVGID_00000035493650493022158810000005690666462438126774_"
                                gradientUnits="userSpaceOnUse" x1="600.9095" y1="26.2596" x2="1051.5745"
                                y2="-812.5815" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#FEBBBA"/>
                    <stop offset="1" style="stop-color:#FF928E"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000035493650493022158810000005690666462438126774_);" d="M536.2,456.3c0,0,1.4,59.4,1.6,67.8
	c0.2,8.4-1.3,27.1-9.8,32.7l3.7,2.7c0,0,4.6-3.9,9.1-11.4c5-8.5,6.6-10,6.6-10l-1.7-3.4c-0.7-1.5-0.9-3.2-0.6-4.8
	c1.8-8.8,7.9-38.3,9.6-44.6c2-7.5,2.1-17.5-4.7-25.8C543.1,451.1,536.2,456.3,536.2,456.3z"/>
                <linearGradient id="SVGID_00000085932231264895826750000005759438318085210754_"
                                gradientUnits="userSpaceOnUse" x1="635.8295" y1="100.601" x2="5.001"
                                y2="94.723"
                                gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#09005D"/>
                    <stop offset="1" style="stop-color:#1A0F91"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000085932231264895826750000005759438318085210754_);" d="M544,562.3c0.3,0,0.5-0.2,0.5-0.4
	c0.3-2,1.5-9.9,2.4-12c1.1-2.4,4.7-12.3,0-13.1c0,0-11.7,17-13.9,19.3c-2.2,2.3-5.1,0.8-5.1,0.8s-2.2,0.6-3.8,2.7
	c-0.8,1-0.1,2.5,1.2,2.5l7.8,0.1c2.3,0,4.3-1.4,5.2-3.5c1.4-3.5,3.8-8.5,6.5-10.8l-1.4,13.8C543.5,562,543.7,562.3,544,562.3z"/>
                <linearGradient id="SVGID_00000119094504260226703070000017627682907753535887_"
                                gradientUnits="userSpaceOnUse" x1="436.0148" y1="-117.0968" x2="780.6324"
                                y2="1002.7221" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#09005D"/>
                    <stop offset="1" style="stop-color:#1A0F91"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000119094504260226703070000017627682907753535887_);" d="M526.4,384.2c0,0,1.8,5.6-0.9,14.9
	L508.6,458c-0.3,0.9,0.2,1.9,1.1,2.2c5,1.8,21.6,6.9,44.2,5.4c1.1-0.1,2.1-0.9,2.3-2c1.5-6.7,6.6-29.6,7.6-34.3
	c4-17.9,8.4-37.3-11.2-48.9C543.8,384.2,535.4,386.5,526.4,384.2z"/>
                <linearGradient id="SVGID_00000147214219480005750220000008653587211034237831_"
                                gradientUnits="userSpaceOnUse" x1="716.4116" y1="641.7238" x2="-121.6095"
                                y2="-1254.5828" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#FEBBBA"/>
                    <stop offset="1" style="stop-color:#FF928E"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000147214219480005750220000008653587211034237831_);" d="M557.6,325.1c0,0,19.2,15.9,23.9,37.7
	c4.8,21.8-28,24.1-28,24.1s-4.5-5-9.7-3.2s-0.4-8.7,5.8-8.6c6.2,0,3.3,4.9,12.1,1c8.5-3.9,13.8-15.8-8-33.9c-1.3-1-2.3-2.4-2.9-3.9
	C546.6,327.6,550,320.6,557.6,325.1z"/>
                <path class="st6" d="M443.4,531.2l24.3,45.9l20.6-36.2L443.4,531.2z"/>
                <path class="st4" d="M635.9,395.7l-66.4,174.3c-1.3,3.3-3.8,6-7.1,7.4c-3.3,1.4-7,1.4-10.3,0l-172.5-73.3c-2-0.8-3.2-2.8-3.2-4.9
	c0-2.1,1.4-4.1,3.4-4.8L635.9,395.7z"/>
                <path class="st6" d="M443.4,531.2l192.4-135.5L471.2,543.1l6.2,13L443.4,531.2z"/>
                <linearGradient id="SVGID_00000067954880432926858670000009855140895959762081_"
                                gradientUnits="userSpaceOnUse" x1="605.6502" y1="267.0215" x2="494.4752"
                                y2="121.8395" gradientTransform="matrix(1 0 0 -1 0 649.2756)">
    <stop offset="0" style="stop-color:#09005D"/>
                    <stop offset="1" style="stop-color:#1A0F91"/>
</linearGradient>
                <path style="fill:url(#SVGID_00000067954880432926858670000009855140895959762081_);"
                      d="M487.8,550l-20.1,27l3.5-34L487.8,550z"/>
</svg>
        </div>
    </div>
</div>