import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CollectionFormFieldSingleLookupData } from '../../../../../models/ts/collection-form-field-single-lookup-data.model';
import { CollectionFormFieldInputValue } from '../../../../../models/ts/collection-form-field-input-value.model';
import { CollectionFormField } from '../../../../../models/ts/collection-form-field.model';
import { CollectionForm } from '../../../../../models/ts/collection-form.model';
import { CollectionFormFieldGridLookupData } from '../../../../../models/ts/collection-form-field-grid-lookup-data.model';
import { CollectionFormService } from './collection-form.service';
import { formsActions } from '../../../../store/features/forms/forms-actions';
import { UpdateDeleteState } from '../../../../../models/ts/update-delete-state.model';
import { LookupService } from '../../../../shared/services/lookup/lookup.service';
import {
  selectForm,
  selectFormFieldByviewDataSourceId,
  selectFormFieldsByProtectedFieldType
} from '../../../../store/features/forms/forms-selectors';
import { ReadOnlyPriority } from '../enums/read-only-priority.enum';
import { ProtectedCollectionType } from '../../../../../models/ts/protected-collection-type.model';
import { ViewDataSource } from '../../../../../models/ts/view-data-source.model';
import { ProtectedFieldType } from '../../../../../models/ts/protected-field-type.model';
import { refreshActions } from '../../../../store/features/refresh/refresh-actions';
import { TableFieldDataType } from '../../../../../models/ts/table-field-data-type.model';
import { CollectionFormLinkedService } from './collection-form-linked.service';
import { LookupItem } from '../../../../../models/ts/collection-list-summary-item.model';

/**
 * Service handling form functions that require store action dispatches that cannot/should not be handled by an effect
 * (example: effects should not dispatch multiple actions, this service can do this instead)
 */
@Injectable({
  providedIn: 'root'
})
export class StoreCollectionFormService {

  private store$ = inject(Store);

  public updateFormWithLookup(formId: string, form: CollectionForm, field: CollectionFormField, lookup: CollectionFormFieldSingleLookupData, item: LookupItem): void {
    // Update field values
    this.updateLookupFields(formId, form, lookup);
    // Clear any children
    //this.clearChildViewDataSourcesFieldsValue(formId, form, field.ViewDataSourcesID);
    // Update vds
    this.addLookupToViewDataSources(formId, form, field.ViewDataSourcesID, lookup, item);

    // TODO: double check with Jonas
    this.linkExamTrainingInfo(formId, lookup);
    this.linkTrainingViewDataSource(formId, lookup, field);
    this.linkExamViewDataSources(formId, lookup);
  }

  public updateFormWithGridLookup(formId: string, form: CollectionForm, gridFieldId: number, recordId: number, field: CollectionFormField, lookup: CollectionFormFieldGridLookupData, item: LookupItem): void {
    // Update field values
    this.updateLookupRecord(formId, form, gridFieldId, recordId, lookup);
    // Update vds
    this.addLookupToViewDataSources(formId, form, field.ViewDataSourcesID, lookup, item);
  }

  public updateLookupFields(formId: string, form: CollectionForm, lookupData: CollectionFormFieldSingleLookupData): void {
    const fields: { input: CollectionFormFieldInputValue, field: CollectionFormField }[] = [];
    lookupData.FormFields.forEach((fieldInput) => {
      const formField = CollectionFormService.getField(form, f => f.Id === fieldInput.CollectionFormFieldID || f.CollectionFieldsID == fieldInput.CollectionFieldsID);
      if (formField)
        fields.push({ input: fieldInput, field: structuredClone(formField) });
      else throw new Error(`Could not find field ${fieldInput.CollectionFieldsID}/${fieldInput.CollectionFormFieldID} on form.`);
    });

    fields.forEach(field => {
      if (CollectionFormService.fieldIsGrid(field.field)) {
        // TODO: Is this case possible? How would data design look?
        // this.store$.dispatch(formsActions.addRowsToGrid({
        //   formId,
        //   gridFieldId: field.field.Id,
        //   records: field.input.Records
        // }));
      } else {
        switch (field.field.ComponentType) {
          case TableFieldDataType.EnumList:
            this.store$.dispatch(formsActions.updateFormFieldEnumValue({
              formId,
              fieldId: field.field.Id,
              value: field.input.Value
            }));
            break;
          case TableFieldDataType.OrganizationChartUnitSelector:
            this.store$.dispatch(formsActions.updateOrgChartFieldValue({
              formId,
              fieldId: field.field.Id,
              value: field.input.OrgChartUnitSelector
            }));
            break;
          default:
            this.store$.dispatch(formsActions.updateFormFieldValue({
              formId,
              fieldId: field.field.Id,
              value: field.input.Value
            }));
            break;
        }
      }
    });
  }

  public updateLookupRecord(formId: string, form: CollectionForm, gridFieldId: number, recordId: number, lookupData: CollectionFormFieldGridLookupData): void {
    let record = CollectionFormService.getRecord(form, gridFieldId, recordId);
    if (record) {
      record = CollectionFormService.fillRecordWithLookupData(record, lookupData, recordId);
      this.store$.dispatch(formsActions.addRowsToGrid({
        formId,
        gridFieldId,
        records: [record]
      }));
    }
  }

  public addLookupToViewDataSources(formId: string, form: CollectionForm, vdsId: number, lookup: CollectionFormFieldSingleLookupData | CollectionFormFieldGridLookupData, summaryItem: LookupItem): void {
    const viewDataSource = form.ViewDataSources.find(v => v.ViewDataSourcesID == vdsId);
    if (viewDataSource && viewDataSource.ParentDataSourcesID == 0) {
      const lowestCrossLinkId = LookupService.getLowestCrossLinkId(viewDataSource.Instances);
      // Replace the entire array to clear any previously linked instance
      viewDataSource.Instances = [{
        ChildInstancesID: summaryItem.InstancesID,
        ChildVersionsID: summaryItem.VersionsID,
        ParentVersionsID: 0,
        ParentInstancesID: 0,
        CrossLinkedInstancesID: viewDataSource.Instances[0]?.CrossLinkedInstancesID > 0 ? viewDataSource.Instances[0].CrossLinkedInstancesID : lowestCrossLinkId,
        DataDesignCrossID: 0,
        OriginalChildInstancesID: summaryItem.OriginalChildInstancesID,
        State: viewDataSource.Instances.length == 0 ? UpdateDeleteState.Default : UpdateDeleteState.Update,
        PreviousChildVersionsID: viewDataSource.Instances[0]?.ChildVersionsID ?? 0,
        RowDataDesignCrossID: -1,
        SnapshotCrosslinkInstancesID: 0
      }];
      this.store$.dispatch(formsActions.updateFormViewDataSource({
        formId,
        viewDataSource: viewDataSource
      }));
    } else {
      lookup.ViewDataSources.forEach(vds => {
        this.store$.dispatch(formsActions.updateFormViewDataSource({
          formId,
          viewDataSource: vds
        }));
      });
    }
  }



  // TODO: optimize grid cases to only update single rows instead of all (will require new actions specific for grid)
  /**
   * Updates the IsReadOnly property for all fields of the provided ViewDataSource.
   * Also updates child ViewDataSources.
   * @param {string} formId
   * @param {CollectionForm} form
   * @param {number} vdsId
   * @param {boolean} updateChildren
   * @param {number | undefined} recordId
   */
  public updateViewDataSourceFieldsReadOnly(formId: string, form: CollectionForm, vdsId: number, updateChildren: boolean = true, recordId?: number): void {
    if (form !== undefined) {
      const vds = form.ViewDataSources.find(v => v.ViewDataSourcesID == vdsId);
      if (vds) {
        const parentGridVds = CollectionFormLinkedService.getParentGridViewDataSource(form, vds);
        if (parentGridVds !== undefined) {
          const parentGridField = CollectionFormService.getField(form, field => field.ViewDataSourcesID == parentGridVds.ViewDataSourcesID && CollectionFormService.fieldIsGrid(field));
          if (parentGridField !== undefined) {
            const vdsRecordFields = parentGridField.Records?.map(record => {
              return {
                id: record.CrossLinkedInstancesID,
                fields: record.Fields.flat().filter(field => field.ViewDataSourcesID == vdsId)
              };
            }) ?? [];
            vdsRecordFields.forEach(record => {
              record.fields.forEach(recordField => {
                this.store$.dispatch(formsActions.setGridFieldReadOnly({
                  formId: formId,
                  fieldId: parentGridField.Id,
                  recordId: record.id,
                  recordFieldId: recordField.CollectionFieldsID,
                  isReadOnly: CollectionFormService.isLinkedFieldReadOnly(form, recordField),
                  readOnlyPriority: ReadOnlyPriority.Relational
                }));
              });
            });
          }
        } else {
          const vdsFields = CollectionFormService.extractFieldsFromForm(form).filter(field => field.ViewDataSourcesID == vdsId);
          vdsFields.forEach(field => {
            this.store$.dispatch(formsActions.setFieldReadOnly({
              formId: formId,
              formFieldId: field.Id,
              isReadOnly: CollectionFormService.isLinkedFieldReadOnly(form, field),
              readOnlyPriority: ReadOnlyPriority.Relational
            }));
          });
        }

        // TODO: Optimization, skip over Snapshot ViewDataSources (they don't have fields tied to them).
        if (updateChildren) {
          // Repeat for all Child ViewDataSources
          const childViewDataSources = form.ViewDataSources.filter(vds => vds.ParentDataSourcesID == vdsId);
          childViewDataSources.forEach(vds => {
            this.updateViewDataSourceFieldsReadOnly(formId, form, vds.ViewDataSourcesID);
          });
        }

      } else throw new Error(`Could not find VDS ${vdsId}`);
    }
  }

  /**
   * @deprecated
   * @param {string} formId
   * @param {CollectionForm} form
   * @param {CollectionFormFieldSingleLookupData} lookupData
   * @param {CollectionFormField} field
   * @param {LookupItem} summaryItem
   */
  public updateLinkedFields(formId: string, form: CollectionForm,
                            lookupData: CollectionFormFieldSingleLookupData,
                            field?: CollectionFormField,
                            summaryItem?: LookupItem): void {
    if (lookupData) {
      const fields: { input: CollectionFormFieldInputValue, field: CollectionFormField }[] = [];
      lookupData.FormFields.forEach((fieldInput) => {
        const formField = CollectionFormService.getField(form, f => f.Id === fieldInput.CollectionFormFieldID);
        if (formField)
          fields.push({ input: fieldInput, field: structuredClone(formField) });
        else
          console.warn(`Field with ID ${fieldInput.CollectionFormFieldID} was not found in form.`);
      });

      // Set Values & ReadOnly
      fields.forEach((field) => {
        const vds = CollectionFormService.getViewDataSourceForField(form, field.field);
        if (vds) {
          this.store$.dispatch(formsActions.setFieldReadOnly({
            formId,
            formFieldId: field.field.Id,
            isReadOnly: CollectionFormService.isLinkedFieldReadOnly(form, field.field),
            readOnlyPriority: ReadOnlyPriority.Relational
          }));

          switch (field.field.ComponentType) {
            case TableFieldDataType.EnumList:
              this.store$.dispatch(formsActions.updateFormFieldEnumValue({
                formId,
                fieldId: field.field.Id,
                value: field.input.Value
              }));
              break;
            case TableFieldDataType.OrganizationChartUnitSelector:
              this.store$.dispatch(formsActions.updateOrgChartFieldValue({
                formId,
                fieldId: field.field.Id,
                value: field.input.OrgChartUnitSelector
              }));
              break;
            default:
              this.store$.dispatch(formsActions.updateFormFieldValue({
                formId,
                fieldId: field.field.Id,
                value: field.input.Value
              }));
              break;
          }
        }
      });


      if (field && summaryItem) {
        const vdsIndex = form.ViewDataSources.findIndex(v => v.ViewDataSourcesID == field.ViewDataSourcesID);
        if (vdsIndex != -1) {
          // Add instance to VDS
          let viewDataSources = structuredClone(form.ViewDataSources);
          let updatedVds = viewDataSources[vdsIndex];
          updatedVds.Instances.push({
            // LookupItem.InstancesID
            ChildInstancesID: summaryItem.InstancesID,
            // LookupItem.VersionsID
            ChildVersionsID: summaryItem.VersionsID,

            // CollectionForm.VersionsID
            ParentVersionsID: form.VersionsID,
            // CollectionForm.InstancesID
            ParentInstancesID: form.InstancesID,

            // LookupItem.CrossLinkInstancesID
            CrossLinkedInstancesID: summaryItem.CrossLinkInstancesID,
            // ???
            DataDesignCrossID: 0,
            // LookupItem.OriginalChildInstancesID
            OriginalChildInstancesID: summaryItem.OriginalChildInstancesID,
            // Default?
            State: UpdateDeleteState.Default,
            // ???
            PreviousChildVersionsID: 0,
            // ???
            RowDataDesignCrossID: 0,
            // ???
            SnapshotCrosslinkInstancesID: 0
          });
          this.store$.dispatch(formsActions.updateFormViewDataSources({
            formId,
            viewDataSources: viewDataSources
          }));

          // Set linked fields ReadOnly
          updatedVds.ViewDataSourceRelations.forEach(relation => {
            relation.SiblingFormFieldIds.forEach((id) => {
              const formField = CollectionFormService.getField(form, f => f.Id === id);
              if (formField)
                this.store$.dispatch(formsActions.setFieldReadOnly(
                  {
                    formId,
                    formFieldId: formField.Id,
                    isReadOnly: CollectionFormService.isLinkedFieldReadOnly(form, formField),
                    readOnlyPriority: ReadOnlyPriority.Relational
                  }
                ));
            });
            const childVds = form.ViewDataSources.filter(v => v.ParentDataSourcesID == updatedVds.ViewDataSourcesID);
            if (childVds.length > 0)
              childVds.forEach(child => {
                const childFields = CollectionFormService.extractFieldsFromForm(form).filter(f => f.ViewDataSourcesID == child.ViewDataSourcesID);
                childFields.forEach(f => {
                    this.store$.dispatch(formsActions.setFieldReadOnly(
                      {
                        formId,
                        formFieldId: f.Id,
                        isReadOnly: CollectionFormService.isLinkedFieldReadOnly(form, f),
                        readOnlyPriority: ReadOnlyPriority.Relational
                      }
                    ));
                  }
                );
              });
          });
        }
      }

      // Update viewdatasources
      this.store$.dispatch(formsActions.updateFormViewDataSources({
        formId,
        viewDataSources: lookupData.ViewDataSources
      }));
      this.linkExamTrainingInfo(formId, lookupData);
      this.linkTrainingViewDataSource(formId, lookupData, field);
      this.linkExamViewDataSources(formId, lookupData);
    }
  }

  public clearGridLinkedFields(formId: string, form: CollectionForm, gridFieldId: number, recordId: number): void {
    const gridField = CollectionFormService.getField(form, f => f.Id === gridFieldId);
    if (gridField) {
      const vds = CollectionFormService.getViewDataSourceForField(form, gridField);
      const record = CollectionFormService.getRecord(form, gridFieldId, recordId);
      if (vds && record) {
        vds.ViewDataSourceRelations.forEach((relation) => {
          relation.SiblingFormFieldIds.forEach((id) => {
            const field = CollectionFormService.getRecordField(record, f => f.CollectionFieldsID === id);
            if (field)
              this.store$.dispatch(formsActions.updateGridFormFieldValue({
                formId,
                gridFieldId: gridFieldId,
                recordId: record.CrossLinkedInstancesID,
                recordFieldId: field.CollectionFieldsID,
                value: undefined
              }));
            //updatedForm = CollectionFormService.setGridFieldValue(updatedForm, gridFieldId, recordId, id, undefined);
          });
        });
      } else {
        console.warn(`Clear linked grid fields was called for field ${gridFieldId} but it's ViewDataSource was not found!`);
      }
    }
  }

  public linkTrainingViewDataSource(formId: string, lookupData: CollectionFormFieldSingleLookupData, fieldParam?: CollectionFormField): void {

    if (fieldParam != null && lookupData?.TrainingViewDataSources != null && lookupData?.TrainingViewDataSources.length > 0) {
      const form = this.store$.selectSignal(selectForm(formId))();
      if (!form || !form.data) return;
      const costs = lookupData.TrainingViewDataSources.find(v => v.ProtectedCollectionType == ProtectedCollectionType.LinkTrainingCosts);
      const trainingSessionCosts = form.data.ViewDataSources.find(v => v.ProtectedCollectionType == ProtectedCollectionType.LinkTrainingSessionCosts);
      if (costs && trainingSessionCosts) {
        const gridFieldCosts = this.store$.selectSignal(selectFormFieldByviewDataSourceId(formId, trainingSessionCosts.ViewDataSourcesID))();
        if (gridFieldCosts) {
          this.copyGridsByViewDataSource(costs, trainingSessionCosts, gridFieldCosts, formId);
        }
      }

      const trainingExamVds = lookupData.TrainingViewDataSources.filter(v => v.ProtectedCollectionType == ProtectedCollectionType.LinkTrainingExam);
      this.copyViewDataSourceToParent(formId, trainingExamVds, ProtectedCollectionType.LinkTrainingSessionExam);

      const trainingSkillVds = lookupData.TrainingViewDataSources.filter(v => v.ProtectedCollectionType == ProtectedCollectionType.LinkTrainingSkill);
      this.copyViewDataSourceToParent(formId, trainingSkillVds, ProtectedCollectionType.LinkTrainingSessionSkill);

      const attachments = lookupData.TrainingViewDataSources.find(v => v.ProtectedCollectionType == ProtectedCollectionType.LinkTrainingTrainingAttachments);
      const trainingSessionAttachments = form.data.ViewDataSources.find(v => v.ProtectedCollectionType == ProtectedCollectionType.LinkTrainingSessionTrainingAttachments);
      if (attachments && trainingSessionAttachments) {
        const gridFieldAttachments = this.store$.selectSignal(selectFormFieldByviewDataSourceId(formId, trainingSessionAttachments.ViewDataSourcesID))();
        if (gridFieldAttachments) {
          this.copyGridsByViewDataSource(attachments, trainingSessionAttachments, gridFieldAttachments, formId);
        }
      }

      if (lookupData.TrainingInfo?.TrainingAppID > 0) {
        const skillVersionId = trainingSkillVds != null && trainingSkillVds.length > 0 ? trainingSkillVds[0].Instances[0].ChildVersionsID : 0;
        this.store$.dispatch(refreshActions.refreshSkillGrades({
          trainingAppId: lookupData.TrainingInfo.TrainingAppID,
          versionId: skillVersionId
        }));
      }
    }
  }

  public linkExamTrainingInfo(formId: string, lookupData: CollectionFormFieldSingleLookupData): void {
    if (lookupData.TrainingInfo != null) {
      const form = this.store$.selectSignal(selectForm(formId))();
      if (!form || !form.data) return;
      const found = this.store$.selectSignal(selectFormFieldsByProtectedFieldType(formId, 0,
        [
          ProtectedFieldType.TrainingResponsibes,
          ProtectedFieldType.TrainingMaxParticipants,
          ProtectedFieldType.TrainingLocation,
          ProtectedFieldType.TrainingSessionEvaluationType,
          ProtectedFieldType.TrainingType
        ]))();
      if (found) {
        const trainingResponsibesField = found[ProtectedFieldType.TrainingResponsibes];
        const trainingMaxParticipantsField = found[ProtectedFieldType.TrainingMaxParticipants];
        const trainingLocationField = found[ProtectedFieldType.TrainingLocation];
        const trainingSessionEvaluationTypeField = found[ProtectedFieldType.TrainingSessionEvaluationType];
        const trainingTypeField = found[ProtectedFieldType.TrainingType];

        if (trainingResponsibesField) {
          const value: Array<any> = [];
          lookupData.TrainingInfo.Responsibles.forEach(el => {
            value.push(
              {
                ObjectID: el.ID,
                ObjectType: el.ObjectType,
                text: el.Name,
                Name: el.Name,
                UniqueID: `${el.ObjectType}:${el.ID}`
              });
          });
          this.store$.dispatch(formsActions.updateOrgChartFieldValue({
            formId,
            fieldId: trainingResponsibesField.Id,
            value: value
          }));
        }

        if (trainingMaxParticipantsField) {
          this.store$.dispatch(formsActions.updateFormFieldValue({
            formId,
            fieldId: trainingMaxParticipantsField.Id,
            value: lookupData.TrainingInfo.MaxParticipants
          }));
        }

        if (trainingLocationField) {
          this.store$.dispatch(formsActions.updateFormFieldValue({
            formId,
            fieldId: trainingLocationField.Id,
            value: lookupData.TrainingInfo.Location
          }));
        }

        if (trainingSessionEvaluationTypeField) {
          const currentValue = trainingSessionEvaluationTypeField.Value;
          let value = 0;
          if (currentValue > -1) {
            value = trainingSessionEvaluationTypeField.FieldValues?.find(x => x.IndexPosition == lookupData.TrainingInfo.EvaluationType)?.CollectionFieldValuesID ?? 0;
          }
          this.store$.dispatch(formsActions.updateFormFieldValue({
            formId,
            fieldId: trainingSessionEvaluationTypeField.Id,
            value: value
          }));
        }

        if (trainingTypeField) {
          const currentValue = trainingTypeField.Value;
          let value = 0;
          if (currentValue > -1) {
            value = trainingTypeField.FieldValues?.find(x => x.IndexPosition == lookupData.TrainingInfo.TrainingType)?.CollectionFieldValuesID ?? 0;
          }
          this.store$.dispatch(formsActions.updateFormFieldValue({
            formId,
            fieldId: trainingTypeField.Id,
            value: value
          }));
        }
      }
    }
  }

  public linkExamViewDataSources(formId: string, lookupData: CollectionFormFieldSingleLookupData): void {
    if (lookupData.ExamViewDataSources != null) {
      const form = this.store$.selectSignal(selectForm(formId))();
      if (!form || !form.data) return;
      const examsVds = lookupData.ExamViewDataSources.filter(v => v.ProtectedCollectionType == ProtectedCollectionType.LinkExamsSkills);
      this.copyViewDataSourceToParent(formId, examsVds, ProtectedCollectionType.LinkTrainingSkill);
    }
  }

  private copyGridsByViewDataSource(sourceViewDataSource: ViewDataSource, targetViewDataSource: ViewDataSource, targetGridField: CollectionFormField, formId: string): void {
    const form = this.store$.selectSignal(selectForm(formId))();
    if (!form || !form.data) return;
    const formViewdataSources = form.data.ViewDataSources;
    targetViewDataSource.Instances.forEach(instance => {
      const row = targetGridField.Records?.find(r => r.CrossLinkedInstancesID == instance.CrossLinkedInstancesID);
      if (row) {
        this.store$.dispatch(formsActions.removeRecordFromGrid({
          formId: form.id,
          gridFieldId: targetGridField.Id,
          recordId: row.CrossLinkedInstancesID
        }));
      }
    });
    // For each instance of the training cost, we add it to the session cost
    sourceViewDataSource.Instances.forEach(instance => {
      this.store$.dispatch(formsActions.addViewDataInstanceToGrid({
        formId: formId,
        instanceId: instance.ChildInstancesID,
        linkedCollectionType: targetViewDataSource.SingleOrMany,
        originalChildInstancesId: instance.OriginalChildInstancesID,
        viewDataSourceId: targetViewDataSource.ViewDataSourcesID,
        versionId: instance.ChildVersionsID,
        viewDataSources: formViewdataSources,
        targetGridField: targetGridField,
        row: undefined,
        isNewInstanceOnLookup: false
      }));
    });

  }

  private copyViewDataSourceToParent(formId: string, toBeCopied: Array<ViewDataSource>, copyTo: ProtectedCollectionType): void {
    if (toBeCopied == null || copyTo == null) return;
    const form = this.store$.selectSignal(selectForm(formId))();
    if (!form || !form.data) return;
    const parentVds = form.data.ViewDataSources.filter(v => v.ProtectedCollectionType == copyTo);
    if (parentVds == null || parentVds.length == 0) return;
    const instances = toBeCopied.flatMap(el => el.Instances);
    if (instances == null || instances.length == 0) return;
    // TODO: adapt when lookups are fixed
    // instances.forEach(el => {
    //   const storeField = this.store$.selectSignal(selectFormFieldByviewDataSourceId(formId, parentVds[0].ViewDataSourcesID))();
    //   if (storeField) {
    //     const foundField = this.store$.selectSignal(selectFormField(formId, storeField.Id))();
    //     if (foundField) {
    //       this.store$.dispatch(formsActions.fetchLookup(
    //         {
    //           formId: formId,
    //           form: form.data,
    //           field: foundField,
    //           instanceId: el.ChildInstancesID,
    //           versionId: el.ChildVersionsID
    //         }
    //       ));
    //     }
    //   }
    // });
  }
}
