@if (formFieldSignal(); as formField) {
    @if(errorState?.Message; as errorMessage){
        <div class="absolute right-16 top-0 z-[2] flex h-full items-center justify-center">
            <bizz-tooltip class="cursor-pointer text-red-400" [text]="errorMessage"></bizz-tooltip>
        </div>
    }
    <div class="input-button-group flex" [ngClass]="{'hidden' : formField.IsHidden}" #vcr>
        <kendo-multiselect [id]="inputId" #multiSelectInput
                           class="bizz-combobox-multiselect" [class.display-values]="hasDisplayValues" [class.focused]="isFocused" [data]="data"
                           textField="Caption"
                           valueField="CollectionFieldValuesID"
                           [value]="selection"
                           [valuePrimitive]="false"
                           [filterable]="true"
                           [readonly]="readOnlyOverride || formField.IsReadOnly"
                           [tabIndex]="readOnlyOverride || formField.IsReadOnly ? -1  : 0"
                           (filterChange)="onFilterChange($event)"
                           (valueChange)="onValueChange($event)"
                           [popupSettings]="{ appendTo: isInGrid? 'root' : multiSelectGroupRef, width: isInGrid? 'auto' : '100%', popupClass: 'rounded' }">
            <ng-template kendoMultiSelectTagTemplate let-dataItem>
                @if(displayValues[dataItem.CollectionFieldValuesID]) {
                    <span class="text-sm" [ngStyle]="{ 'color':
                            displayValues[dataItem.CollectionFieldValuesID].ForegroundColor }">
                            {{ dataItem.Caption }}
                        </span>
                } @else {
                    <span class="text-sm">{{ dataItem.Caption }}</span>
                }
            </ng-template>
            <ng-template kendoMultiSelectItemTemplate  let-dataItem>
                @if(displayValues[dataItem.CollectionFieldValuesID]) {
                    <span class="rounded-full px-3 py-0.5"
                          [ngStyle]="{
                    'background-color': displayValues[dataItem.CollectionFieldValuesID].BackgroundColor, 
                    'color': displayValues[dataItem.CollectionFieldValuesID].ForegroundColor }">
                        {{ dataItem.Caption }}
                    </span>
                } @else {
                    <span class="py-0.5 text-default"
                          [ngClass]="{ 'px-3' : hasDisplayValues }">
                        {{ dataItem.Caption }}
                    </span>
                }

            </ng-template>
        </kendo-multiselect>
        <div class="button-group">
            <button type="button"
                    [disabled]="readOnlyOverride || formField.IsReadOnly"
                    [tabIndex]="readOnlyOverride || formField.IsReadOnly ? -1  : 0"
                    class="default !px-2 !py-1"
                    (click)="multiSelectInput.toggle()">
                <bizz-toggle-arrow class="size-4" [direction]="multiSelectInput.isOpen ? 'up' : 'down'">
                </bizz-toggle-arrow>
            </button>
        </div>
    </div>
}
