import {
  AfterViewInit,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  Signal,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AsyncPipe, JsonPipe, NgClass, NgIf, NgStyle, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { EditorModule } from '@progress/kendo-angular-editor';
import { CheckBoxModule, RadioButtonModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { FormControl, FormGroup } from '@angular/forms';
import { asyncScheduler, Observable, Subscription } from 'rxjs';
import { LinkedFormControlButtonsComponent } from '../linked-form-control-buttons/linked-form-control-buttons.component';
import { LinkedFormControlComponent } from '../linked-form-control/linked-form-control.component';
import { ControlledDocumentControlComponent } from '../controlled-document-control/controlled-document-control.component';
import { ReverseEnumPipe } from 'src/app/shared/pipes/reverse-enum/reverse-enum.pipe';
import { ViewContainerRefDirective } from '../../../../../../shared/directives/view-container-ref.directive';
import { TooltipComponent } from '../../../../../../shared/components/ui/tooltip/tooltip.component';
import { CollectionFormField } from '../../../../../../../models/ts/collection-form-field.model';
import { ViewDataSource } from '../../../../../../../models/ts/view-data-source.model';
import { MediaCommitDto } from '../../../../../../../models/ts/media-commit-dto.model';
import { TableFieldDataType } from '../../../../../../../models/ts/table-field-data-type.model';
import { createViewContainerComponent } from '../../../../../../shared/functions/helpers/view-container-helpers';
import { DynamicFormControlComponent } from '../../../../../../shared/interfaces/dynamic-form-control-component';
import { Store } from '@ngrx/store';
import {
  selectFormField,
  selectFormFieldValidationState,
  selectGridRecordField
} from '../../../../../../store/features/forms/forms-selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CollectionFormService } from '../../../services/collection-form.service';
import { resolveControlComponent } from '../../../functions/resolve-control-components';
import { GridColumnBase } from '../../../../../../shared/classes/list/grid-column-base';
import { CollectionFormValidatorError } from '../../../classes/collection-form-validator-error';


/**
 * Container component that dynamically loads the appropriate form control based on the field type.
 * This component is used by the form component to render the form controls.
 * @see FormComponent
 */
@Component({
  selector: 'bizz-form-control',
  templateUrl: './form-control.component.html',
  styleUrls: ['./form-control.component.scss'],
  imports: [
    NgSwitch,
    DropDownsModule,
    NgSwitchCase,
    EditorModule,
    RadioButtonModule,
    DateInputsModule,
    CheckBoxModule,
    NgSwitchDefault,
    ReverseEnumPipe,
    NgIf,
    ViewContainerRefDirective,
    NgClass,
    TooltipComponent,
    LinkedFormControlButtonsComponent,
    JsonPipe,
    AsyncPipe,
    NgStyle
  ],
  standalone: true
})
export class FormControlComponent implements OnInit, AfterViewInit, OnChanges {
  /**
   * Whether to display the caption above the control.
   * @type {boolean}
   */
  public displayCaption: boolean;
  /**
   * Reference to the view container directive that will host the control component.
   * @type {ViewContainerRefDirective}
   */
  @ViewChild(ViewContainerRefDirective, { static: true }) public componentHost: ViewContainerRefDirective;

  /**
   * Signal for the store form field this control is tied to.
   * @type {Signal<CollectionFormField | undefined>}
   */
  public storeFieldSignal: Signal<CollectionFormField | undefined>;
  /**
   * Observable for the store form field this control is tied to.
   * @type {Observable<CollectionFormField | undefined>}
   */
  public storeField$: Observable<CollectionFormField | undefined>;
  /**
   * The store form field this control is tied to.
   * In essence, this is a value accessor for the storeField$ observable.
   * @type {CollectionFormField | undefined}
   */
  public storeField: CollectionFormField | undefined;

  /**
   * The form id of the form this field is part of.
   * @type {string}
   */
  @Input({ required: true }) public formId: string;
  /**
   * The field id of the form control.
   * @type {number}
   */
  @Input({ required: true }) public fieldId: number;
  /**
   * The formControl instance tied to this form control.
   * Do not bind this and group at the same time, as it will cause changedAfterItWasCheckedError.
   * @type {FormControl}
   */
  @Input() public control: FormControl;
  /**
   * The formGroup instance of the record this field is part of.
   * Binding group will override control input on loadControl, potentially causing changedAfterItWasCheckedError.
   * @type {FormGroup}
   */
  @Input() public group: FormGroup;
  /**
   * The record id of the row this form control is part of.
   * @type {number}
   */
  @Input() public recordId: number;
  /**
   * The grid field id of the grid this form control is part of.
   * @type {number}
   */
  @Input() public gridFieldId: number;
  /**
   * The VDS array of the form.
   * @type {ViewDataSource[]}
   */
  @Input() public viewDataSources: ViewDataSource[];
  @Output() public fileUploaded = new EventEmitter<MediaCommitDto>();
  /**
   * Whether this form control is part of a grid.
   * @type {boolean}
   */
  @Input() public isGridControl = false;
  @Input() public gridColumn: GridColumnBase;
  @Input() public columnIndex: number;
  protected errorState: CollectionFormValidatorError | undefined;
  private store$ = inject(Store);
  private destroyRef = inject(DestroyRef);
  /**
   * Array holding subscriptions to facilitate easy tracking of active Observable subscriptions.
   * Controls can be reloaded which will also change which Observables should be subscribed to.
   * This array can be used to unsub from previous Observable subscriptions and prevent memory leaks.
   * All subscriptions should unsub themselves on destroy (through takeUntilDestroyed) as this array is not explicitly handled on destroy.
   * @type {Subscription[]}
   * @private
   */
  private subscriptions: Subscription[] = [];

  public ngOnInit(): void {
    // Select from store both Signal and Observable version.
    this.setSignalsAndObservables();
    this.createSubscriptions();
  }

  public ngAfterViewInit(): void {
    // Schedule the loading of the control to the next tick. When all variables are set.
    asyncScheduler.schedule(() => {
      const field = this.storeFieldSignal();
      if (field && ((this.isGridControl && this.group) || (!this.isGridControl && this.control)) && this.componentHost) {
        this.loadControl(field);
        // Checkboxes, grids don't display the caption above the control but to the right (inside the control component itself)
        this.displayCaption = field.ComponentType !== TableFieldDataType.Checkbox &&
          !CollectionFormService.fieldIsGrid(field);
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.checkForRecordChange(changes['recordId']);
  }

  /**
   * Loads a form control into the view container.
   * @param field
   * @param control
   * @private
   */
  protected loadControl(field: CollectionFormField): void {
    if (this.isGridControl) this.setControlFromGroup();
    if (resolveControlComponent(field) === undefined) console.warn('Field could not resolve to a component', field);
    const componentRef = createViewContainerComponent<DynamicFormControlComponent>(this.componentHost.viewContainerRef, resolveControlComponent(field));
    componentRef.instance.formControl = this.control;
    componentRef.instance.formId = this.formId;
    componentRef.instance.formField$ = this.storeField$;
    componentRef.instance.formFieldSignal = this.storeFieldSignal;
    componentRef.instance.isInGrid = this.isGridControl;
    componentRef.instance.recordId = this.recordId;
    componentRef.instance.gridFieldId = this.gridFieldId;

    if (componentRef.instance instanceof LinkedFormControlComponent) {
      componentRef.instance.viewDataSources = this.viewDataSources;
    }

    // TODO: JONAS verify if this is still required
    if (componentRef.instance instanceof ControlledDocumentControlComponent) {
      componentRef.instance.fileChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(this.fileUploaded);
    }
  }

  protected reloadControl(): void {
    this.dropSubscriptions();
    this.setSignalsAndObservables();
    this.createSubscriptions();
    const field = this.storeFieldSignal();
    if (field !== undefined) {
      this.loadControl(field);
    } else throw new Error(`Control reload triggered but field was undefined`);
  }

  private setSignalsAndObservables(): void {
    this.storeFieldSignal = this.store$.selectSignal(this.isGridControl ? selectGridRecordField(this.formId, this.gridFieldId, this.recordId, this.recordFieldPredicate) : selectFormField(this.formId, this.fieldId));
    this.storeField$ = this.store$.select(this.isGridControl ? selectGridRecordField(this.formId, this.gridFieldId, this.recordId, this.recordFieldPredicate) : selectFormField(this.formId, this.fieldId));
  }

  private createSubscriptions(): void {
    this.subscriptions.push(this.createFieldSubscription());
    const field = this.storeFieldSignal();
    if (field) {
      this.createValidationSubscription(field.Id)
    }
  }

  /**
   * Returns a subscription which stored the field in storeField on every push
   * @return {Subscription}
   * @private
   */
  private createFieldSubscription(): Subscription {
    return this.storeField$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(field => this.storeField = field);
  }

  // TODO: Check if record fields have validation. If so, expand this to include them
  private createValidationSubscription(fieldId: number): Subscription {
    return this.store$.select(selectFormFieldValidationState(this.formId, fieldId))
      .pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe({
      next: (error: CollectionFormValidatorError | undefined) => {
        this.errorState = error;
      }
    });
  }

  /**
   * Unsubscribes from all active subs and empties the subscriptions array.
   * @private
   */
  private dropSubscriptions(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }

  /**
   * If recordId changes, the control must be reloaded. Record will functionally be new but due to being inside a Grid this component will not be destroyed and re-created.
   * @param {SimpleChange} recordChange
   * @private
   */
  private checkForRecordChange(recordChange: SimpleChange): void {
    if (recordChange !== undefined) {
      if (recordChange.currentValue !== recordChange.previousValue && !recordChange.firstChange) {
        this.reloadControl();
      }
    }
  }

  /**
   * Predicate to filter the record field from the form field collection.
   * @param {CollectionFormField} field
   * @return {boolean}
   */
  private recordFieldPredicate = (field: CollectionFormField) => field.CollectionFieldsID == this.fieldId;

  private setControlFromGroup(): void {
    if (!this.isGridControl || !this.group) throw new Error('setControlFromGroup called without group or outside grid context');
    const recordGroup = this.group.get(this.recordId.toString()) as FormGroup;
    if (recordGroup) {
      const control = recordGroup.get(this.fieldId.toString());
      if (control) this.control = control as FormControl;
      else throw new Error('Control not found in record group');
    } else throw new Error('Record group not found');
  }
}